import { AfterViewInit, Component, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'lib-signature',
  template: '<canvas class="signature-pad-canvas"></canvas><button class="button" (click)="onClear($event)"></button>',
  styleUrls: ['./signature.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureComponent),
      multi: true,
    },
  ],
})
export class SignatureComponent implements ControlValueAccessor, AfterViewInit {
  private _dataUrl!: string;
  private _signaturePad: SignaturePad;

  constructor(private elementRef: ElementRef) {}

  public ngAfterViewInit(): void {
    const canvas: HTMLCanvasElement = this.elementRef.nativeElement.querySelector('canvas');
    setTimeout(() => {
      // 500ms to allow style to be applied
      canvas.height = canvas.getBoundingClientRect().height;
      canvas.width = canvas.getBoundingClientRect().width;
    }, 500);

    this._signaturePad = new SignaturePad(canvas, {});
    this._signaturePad.addEventListener('endStroke', (event: CustomEvent) => this._onEndSign(event));
    if (this._dataUrl) {
      this._signaturePad.fromDataURL(this._dataUrl);
    }
  }

  /**
   * Clears the canvas
   */
  onClear(e: Event): void {
    this._signaturePad.clear();
    this._onEndSign(e);
  }

  propagateChange = (_: any) => {};
  propagateTouched = () => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouched = fn;
  }

  writeValue(dataURL: any) {
    if (dataURL !== undefined) {
      this._dataUrl = dataURL;

      if (this._signaturePad) {
        this._signaturePad.fromDataURL(this._dataUrl);
      }
    }
  }

  private _onEndSign(e?: Event): void {
    e.stopPropagation();
    this._dataUrl = this._toDataURL();
    this.propagateChange(this._dataUrl);
  }

  private _toDataURL(imageType?: string, quality?: number): string {
    return this._signaturePad.toDataURL(imageType, quality); // save image as data URL
  }
}
