import { createAction, props } from '@ngrx/store';

import { UnitOfWorkDangerSource } from '../models';

// Load
export const loadSuccess = createAction(
  '[UnitOfWorkDangerSource/API] Load Links between Unit of Work and Danger Source Success',
  props<{ unitOfWorkDangerSources: UnitOfWorkDangerSource[] }>()
);
export const loadFailure = createAction('[UnitOfWorkDangerSource/API] Load Links between Unit of Work and Danger Source Failure');
// Update
export const updateSuccess = createAction(
  '[UnitOfWorkDangerSource/API] Update Link between Unit of Work and Danger Source Success',
  props<{ unitOfWorkDangerSource: UnitOfWorkDangerSource }>()
);
export const updateFailure = createAction('[UnitOfWorkDangerSource/API] Update Link between Unit of Work and Danger Source Failure');
// Create
export const createSuccess = createAction(
  '[UnitOfWorkDangerSource/API] Create Link between Unit of Work and Danger Source Success',
  props<{ unitOfWorkDangerSource: UnitOfWorkDangerSource }>()
);
export const createFailure = createAction('[UnitOfWorkDangerSource/API] Create Link between Unit of Work and Danger Source Failure');
// Duplicate
export const duplicateSuccess = createAction(
  '[UnitOfWorkDangerSource/API] Duplicate Link between Unit of work and Danger source for an another unit of work Success',
  props<{ unitOfWorkDangerSource: UnitOfWorkDangerSource }>()
);
export const duplicateFailure = createAction(
  '[UnitOfWorkDangerSource/API] Duplicate Link between Unit of work and Danger source for an another unit of work Failure'
);
// Delete
export const removeSuccess = createAction(
  '[UnitOfWorkDangerSource/API] Delete Link between Unit of Work and Danger Source Success',
  props<{ id: number }>()
);
export const removeFailure = createAction('[UnitOfWorkDangerSource/API] Delete Link between Unit of Work and Danger Source Failure');
