import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MessageItemComponent } from './components/item/item.component';
import { MessageListComponent } from './containers/list/list.component';
import { MessengerEffects } from './store/effects';
import { reducer } from './store/reducer';

@NgModule({
  declarations: [MessageListComponent, MessageItemComponent],
  imports: [CommonModule, StoreModule.forFeature('messenger', reducer), EffectsModule.forFeature([MessengerEffects])],
  exports: [MessageListComponent],
})
export class MessengerModule {}
