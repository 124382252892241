import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-checkbox-input',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxComponent),
      multi: true,
    },
  ],
})
export class FormCheckboxComponent implements ControlValueAccessor, AfterViewInit {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter();
  @Input() formControlName: string | number;
  @ViewChild('formField') formField: ElementRef;
  @Input() value: any[] | any = true;
  @Input() valueUnchecked: any = false;
  private _disabled = false;
  private _focus = false;
  private _formControlValue: any;

  @HostBinding('class')
  get className() {
    const classList = ['lib-checkbox-input', 'form-field'];

    if (this.checked) {
      classList.push('checked');
    }

    if (this._focus) {
      classList.push('focus');
    }

    if (this._disabled) {
      classList.push('disabled');
    }

    return classList.join(' ');
  }

  get checked() {
    if (Array.isArray(this._formControlValue)) {
      return this._formControlValue.includes(this.value);
    }
    return this._formControlValue === this.value;
  }

  @Input()
  set disabled(val: any) {
    this._disabled = coerceBooleanProperty(val);
  }
  get disabled() {
    return this._disabled;
  }

  constructor(@Optional() private _group: FormCheckboxGroupComponent) {}

  ngAfterViewInit() {
    const formField = this.formField.nativeElement as HTMLInputElement;
    formField.addEventListener('keypress', (e: KeyboardEvent) => {
      if (e.shiftKey || e.ctrlKey) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      this._keyboardAction(e.code);
    });

    formField.addEventListener('focus', () => (this._focus = true));
    formField.addEventListener('blur', () => (this._focus = false));
  }

  propagateChange = (_: any) => {};
  propagateTouched = () => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this._formControlValue = value;
    }
  }

  private _keyboardAction(key: string) {
    if (key === 'Space') {
      this._toggle();
    }
  }

  @HostListener('click', ['$event'])
  private _onClick(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this._toggle();
  }

  private _toggle() {
    if (this._disabled) {
      return;
    }

    if (this._group && !Array.isArray(this._formControlValue)) {
      this._formControlValue = [];
    }

    if (Array.isArray(this._formControlValue)) {
      if (this._formControlValue.includes(this.value)) {
        this._formControlValue = this._formControlValue.filter(val => val !== this.value);
      } else {
        this._formControlValue.push(this.value);
      }
    } else {
      if (this._formControlValue === this.value) {
        this._formControlValue = this.valueUnchecked;
      } else {
        this._formControlValue = this.value;
      }
    }

    if (this._group) {
      this._group.update(this._formControlValue);
    } else {
      this.propagateChange(this._formControlValue);
      this.change.emit(this._formControlValue);
      this.propagateTouched();
    }
  }
}

let uniqId = 0;
@Component({
  selector: 'lib-checkbox-group',
  template: '<ng-content></ng-content>',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxGroupComponent),
      multi: true,
    },
  ],
})
export class FormCheckboxGroupComponent implements ControlValueAccessor, AfterContentInit {
  @Input() formControlName: string;
  @ContentChildren(FormCheckboxComponent, { descendants: true }) private _controls: QueryList<FormCheckboxComponent>;
  private _formControlValue: any;
  private _name = `radio-checkbox-${uniqId++}`;

  @HostBinding('class')
  get className(): string {
    return 'lib-checkbox-group form-field';
  }

  @Input()
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }

  @Input()
  get value() {
    return this._formControlValue;
  }

  ngAfterContentInit() {
    this._propagateToControls();
  }

  propagateChange = (_: any) => {};
  propagateTouched = () => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouched = fn;
  }

  update(value) {
    this._formControlValue = value;

    this.propagateChange(this._formControlValue);
    this.propagateTouched();
    this._propagateToControls();
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this._formControlValue = value || [];
      this._propagateToControls();
    }
  }

  private _propagateToControls() {
    if (this._controls) {
      this._controls.forEach(control => control.writeValue(this._formControlValue));
    }
  }
}
