import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { CompanyService } from '../../services/models/company.service';
import { CompanyActions, CompanyApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { ContactApFunctionEnum } from '../models';
import { AppState } from '../reducers';
import { ContractSelectors } from '../selectors';

@Injectable()
export class CompanyEffects {
  loadForContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadForCurrentContract),
      withLatestFrom(this._store.pipe(select(ContractSelectors.getSelected))),
      switchMap(([, { companyId }]) =>
        this._srv.getById(companyId).pipe(
          map(company => CompanyApiActions.loadForCurrentContractSuccess({ company })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(CompanyApiActions.loadForCurrentContractFailure(), `Erreur lors de la récupération de l\'entreprise`)
            )
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.update),
      withLatestFrom(this._store.pipe(select(ContractSelectors.getSelected))),
      mergeMap(([{ id, values }, contract]) =>
        this._srv.update(id, values, contract.id).pipe(
          map(company => CompanyApiActions.updateSuccess({ company })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(CompanyApiActions.updateFailure(), `Erreur lors de la sauvegarde de l\'entreprise`))
          )
        )
      )
    )
  );

  updateDecisionMaker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.updateOrCreateDecisionMaker),
      mergeMap(({ id, values }) =>
        this._srv.updateContact(id, values, ContactApFunctionEnum.DECISION_MAKER).pipe(
          map(contact => CompanyApiActions.updateOrCreateReferentSuccess({ companyId: id, contact })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(
                CompanyApiActions.updateOrCreateReferentFailure(),
                `Erreur lors de la sauvegarde du dirigeant de l\'entreprise`
              )
            )
          )
        )
      )
    )
  );

  updateReferent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.updateOrCreateReferent),
      mergeMap(({ id, values }) =>
        this._srv.updateContact(id, values, ContactApFunctionEnum.REFERENT).pipe(
          map(contact => CompanyApiActions.updateOrCreateReferentSuccess({ companyId: id, contact })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(
                CompanyApiActions.updateOrCreateReferentFailure(),
                `Erreur lors de la sauvegarde du référent de l\'entreprise`
              )
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: CompanyService, private _store: Store<AppState>) {}
}
