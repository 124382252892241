import { Directive, HostBinding, HostListener } from '@angular/core';
import { EditableDataBaseDirective } from './_editable-data-base.directive';

@Directive({
  selector: '[libEditableDataInput]',
})
export class EditableDataInputDirective extends EditableDataBaseDirective<HTMLInputElement> {
  @HostBinding('class') className = 'editable-data-input';

  focus() {
    this._domEl.focus();
  }

  get isMultiLine() {
    return this._domEl.tagName === 'TEXTAREA';
  }

  get value() {
    return this._domEl.value;
  }

  set value(val) {
    this._domEl.value = val;
  }

  get placeholder() {
    return this._domEl.placeholder;
  }

  get valid(): boolean {
    return !(this._domEl.classList.contains('ng-invalid') && this._domEl.classList.contains('ng-dirty'));
  }

  @HostListener('blur')
  onBlur() {
    setTimeout(() => {
      this._domEl.dispatchEvent(new Event('editable:blur', { bubbles: true }));
      this.event$.emit('blur');
    });
  }

  @HostListener('keydown', ['$event'])
  onEnter(e: KeyboardEvent) {
    if (this.isMultiLine) {
      setTimeout(() => this._applyRowAttr(), 0);
    }

    if (e.shiftKey) {
      return;
    }

    if (e.key === 'Enter') {
      this._domEl.blur();
    }
  }

  @HostListener('focus')
  onFocus() {
    this._domEl.dispatchEvent(new Event('editable:focus', { bubbles: true }));
    this.event$.emit('focus');
  }

  show() {
    super.show();
    if (this.isMultiLine) {
      this._domEl.style.overflow = 'hidden';
    }
    setTimeout(() => this._applyRowAttr(), 0);
  }

  private _applyRowAttr() {
    this._domEl.style.height = '1px';
    this._domEl.style.height = `${this._domEl.scrollHeight + 10}px`;
  }
}
