import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { UnitOfWorkActions, UnitOfWorkApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { UnitOfWork } from '../models';

export interface State extends EntityState<UnitOfWork>, LoadingState {}

export const adapter: EntityAdapter<UnitOfWork> = createEntityAdapter<UnitOfWork>();

export const initialState: State = adapter.getInitialState(LoadingStateHelper.initial());

const UnitOfWorkReducer = createReducer(
  initialState,
  // Load
  on(UnitOfWorkActions.load, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkActions.loadByUnitOfProduction, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkApiActions.loadSuccess, (state, { unitOfWorks }) => {
    return adapter.upsertMany(unitOfWorks, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfWorkApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Update
  on(UnitOfWorkActions.update, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkApiActions.updateSuccess, (state, { unitOfWork }) => {
    return adapter.upsertOne(unitOfWork, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfWorkApiActions.updateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Create
  on(UnitOfWorkActions.create, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkApiActions.createSuccess, (state, { unitOfWork }) => {
    return adapter.addOne(unitOfWork, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfWorkApiActions.createFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Remove
  on(UnitOfWorkActions.remove, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkApiActions.removeSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfWorkApiActions.removeFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Move
  on(UnitOfWorkActions.move, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkApiActions.moveSuccess, (state, { unitOfWork }) => {
    const { entities } = state;
    // reorder other unitOfWorks in same unitOfProduction
    const working = Object.values(entities)
      .filter(current => current.unitOfProductionId === unitOfWork.unitOfProductionId)
      .filter(current => current.id !== unitOfWork.id)
      .sort((unitOfWorkA, unitOfWorkB) => (unitOfWorkA.position < unitOfWorkB.position ? -1 : 1))
      .map((current, idx) => ({
        ...current,
        position: idx >= unitOfWork.position ? idx + 1 : idx,
      }));
    working.push(unitOfWork);

    return adapter.upsertMany(working, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfWorkApiActions.moveFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Clean
  on(UnitOfWorkActions.clean, state => adapter.removeAll(state)),
  on(UnitOfWorkActions.cleanOne, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
    });
  })
);

export function reducer(state: State | undefined, action: Action) {
  return UnitOfWorkReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
