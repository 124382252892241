import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Message } from './model';
import { State } from './reducer';

const getMessengerState = createFeatureSelector('messenger');

const getEntitiesFromState = createSelector(getMessengerState, (state: State): Dictionary<Message> => state.entities);

const getAll = createSelector(getEntitiesFromState, entities => Object.keys(entities).map(id => entities[id]));

export const messengerSelectors = {
  getAll,
};
