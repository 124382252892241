import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { LibSelectOption } from '../../../interfaces/select';
import { RefActivityActions, RefProfileActions } from '../../../store/actions';
import { AppState } from '../../../store/reducers';
import { RefProfileSelectors, RefUnitOfProductionSelectors } from '../../../store/selectors';

@Component({
  selector: 'lib-unit-of-production-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class UnitOfProductionProfileComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public refProfiles: LibSelectOption[];
  public refUnitOfProductions: LibSelectOption[];

  private _destroy$: Subject<boolean> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<UnitOfProductionProfileComponent>,
    private _store: Store<AppState>,
    private _fb: UntypedFormBuilder
  ) {}

  cancel() {
    this._dialogRef.close(false);
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this._dialogRef.addPanelClass(['overflowable', 'narrow', 'alternative']);
    this._store.dispatch(RefActivityActions.load());

    this.form = this._fb.group({
      refProfileId: [null, Validators.required],
      refUnitOfProductionId: [null, Validators.required],
    });

    this._store
      .pipe(
        select(RefProfileSelectors.getAll),
        takeUntil(this._destroy$),
        tap(
          refProfiles =>
            (this.refProfiles = refProfiles.map(({ name, id }) => ({
              label: name,
              value: id,
            })))
        )
      )
      .subscribe();

    this._store
      .pipe(
        select(RefUnitOfProductionSelectors.getBySelectedRefProfile),
        takeUntil(this._destroy$),
        tap(
          refUnitOfProduction =>
            (this.refUnitOfProductions = refUnitOfProduction.map(({ name, id }) => ({
              label: name,
              value: id,
            })))
        )
      )
      .subscribe();

    this.form
      .get('refProfileId')
      .valueChanges.pipe(
        takeUntil(this._destroy$),
        tap(id => {
          this._store.dispatch(RefProfileActions.select({ id }));

          this.form.patchValue({
            refUnitOfProductionId: null,
          });
        })
      )
      .subscribe();
  }

  submit() {
    if (this.form.valid) {
      this._dialogRef.close(this.form.get('refUnitOfProductionId').value);
    }
  }
}
