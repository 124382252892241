import { createAction, props } from '@ngrx/store';

import { Measure } from '../models';

// Load
export const loadTopFailure = createAction('[Measure/Api] Load Top Measures for current Unit of production entity Failure');
// Update
export const updateSuccess = createAction('[Measure/API] Update Measure Success', props<{ measure: Measure }>());
export const updateFailure = createAction('[Measure/API] Update Measure Failure');
// Move
export const moveSuccess = createAction('[Measure/API] Move Measure Success', props<{ measure: Measure }>());
export const moveFailure = createAction('[Measure/API] Move Measure Failure');
// Create
export const createSuccess = createAction('[Measure/API] Create Measure Success', props<{ measure: Measure }>());
export const createFailure = createAction('[Measure/API] Create Measure Failure');
// Delete
export const removeSuccess = createAction('[Measure/API] Delete Measure Success', props<{ id: number }>());
export const removeFailure = createAction('[Measure/API] Delete Measure Failure');
