import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { DangerSourceImage, DangerSourceImageStatusEnum } from '../../../store/models';

@Component({
  selector: 'lib-danger-source-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
})
export class DangerSourcePictureComponent implements OnInit, OnDestroy {
  @Output() deleteRequest: EventEmitter<null> = new EventEmitter();
  form: UntypedFormGroup;
  @Input() image: DangerSourceImage;
  statuses = DangerSourceImageStatusEnum;
  @Output() update: EventEmitter<Partial<DangerSourceImage>> = new EventEmitter();
  private _destroy$: Subject<boolean> = new Subject();

  @HostBinding('class')
  get className() {
    const classList = ['lib-danger-source-picture'];

    if (this.image.status === this.statuses.CONTINUE) {
      classList.push('success');
    }

    if (this.image.status === this.statuses.EDIT) {
      classList.push('warning');
    }

    return classList.join(' ');
  }

  get status() {
    return this.form.get('status');
  }

  constructor(private _fb: UntypedFormBuilder) {}

  delete(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.deleteRequest.emit();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this.form = this._fb.group(
      {
        name: [this.image.name, Validators.required],
        status: [this.image.status, Validators.required],
      },
      {
        updateOn: 'blur',
      }
    );

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.form.valid),
        tap(values => this.update.emit(values))
      )
      .subscribe();
  }
}
