import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RefActivityApi, RefActivityListApi, RefActivityServiceResponse } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class RefActivityService {
  constructor(private _http: HttpClient) {}

  getAll(): Observable<RefActivityServiceResponse[]> {
    return this._http
      .get<RefActivityListApi>('activities', {
        params: {
          paginate: 'false',
        },
      })
      .pipe(map(({ items }) => items.map(item => this._transformer(item))));
  }

  private _transformer(itemApi: RefActivityApi): RefActivityServiceResponse {
    const { refProfil, ...refActivityData } = itemApi;
    return {
      refActivity: {
        ...refActivityData,
        refProfileId: refProfil.id,
      },
      refProfile: refProfil,
    };
  }
}
