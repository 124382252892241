<ng-container *ngIf="riskType$ | async as riskType">
  <ng-container *appDuerTitle>
    <h1>
      [Risque]
      <lib-text-format [text]="riskType.name"></lib-text-format>
    </h1>
    <h2 *ngIf="unitOfProduction$ | async as unitOfProduction" [textFormat]="unitOfProduction.name"></h2>
  </ng-container>

  <lib-danger-source-list
    *ngIf="unitOfProduction$ | async as unitOfProduction"
    [dangerSourceItemUrlMask]="getDangerSourceItemUrlMask(unitOfProduction, riskType)"
    [trackingMode]="true"
  ></lib-danger-source-list>
</ng-container>
