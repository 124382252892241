<lib-line-item [formGroup]="form" [disabled]="!measure.enabled">
  <lib-editable-data class="name">
    <span libEditableDataLabel></span>
    <textarea libEditableDataInput placeholder="Intitulé de la mesure" formControlName="name"></textarea>
  </lib-editable-data>
  <lib-editable-data [showEmptyLabel]="true" class="amount" suffix="€">
    <span libEditableDataLabel></span>
    <input libEditableDataInput placeholder="--" formControlName="amount" />
  </lib-editable-data>
  <lib-editable-data [showEmptyLabel]="true" class="responsible">
    <span libEditableDataLabel></span>
    <input libEditableDataInput placeholder="--" formControlName="inCharge" maxlength="5" />
  </lib-editable-data>
  <lib-editable-data [showEmptyLabel]="true" class="date">
    <span libEditableDataLabel></span>
    <input
      libEditableDataInput
      libDateAutoFormatter
      placeholder="--"
      formControlName="predictedDate"
      [matDatepicker]="picker"
      (focus)="picker.open()"
    />
    <mat-datepicker #picker></mat-datepicker>
  </lib-editable-data>
  <div class="indicators">
    <span class="star" [ngClass]="{ inactive: !measure.top }"></span>
    <span class="periodic" *ngIf="measure.periodic"></span>
    <!-- TODO: activate when API has data-->
    <span class="alert" *ngIf="hasNotification"></span>
  </div>

  <ng-container actions>
    <lib-dropdown-menu>
      <a href="#" libDropdownItem (click)="changePeriodic($event)">
        {{ measure.periodic ? 'Définir comme mesure non périodique' : 'Définir comme mesure périodique' }}
      </a>
      <a href="#" libDropdownItem (click)="changeTop($event)" class="star">
        {{ measure.top ? 'Définir comme mesure non prioritaire (TOP)' : 'Définir comme mesure prioritaire (TOP)' }}
      </a>
      <a href="#" libDropdownItem (click)="alert($event)">{{ notificationLabel }}</a>
      <a href="#" libDropdownItem (click)="delete($event)" class="warning">Supprimer</a>
    </lib-dropdown-menu>
    <a href="#" (click)="changeTop($event)" class="star" [ngClass]="{ inactive: !measure.top }"></a>
  </ng-container>
</lib-line-item>
