<div class="content">
  <router-outlet></router-outlet>
</div>

<app-insert [contact]="true">
  <ng-container title>Vous n'êtes pas client ?</ng-container>
  <p>Vous recherchez un conseil fiable et souhaitez en savoir plus sur nos différentes prestations ?</p>
  <p>
    Contactez-nous pour nous présenter votre entreprise, vos besoins : notre équipe
    <strong>Experts en ligne</strong>
    est dédiée à votre écoute.
  </p>
  <p>
    En faisant confiance à
    <strong>Aequalis Prévention</strong>
    , vous bénéficiez de 20 ans d'expériences en prévention des risques professionnels, d'audits sur site, d'outils online et d'un panel de
    solutions simples et efficaces pour sécuriser votre entreprise.
  </p>
</app-insert>
