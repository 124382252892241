import { createAction, props } from '@ngrx/store';

import { UnitOfWork } from '../models';

// Load
export const loadSuccess = createAction('[UnitOfWork/API] Load Units of Work Success', props<{ unitOfWorks: UnitOfWork[] }>());
export const loadFailure = createAction('[UnitOfWork/API] Load Units of Work Failure');
// Update
export const updateSuccess = createAction('[UnitOfWork/API] Update Unit of Work Success', props<{ unitOfWork: UnitOfWork }>());
export const updateFailure = createAction('[UnitOfWork/API] Update Unit of Work Failure');
// Move
export const moveSuccess = createAction('[UnitOfWork/API] Move Unit of Work Success', props<{ unitOfWork: UnitOfWork }>());
export const moveFailure = createAction('[UnitOfWork/API] Move Unit of Work Failure');
// Create
export const createSuccess = createAction('[UnitOfWork/API] Create Unit of Work Success', props<{ unitOfWork: UnitOfWork }>());
export const createFailure = createAction('[UnitOfWork/API] Create Unit of Work Failure');
// Delete
export const removeSuccess = createAction('[UnitOfWork/API] Delete Unit of Work Success', props<{ id: number }>());
export const removeFailure = createAction('[UnitOfWork/API] Delete Unit of Work Failure');
