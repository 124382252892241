<lib-drop-zone (dropItem)="onDrop($event)" *ngIf="possibleStatuses$ | async as possibleStatuses">
  <div class="title">
    {{ category.name }}
    <span *ngIf="riskType$ | async as riskTypes" class="count">{{ riskTypes.length }}</span>
  </div>
  <div class="header" *ngIf="trackingMode">
    <span class="count-label">Mesures existantes</span>
    <span class="count-label">Mesures préconisées</span>
  </div>
  <lib-risk-type-item
    *ngFor="let type of riskType$ | async"
    [riskType]="type"
    [possibleStatuses]="possibleStatuses"
    [dangerSourceListUrlMask]="dangerSourceListUrlMask"
    [riskDetailUrlMask]="riskDetailUrlMask"
    [trackingMode]="trackingMode"
    (update)="update(type, $event)"
    libDragItem
    [dragData]="{ id: type.id }"
  ></lib-risk-type-item>
</lib-drop-zone>
