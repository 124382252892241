import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { DangerSourceImageService } from '../../services/models/danger-source-image.service';
import { DangerSourceActions, DangerSourceImageActions, DangerSourceImageApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { AppState } from '../reducers';
import { UnitOfProductionSelectors } from '../selectors';

@Injectable()
export class DangerSourceImageEffects {
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceImageActions.create),
      mergeMap(({ dangerSourceId, values }) =>
        this._srv.create(dangerSourceId, values).pipe(
          mergeMap(image => [DangerSourceImageApiActions.createSuccess({ image }), DangerSourceActions.imageAdded({ dangerSourceId })]),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(DangerSourceImageApiActions.createFailure(), `Erreur lors de la création de l\'image`))
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceImageActions.remove),
      mergeMap(({ dangerSourceId, id }) =>
        this._srv.delete(id).pipe(
          mergeMap(() => [DangerSourceImageApiActions.removeSuccess({ id }), DangerSourceActions.imageDeleted({ dangerSourceId })]),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(DangerSourceImageApiActions.removeFailure(), `Erreur lors de la suppression de l\'image`))
          )
        )
      )
    )
  );

  loadByDangerSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceImageActions.load),
      switchMap(({ dangerSourceId }) =>
        this._srv.getAllByDangerSource(dangerSourceId).pipe(
          map(images => DangerSourceImageApiActions.loadSuccess({ images })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(DangerSourceImageApiActions.loadFailure(), `Erreur lors de la récupération des images`))
          )
        )
      )
    )
  );

  loadByUnitOfProduction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceImageActions.loadForSelectedUnitOfProduction),
      withLatestFrom(this._store.pipe(select(UnitOfProductionSelectors.getSelected))),
      switchMap(([, unitOfProdution]) =>
        this._srv.getAllByUnitOfProduction(unitOfProdution.id).pipe(
          map(images => DangerSourceImageApiActions.loadSuccess({ images })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(DangerSourceImageApiActions.loadFailure(), `Erreur lors de la récupération des images`))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceImageActions.update),
      mergeMap(({ id, values }) =>
        this._srv.update(id, values).pipe(
          map(image => DangerSourceImageApiActions.updateSuccess({ image })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(DangerSourceImageApiActions.updateFailure(), `Erreur lors de la sauvegarde des images`))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: DangerSourceImageService, private _store: Store<AppState>) {}
}
