import { Action, createReducer, on } from '@ngrx/store';
import { PageApiActions } from '../actions';
import { PageList } from '../models';

export interface State {
  pages: PageList[];
}

export const initialState: State = {
  pages: [],
};

const PageReducer = createReducer(
  initialState,
  on(PageApiActions.loadSuccess, (state, { pages }) => ({
    ...state,
    pages,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return PageReducer(state, action);
}
