import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DangerSourceImageActions } from '../../../store/actions';
import { DangerSourceImage, DangerSourceImageStatusEnum } from '../../../store/models';
import { AppState } from '../../../store/reducers';

@Component({
  selector: 'lib-picture-list',
  templateUrl: './picture-list.component.html',
  styleUrls: ['./picture-list.component.scss'],
})
export class DangerSourcePictureListComponent implements OnInit, OnDestroy {
  @Output() createEnd = new EventEmitter<void>();
  @Input() dangerSourceId!: number;
  form: UntypedFormGroup;
  @Input() images: DangerSourceImage[] = [];
  @Input() showCreate = true;
  statuses = DangerSourceImageStatusEnum;

  private _destroy$: Subject<boolean> = new Subject();

  constructor(private _store: Store<AppState>, private _fb: UntypedFormBuilder) {}

  delete(image: DangerSourceImage) {
    this._store.dispatch(DangerSourceImageActions.remove({ dangerSourceId: this.dangerSourceId, id: image.id }));
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this.form = this._fb.group(
      {
        image: [null, Validators.required],
        name: [null, Validators.required],
        status: [null, Validators.required],
      },
      {
        updateOn: 'blur',
      }
    );

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.form.valid),
        tap(values => {
          this._store.dispatch(DangerSourceImageActions.create({ dangerSourceId: this.dangerSourceId, values }));
          this.createEnd.emit();
          this.form.reset();
        })
      )
      .subscribe();
  }

  update(image: DangerSourceImage, values: Partial<DangerSourceImage>) {
    this._store.dispatch(DangerSourceImageActions.update({ id: image.id, values }));
  }
}
