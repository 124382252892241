import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Measure, MeasureNotification } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class MeasureNotificationService {
  constructor(private _http: HttpClient) {}

  create(measure: Measure, data: Partial<MeasureNotification>): Observable<MeasureNotification> {
    return this._http
      .post<MeasureNotification>(`notifications`, {
        ...data,
        measureId: measure.id,
        unitOfWorkDangerSourceId: measure.unitOfWorkDangerSourceId,
      })
      .pipe(map(itemApi => this._transformer(itemApi)));
  }

  delete(notificationId: number): Observable<MeasureNotification> {
    return this._http
      .patch<MeasureNotification>(`notifications/${notificationId}`, {
        enabled: false,
      })
      .pipe(map(itemApi => this._transformer(itemApi)));
  }

  update(notificationId: number, data: Partial<MeasureNotification>): Observable<MeasureNotification> {
    return this._http.patch<MeasureNotification>(`notifications/${notificationId}`, data).pipe(map(itemApi => this._transformer(itemApi)));
  }

  private _transformer(itemApi: MeasureNotification): MeasureNotification {
    return {
      ...itemApi,
      startAt: itemApi.startAt ? new Date(itemApi.startAt) : null,
      endAt: itemApi.endAt ? new Date(itemApi.endAt) : null,
    };
  }
}
