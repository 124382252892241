import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'urlMask' })
export class UrlMaskPipe implements PipeTransform {
  transform(urlMask: string[], replacement: any, search: string | string[] = 'id'): string[] {
    if (Array.isArray(replacement) !== Array.isArray(search)) {
      throw Error('UrlMaskPipe - search need to be array if replacement is');
    }
    if (Array.isArray(replacement) && replacement.length !== search.length) {
      throw Error('UrlMaskPipe - search and replacement array need to have the same count of element');
    }
    return urlMask.map(fragment => this._replace(fragment, replacement, search));
  }

  private _replace(fragment: string, replacement: any, search: string | string[]) {
    if (Array.isArray(replacement)) {
      replacement.forEach((val, i) => (fragment = this._replace(fragment, val, search[i])));
    } else {
      fragment = fragment.replace(`%${search}`, replacement.toString());
    }

    return fragment;
  }
}
