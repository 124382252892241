<h1>1ère connexion ou mot de passe oublié</h1>

<form (submit)="submit()" [formGroup]="form" *ngIf="!isSuccess; else message">
  <lib-message class="danger" *ngIf="isFailure">
    Une erreur s'est produite.
    <br />
    Veuillez vérifier les données saisies ou recommencer ultérieurement.
  </lib-message>

  <div class="form-field">
    <label for="email">Email</label>
    <input type="text" id="email" formControlName="email" placeholder="Email" />
    <p class="error" *ngIf="form.get('email').touched && form.get('email').hasError('email')">Cet email n'est pas un email valide</p>
  </div>

  <div class="actions">
    <button type="submit" class="button" [ngClass]="{ disabled: !form.valid }">Envoyer</button>
    <a routerLink="/login">Retour</a>
  </div>
</form>

<ng-template #message>
  <p>Un mail vient de vous être envoyé avec un lien pour réinitialiser votre mot de passe</p>
  <div class="actions">
    <a routerLink="/login" class="button">Retour</a>
  </div>
</ng-template>
