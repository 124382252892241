export enum MessageTypeEnum {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface MessageData {
  title?: string;
  content: string;
}

export interface Message extends MessageData {
  id: string;
  type: MessageTypeEnum;
}
