<lib-danger-source-picture
  *ngFor="let image of images"
  [image]="image"
  (update)="update(image, $event)"
  (deleteRequest)="delete(image)"
></lib-danger-source-picture>

<div [formGroup]="form" class="create-form" *ngIf="showCreate">
  <lib-form-image formControlName="image"></lib-form-image>
  <div class="other-controls">
    <lib-editable-data>
      <input libEditableDataInput type="text" placeholder="Légende photo" formControlName="name" />
    </lib-editable-data>
    <lib-radio-group formControlName="status">
      <lib-radio-input [value]="statuses.CONTINUE" class="success"><label>À faire perdurer</label></lib-radio-input>
      <lib-radio-input [value]="statuses.EDIT" class="warning"><label>À modifier</label></lib-radio-input>
    </lib-radio-group>
  </div>
</div>
