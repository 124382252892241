import { createAction, props } from '@ngrx/store';

import { MeasureNotification } from '../models';

export const createSuccess = createAction(
  '[Measure] Create Notification for a measure Success',
  props<{ measureId: number; notification: MeasureNotification }>()
);
export const createFailure = createAction('[Measure] Create Notification for a measure Failure');
export const updateSuccess = createAction(
  '[Measure] Update Notification for a measure Success',
  props<{ measureId: number; notification: MeasureNotification }>()
);
export const updateFailure = createAction('[Measure] Update Notification for a measure Failure');
export const removeSuccess = createAction('[Measure] Delete Notification for a measure Success', props<{ measureId: number }>());
export const removeFailure = createAction('[Measure] Delete Notification for a measure Failure');
