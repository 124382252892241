import { AppState, DateHelper, LibSelectOption, Measure, MeasureNotificationFrequencyEnum } from '@aequalib';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MeasureModalDirective } from '../modal.component';

@Component({
  selector: 'app-measure-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['../modal.component.scss', './alert.component.scss'],
})
export class MeasureAlertComponent extends MeasureModalDirective implements OnInit, OnDestroy {
  get hasNotification() {
    if (!this.measure.notification) {
      return false;
    }

    return !this.measure.notification.endAt;
  }

  frequencyOptions: LibSelectOption[] = [
    {
      value: MeasureNotificationFrequencyEnum.WEEKLY,
      label: 'Hebdomadaire',
    },
    {
      value: MeasureNotificationFrequencyEnum.MONTHLY,
      label: 'Mensuelle',
    },
    {
      value: MeasureNotificationFrequencyEnum.QUARTERLY,
      label: 'Trimestrielle',
    },
    {
      value: MeasureNotificationFrequencyEnum.ANNUAL,
      label: 'Annuelle',
    },
  ];

  constructor(
    dialogRef: MatDialogRef<MeasureAlertComponent>,
    store: Store<AppState>,
    fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data: { measure: Measure }
  ) {
    super(dialogRef, store, fb, data.measure);
  }

  delete(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this._dialogRef.close({ delete: true });
  }

  protected _createForm(): void {
    const { notification } = this.measure;
    const { startAt, frequency } = notification || {};

    this.form = this._fb.group(
      {
        startAt: [startAt, [Validators.required, DateHelper.validator]],
        frequency: [frequency || MeasureNotificationFrequencyEnum.MONTHLY, Validators.required],
      },
      { updateOn: 'blur' }
    );
  }
}
