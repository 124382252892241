import { DateHelper, FloatHelper } from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter, first, tap } from 'rxjs/operators';
import { MeasureInfoComponent } from '../../../../containers/measure/modal/info/info.component';
import { MeasureItemDirective } from '../item.component';

@Component({
  selector: 'app-measure-item-effective',
  templateUrl: './effective.component.html',
  styleUrls: ['../item.component.scss', './effective.component.scss'],
})
export class MeasureItemEffectiveComponent extends MeasureItemDirective implements OnInit {
  constructor(private _dialog: MatDialog, fb: UntypedFormBuilder) {
    super(fb);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  showInfo(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    const ref = this._dialog.open(MeasureInfoComponent, {
      data: {
        measure: this.measure,
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(values => values !== undefined),
        tap(values => this._submitModalForm(values))
      )
      .subscribe();
  }

  private _submitModalForm(values) {
    const { amount, completionDate, ...others } = values;
    this.update.emit({
      ...others,
      amount: FloatHelper.stringToNumber(amount),
      completionDate: DateHelper.formatToDate(completionDate),
    });
  }
}
