<span class="close" (click)="close()"></span>

<div class="title">Commentaire</div>

<div class="content" [formGroup]="form">
  <lib-editable-data>
    <textarea libEditableDataInput formControlName="comment"></textarea>
  </lib-editable-data>
</div>

<div class="action-wrapper">
  <span (click)="valid()" class="button">Valider</span>
</div>
