import { createAction, props } from '@ngrx/store';

import { Company, CompanyShort, Contact } from '../models';

// Load
export const loadForCurrentContractSuccess = createAction(
  '[Company/API] Load company of a contract Success',
  props<{ company: Company }>()
);
export const loadForCurrentContractFailure = createAction('[Company/API] Load company of a contract Failure');
export const loadShorts = createAction('[Company/API] Load short companies', props<{ companies: CompanyShort[] }>());
// Update
export const updateSuccess = createAction('[Company/API] Update company Success', props<{ company: Company }>());
export const updateFailure = createAction('[Company/API] Update company Failure');
// Update Referent
export const updateOrCreateReferentSuccess = createAction(
  '[Company/API] Update Referent for company Success',
  props<{ companyId: number; contact: Contact }>()
);
export const updateOrCreateReferentFailure = createAction('[Company/API] Update Referent for company Failure');
// Update Decision maker
export const updateOrCreateDecisionMakerSuccess = createAction(
  '[Company/API] Update Decision maker for company Success',
  props<{ companyId: number; contact: Contact }>()
);
export const updateOrCreateDecisionMakerFailure = createAction('[Company/API] Update Decision maker for company Failure');
