import { SharedModule } from '@aequalib';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PfwConfigurationModule } from '@prestaconcept/config-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompanyEditComponent } from './components/company/edit/edit.component';
import { InsertComponent } from './components/insert/insert.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LegalComponent } from './components/legal/legal.component';
import { MeasureItemEffectiveComponent } from './components/measure/item/effective/effective.component';
import { MeasureItemPreventiveComponent } from './components/measure/item/preventive/preventive.component';
import { PasswordComponent } from './components/password/password.component';
import { PlanningFilterComponent } from './components/planning/filter/filter.component';
import { PlanningItemComponent } from './components/planning/item/item.component';
import { RefRiskTypeItemComponent } from './components/ref-risk-type/item/item.component';
import { TopMeasureComponent } from './components/top-measure/top-measure.component';
import { UnitOfProductionPictureGalleryPageComponent } from './components/unit-of-production/picture-gallery/picture-gallery.component';
import { ForgotPasswordComponent } from './containers/anonymous/forgot-password/forgot-password.component';
import { AnonymousLayoutComponent } from './containers/anonymous/layout/layout.component';
import { LoginComponent } from './containers/anonymous/login/login.component';
import { ResetPasswordComponent } from './containers/anonymous/reset-password/reset-password.component';
import { ContactComponent } from './containers/contact/contact.component';
import { DangerSourceEditComponent } from './containers/danger-source/edit/edit.component';
import { DangerSourceListComponent } from './containers/danger-source/list/list.component';
import { DefaultComponent } from './containers/default/default.component';
import { DuerChangeContractComponent } from './containers/duer/change-contract/change-contract.component';
import { DuerHeaderComponent } from './containers/duer/header/header.component';
import { DuerLayoutComponent } from './containers/duer/layout/layout.component';
import { HeaderComponent } from './containers/header/header.component';
import { HistoryComponent } from './containers/history/history.component';
import { HomepageComponent } from './containers/homepage/homepage.component';
import { MeasureListComponent } from './containers/measure/list/list.component';
import { MeasureAlertComponent } from './containers/measure/modal/alert/alert.component';
import { MeasureInfoComponent } from './containers/measure/modal/info/info.component';
import { PlanningComponent } from './containers/planning/planning.component';
import { ProductComponent } from './containers/product/product.component';
import { RefRiskTypeListComponent } from './containers/ref-risk-type/list/list.component';
import { RiskListComponent } from './containers/risk/list/list.component';
import { UnitOfProductionDocumentComponent } from './containers/unit-of-production/document/document.component';
import { UnitOfProductionItemComponent } from './containers/unit-of-production/item/item.component';
import { UnitOfProductionListComponent } from './containers/unit-of-production/list/list.component';
import { DuerActionDirective } from './directives/duer-action.directive';
import { DuerTitleDirective } from './directives/duer-title.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import * as fromTrackingStore from './store/reducers';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    HomepageComponent,
    InsertComponent,
    AnonymousLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LegalComponent,
    DuerChangeContractComponent,
    DuerLayoutComponent,
    DuerHeaderComponent,
    DuerTitleDirective,
    DuerActionDirective,
    SidebarDirective,
    UnitOfProductionListComponent,
    UnitOfProductionItemComponent,
    RiskListComponent,
    DangerSourceListComponent,
    DangerSourceEditComponent,
    MeasureListComponent,
    MeasureItemEffectiveComponent,
    MeasureItemPreventiveComponent,
    MeasureInfoComponent,
    RefRiskTypeListComponent,
    RefRiskTypeItemComponent,
    ContactComponent,
    MeasureAlertComponent,
    DefaultComponent,
    ProductComponent,
    CompanyEditComponent,
    PlanningComponent,
    TopMeasureComponent,
    PlanningItemComponent,
    PlanningFilterComponent,
    UnitOfProductionDocumentComponent,
    HistoryComponent,
    PasswordComponent,
    UnitOfProductionPictureGalleryPageComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    PfwConfigurationModule.forRoot(environment),
    SharedModule,
    StoreModule.forFeature(fromTrackingStore.trackingStateFeatureKey, fromTrackingStore.trackingReducer),
    AppRoutingModule,
    // import here to be compliant with library initialization
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
