import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FloatHelper } from '../../helpers/float.helper';
import { Company, CompanyApi, Contact, ContactApFunctionEnum } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private _http: HttpClient) {}

  getById(companyId: number): Observable<Company> {
    return this._http.get<CompanyApi>(`companies/${companyId}`).pipe(map(companyApi => this._transformer(companyApi)));
  }

  update(id: number, values: Partial<Company>, contractId: number): Observable<Company> {
    const apiValues = {
      ...values,
      ...FloatHelper.formatForApi(values.companyContributionRateAtMP, 'companyContributionRateAtMP'),
      ...FloatHelper.formatForApi(values.nationalContributionRateAtMP, 'nationalContributionRateAtMP'),
      ...FloatHelper.formatForApi(values.companyFrequencyIndex, 'companyFrequencyIndex'),
      ...FloatHelper.formatForApi(values.nationalFrequencyIndex, 'nationalFrequencyIndex'),
      ...FloatHelper.formatForApi(values.companyGravityRate, 'companyGravityRate'),
      ...FloatHelper.formatForApi(values.nationalGravityRate, 'nationalGravityRate'),
    };

    return this._http
      .patch<CompanyApi>(`companies/${id}`, { ...apiValues, contractId })
      .pipe(map(companyApi => this._transformer(companyApi)));
  }

  updateContact(companyId: number, values: Partial<Contact>, apFunction: ContactApFunctionEnum): Observable<Contact> {
    if (values.id) {
      return this._http.patch<Contact>(`contacts/${values.id}`, values);
    } else {
      return this._http.post<Contact>(`contacts`, {
        ...values,
        companyId,
        apFunction,
      });
    }
  }

  private _transformer(itemApi: CompanyApi): Company {
    const { refActivity, ...data } = itemApi;
    return {
      ...data,
      refActivityId: refActivity.id,
    };
  }
}
