import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RiskTypeActions, RiskTypeApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { RiskType, RiskTypeStatus } from '../models';

export interface State extends EntityState<RiskType>, LoadingState {
  possibleStatuses: RiskTypeStatus[];
  selected: number;
}

export const adapter: EntityAdapter<RiskType> = createEntityAdapter<RiskType>();

export const initialState: State = {
  ...adapter.getInitialState(LoadingStateHelper.initial()),
  possibleStatuses: [],
  selected: null,
};

const RiskTypeReducer = createReducer(
  initialState,
  // Load
  on(RiskTypeActions.loadByRiskCategory, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(RiskTypeApiActions.loadSuccess, (state, { riskTypes }) => {
    return adapter.upsertMany(riskTypes, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(RiskTypeApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Load Status
  on(RiskTypeActions.loadStatuses, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(RiskTypeApiActions.loadStatusesSuccess, (state, { statuses }) => ({
    ...state,
    possibleStatuses: statuses,
  })),
  on(RiskTypeApiActions.loadStatusesFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Select
  on(RiskTypeActions.select, (state, { id }) => ({
    ...state,
    ...LoadingStateHelper.start(),
    selected: id,
  })),
  on(RiskTypeApiActions.selectSuccess, (state, { riskType }) => {
    return adapter.upsertOne(riskType, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(RiskTypeApiActions.selectFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Update
  on(RiskTypeActions.update, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(RiskTypeApiActions.updateSuccess, (state, { riskType }) => {
    return adapter.upsertOne(riskType, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(RiskTypeApiActions.updateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Move
  on(RiskTypeActions.move, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(RiskTypeApiActions.moveSuccess, (state, { riskType }) => {
    const { entities } = state;
    // reorder other riskTypes in same category
    const working = Object.values(entities)
      .filter(type => type.riskCategoryId === riskType.riskCategoryId)
      .filter(type => type.id !== riskType.id)
      .sort((typeA, typeB) => (typeA.position < typeB.position ? -1 : 1))
      .map((type, idx) => ({
        ...type,
        position: idx >= riskType.position ? idx + 1 : idx,
      }));
    working.push(riskType);

    return adapter.upsertMany(working, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(RiskTypeApiActions.moveFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Clean
  on(RiskTypeActions.clean, state => adapter.removeAll(state))
);

export function reducer(state: State | undefined, action: Action) {
  return RiskTypeReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
