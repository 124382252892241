import { ModelApi } from '../helpers/model.helper';
import { DangerSource } from './danger-source.model';

export enum DangerSourceImageStatusEnum {
  CONTINUE = 'to_continue',
  EDIT = 'to_edit',
}

export interface DangerSourceImage {
  id: number;
  image: string;
  name: string;
  status: DangerSourceImageStatusEnum;
  dangerSourceId: number;
}

export interface DangerSourceImageApi extends Omit<DangerSourceImage, 'dangerSourceId'> {
  dangerSource: Pick<DangerSource, 'id'>;
}

export interface DangerSourceImageListApi extends ModelApi<DangerSourceImageApi> {}
