<lib-line-item [formGroup]="form">
  <span class="name" [textFormat]="riskType.name"></span>
  <span class="status" *ngIf="!trackingMode">{{ status }}</span>
  <ng-container *ngIf="trackingMode">
    <div class="count-wrapper">
      <span class="count">{{ riskType.countMeasureEffective }}</span>
    </div>
    <div class="count-wrapper">
      <span class="count">{{ riskType.countMeasurePreventive }}</span>
    </div>
  </ng-container>
  <ng-container actions>
    <a [routerLink]="dangerSourceListUrlMask | urlMask: riskType.id" class="button">Sélectionner</a>
    <lib-select *ngIf="!trackingMode" formControlName="status" [options]="statusOptions" placeholder="Statut"></lib-select>
    <lib-dropdown-menu>
      <a *ngIf="!trackingMode" [routerLink]="riskDetailUrlMask | urlMask: riskType.id" libDropdownItem>Voir le récapitulatif</a>
      <a *ngIf="trackingMode" [routerLink]="riskDetailUrlMask | urlMask: riskType.id" libDropdownItem>Plus d'information</a>
    </lib-dropdown-menu>
  </ng-container>
</lib-line-item>
