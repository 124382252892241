import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DangerSource, DangerSourceApi, DangerSourceListApi } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class DangerSourceService {
  constructor(private _http: HttpClient) {}

  create(riskTypeId: number, name: string, refDangerSourceId?: number): Observable<DangerSource> {
    return this._http
      .post<DangerSourceApi>(`danger-sources`, {
        riskTypeId,
        name,
        ...(refDangerSourceId && { refDangerSourceId }),
      })
      .pipe(map(dangerSourceApi => this._transformer(dangerSourceApi)));
  }

  delete(id: number): Observable<null> {
    return this._http.delete<null>(`danger-sources/${id}`);
  }

  duplicate(id: number, riskTypeId: number): Observable<DangerSource> {
    return this._http
      .post<DangerSourceApi>(`danger-sources/duplicate/${id}`, {
        riskTypeId,
      })
      .pipe(map(dangerSourceApi => this._transformer(dangerSourceApi)));
  }

  getAllByRiskType(riskTypeId: number): Observable<DangerSource[]> {
    return this._http
      .get<DangerSourceListApi>('danger-sources', {
        params: {
          riskTypeId: riskTypeId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(({ items }) => items.map(dangerSourceApi => this._transformer(dangerSourceApi))));
  }

  select(id: number): Observable<DangerSource> {
    return this._http.get<DangerSourceApi>(`danger-sources/${id}`).pipe(map(dangerSourceApi => this._transformer(dangerSourceApi)));
  }

  update(id: number, values: Partial<DangerSource>): Observable<DangerSource> {
    return this._http
      .patch<DangerSourceApi>(`danger-sources/${id}`, values)
      .pipe(map(dangerSourceApi => this._transformer(dangerSourceApi)));
  }

  private _transformer(itemApi: DangerSourceApi): DangerSource {
    const {
      riskType,
      refDangerSource,
      nbMeasures,
      nbMeasuresCritical,
      nbMeasuresImplemented,
      nbMeasuresImportant,
      nbImages,
      nbMeasuresLow,
      nbMeasuresNormal,
      nbMeasuresPredicted,
      ...data
    } = itemApi;

    return {
      ...data,
      nbMeasures: nbMeasures || 0,
      nbMeasuresCritical: nbMeasuresCritical || 0,
      nbMeasuresImplemented: nbMeasuresImplemented || 0,
      nbMeasuresImportant: nbMeasuresImportant || 0,
      nbImages: nbImages || 0,
      nbMeasuresLow: nbMeasuresLow || 0,
      nbMeasuresNormal: nbMeasuresNormal || 0,
      nbMeasuresPredicted: nbMeasuresPredicted || 0,
      riskTypeId: riskType.id,
      refDangerSourceId: refDangerSource ? refDangerSource.id : null,
    };
  }
}
