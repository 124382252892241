export enum MeasureNotificationFrequencyEnum {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUAL = 'annual',
}

export interface MeasureNotification {
  id: number;
  startAt: Date;
  endAt: Date;
  frequency: MeasureNotificationFrequencyEnum;
  measureId: number;
  unitOfWorkDangerSourceId: number;
}
