import { createSelector } from '@ngrx/store';

import { AuthenticatedUser } from '../models/user.model';
import { getAuthenticatedState } from '../reducers';
import { State } from '../reducers/authenticated.reducer';

export const getUserType = createSelector(getAuthenticatedState, (state: State) => state.type);
export const getUser = createSelector(getAuthenticatedState, (state: State): AuthenticatedUser => {
  const { date, ...userData } = state;
  return userData;
});
