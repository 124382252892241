import { AfterViewInit, Component, ElementRef, forwardRef, HostBinding, HostListener, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-form-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormImageComponent),
      multi: true,
    },
  ],
})
export class FormImageComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('formField') formField: ElementRef<HTMLInputElement>;
  @ViewChild('preview') previewRef: ElementRef<HTMLImageElement>;
  private _disabled = false;
  private _file: File = null;
  private _focus = false;
  private _src: string;

  @HostBinding('class')
  get className() {
    const classList = [];
    if (this._focus) {
      classList.push('focus');
    }
    if (this._disabled) {
      classList.push('disabled');
    }

    return classList.join(' ');
  }

  get disabled() {
    return this._disabled;
  }

  set src(src) {
    this._src = src;
    this._preview();
  }

  constructor(private _host: ElementRef<HTMLInputElement>) {}

  ngAfterViewInit() {
    const formField = this.formField.nativeElement as HTMLInputElement;
    formField.addEventListener('focus', () => (this._focus = true));
    formField.addEventListener('blur', () => (this._focus = false));
  }

  propagateChange = (_: any) => {};
  propagateTouched = () => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this._host.nativeElement.value = '';
      this._file = null;
      this.src = value;
    }
  }

  @HostListener('change', ['$event.target.files'])
  private _onChangeFile(files: FileList) {
    this._file = files && files.item(0);

    if (this._file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.src = reader.result.toString();

        this.propagateChange(reader.result);
        this.propagateTouched();
      });
      reader.readAsDataURL(this._file);
    } else {
      this.src = null;
    }
  }

  @HostListener('click', ['$event'])
  private _onClick(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (this._disabled) {
      return;
    }

    this.formField.nativeElement.dispatchEvent(new MouseEvent('click'));
  }

  private _preview() {
    if (!this.previewRef) {
      return;
    }

    const previewEl = this.previewRef.nativeElement;
    if (this._src) {
      previewEl.src = this._src;
      previewEl.classList.add('visible');
    } else {
      previewEl.classList.remove('visible');
    }
  }
}
