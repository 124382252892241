import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

import { UnitOfProductionActions } from '../store/actions';
import { AppState } from '../store/reducers';
import { UnitOfProductionSelectors } from '../store/selectors';

export const unitOfProductionResolver = ({ params }: ActivatedRouteSnapshot): Observable<boolean> => {
  const store = inject(Store<AppState>);
  const { unitOfProductionId } = params;

  const loaded$ = store.pipe(
    select(UnitOfProductionSelectors.getSelected),
    filter(unitOfProduction => unitOfProduction !== undefined),
    first(),
    switchMap(() => of(true))
  );

  store.dispatch(UnitOfProductionActions.select({ id: +unitOfProductionId }));

  return loaded$;
};
