import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RefUnitOfProductionApi, RefUnitOfProductionListApi, RefUnitOfProductionServiceResponse } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class RefUnitOfProductionService {
  constructor(private _http: HttpClient) {}

  getAll(): Observable<RefUnitOfProductionServiceResponse[]> {
    return this._http
      .get<RefUnitOfProductionListApi>('ref-unit-of-productions', {
        params: {
          paginate: 'false',
        },
      })
      .pipe(map(({ items }) => items.map(item => this._transformer(item))));
  }

  private _transformer(itemApi: RefUnitOfProductionApi): RefUnitOfProductionServiceResponse {
    const { refProfil, ...refUnitOfProductionData } = itemApi;
    return {
      refUnitOfProduction: {
        ...refUnitOfProductionData,
        refProfileId: refProfil.id,
      },
      refProfile: refProfil,
    };
  }
}
