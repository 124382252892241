import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UnitOfProduction, UnitOfProductionApi, UnitOfProductionListApi } from '../../store/models';
import { UserTypeEnum } from '../../store/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UnitOfProductionService {
  constructor(private _http: HttpClient) {}

  create(contractId: number, name: string): Observable<UnitOfProduction> {
    return this._http
      .post<UnitOfProductionApi>(`unit-of-productions`, {
        contractId,
        name,
      })
      .pipe(map(unitOProductionApi => this._transformer(unitOProductionApi, contractId)));
  }

  delete(id: number): Observable<null> {
    return this._http.delete<null>(`unit-of-productions/${id}`);
  }

  duplicate(id: number, contractId: number): Observable<UnitOfProduction> {
    return this._http
      .post<UnitOfProductionApi>(`unit-of-productions/duplicate/${id}`, {
        contractId,
      })
      .pipe(map(unitOProductionApi => this._transformer(unitOProductionApi, contractId)));
  }

  getAllForContract(contractId: number): Observable<UnitOfProduction[]> {
    return this._http
      .get<UnitOfProductionListApi>('unit-of-productions', {
        params: {
          contractId: contractId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(result => result.items.map(item => this._transformer(item, contractId))));
  }

  select(id: number, contractId: number): Observable<UnitOfProduction> {
    return this._http
      .get<UnitOfProductionApi>(`unit-of-productions/${id}`)
      .pipe(map(unitOProductionApi => this._transformer(unitOProductionApi, contractId)));
  }

  update(id: number, values: Partial<UnitOfProduction>, contractId: number): Observable<UnitOfProduction> {
    return this._http
      .patch<UnitOfProductionApi>(`unit-of-productions/${id}`, values)
      .pipe(map(unitOProductionApi => this._transformer(unitOProductionApi, contractId)));
  }

  validate(id: number, contractId: number, userType: UserTypeEnum): Observable<UnitOfProduction> {
    const action = userType === UserTypeEnum.MANAGER ? 'validate' : 'ask-validation';
    return this._http
      .post<UnitOfProductionApi>(`unit-of-productions/${action}/${id}`, {})
      .pipe(map(unitOProductionApi => this._transformer(unitOProductionApi, contractId)));
  }

  private _transformer(itemApi: UnitOfProductionApi, contractId: number): UnitOfProduction {
    const { unitOfWorks, refUnitOfProduction, riskAssessmentExportDate, ...data } = itemApi;

    return {
      ...data,
      refUnitOfProductionId: refUnitOfProduction ? refUnitOfProduction.id : null,
      contractId,
      riskAssessmentExportDate: riskAssessmentExportDate ? new Date(riskAssessmentExportDate) : null,
    };
  }
}
