import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ContractService } from '../../services/models/contract.service';
import { CompanyApiActions, ContractActions, ContractApiActions, ProductActions } from '../actions';
import { ContractStatusEnum } from '../models';
import { AppState } from '../reducers';
import { ContractSelectors } from '../selectors';

@Injectable()
export class ContractEffects {
  loadAndSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.loadAndSelect),
      withLatestFrom(this._store.pipe(select(ContractSelectors.getSelected))),
      switchMap(([, selected]) =>
        this._srv.getByFilters().pipe(
          mergeMap(response => {
            const actions = [];
            const contracts = response.map(({ contract }) => contract);
            const products = response.map(({ product }) => product);
            const companies = response.map(({ company }) => company);

            actions.push(ContractApiActions.loadSuccess({ contracts }));
            actions.push(ProductActions.load({ products }));
            actions.push(CompanyApiActions.loadShorts({ companies }));

            if (!selected && contracts.length > 0) {
              const productWithDu = products.find(({ generatedRiskAssessmentDocument }) => generatedRiskAssessmentDocument);
              if (productWithDu) {
                actions.push(
                  ContractActions.select({
                    contractId: contracts.find(({ productId }) => productId === productWithDu.id).id,
                  })
                );
              } else {
                actions.push(ContractActions.select({ contractId: contracts[0].id }));
              }
            }

            return actions;
          }),
          catchError(() => of(ContractApiActions.loadFailure()))
        )
      )
    )
  );

  loadInProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.loadInProgress),
      switchMap(() =>
        this._srv.getByFilters({ status: ContractStatusEnum.IN_PROGRESS }).pipe(
          mergeMap(response => {
            const contracts = response.map(({ contract }) => contract);
            const products = response.map(({ product }) => product);
            return [ContractApiActions.loadSuccess({ contracts }), ProductActions.load({ products })];
          }),
          catchError(() => of(ContractApiActions.loadFailure()))
        )
      )
    )
  );

  refreshSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.refreshSelected),
      withLatestFrom(this._store.pipe(select(ContractSelectors.getSelectedId))),
      switchMap(([, id]) =>
        this._srv.getById(id).pipe(
          mergeMap(({ contract, product }) => [
            ContractApiActions.refreshSelectedSuccess({ contract }),
            ProductActions.load({ products: [product] }),
          ]),
          catchError(() => of(ContractApiActions.refreshSelectedFailure()))
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: ContractService, private _store: Store<AppState>) {}
}
