import { AfterContentInit, Component, ContentChild, HostBinding, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { EditableDataInputDirective } from '../directives/editable-data-input.directive';
import { EditableDataLabelDirective } from '../directives/editable-data-label.directive';

@Component({
  selector: 'lib-editable-data',
  templateUrl: './editable-data.component.html',
  styleUrls: ['./editable-data.component.scss'],
})
export class EditableDataComponent implements AfterContentInit, OnDestroy {
  @ContentChild(EditableDataInputDirective) inputChild: EditableDataInputDirective;
  @Input() linkedData = true;
  @Input() showEmptyLabel = false;
  @Input() showLegend = false;
  @Input() suffix;
  @ContentChild(EditableDataLabelDirective) textChild: EditableDataLabelDirective;
  @Input() writeMode = false;
  private _destroy$: Subject<boolean> = new Subject();

  @HostBinding('class')
  get className() {
    const classList = ['editable-data-field'];
    if (!this.linkedData) {
      classList.push('action-label');
    }

    if (this.suffix) {
      classList.push('has-suffix');
    }

    if (this.writeMode && this.showLegend) {
      classList.push('has-legend');
    }

    if (this.inputChild.isMultiLine) {
      classList.push('multiline');
    }

    return classList.join(' ');
  }

  ngAfterContentInit(): void {
    if (this.inputChild === undefined) {
      throw new Error('Editable Text need an input element');
    }

    this.inputChild.event$
      .pipe(
        takeUntil(this._destroy$),
        filter(eventName => eventName === 'blur'),
        filter(() => this.inputChild.valid),
        tap(() => {
          this.writeMode = false;
          this._refresh();
        })
      )
      .subscribe();

    this.inputChild.event$
      .pipe(
        takeUntil(this._destroy$),
        filter(eventName => eventName === 'focus'),
        filter(() => this.writeMode === false),
        tap(() => {
          this.writeMode = true;
          this._refresh();
        })
      )
      .subscribe();

    if (this.textChild) {
      this.textChild.event$
        .pipe(
          takeUntil(this._destroy$),
          filter(eventName => eventName === 'click'),
          tap(() => {
            this.writeMode = true;
            this._refresh();
            this.inputChild.focus();
          })
        )
        .subscribe();
    }

    this._refresh();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  private _refresh() {
    if (this.textChild === undefined) {
      this.inputChild.show();
      return;
    }

    if (this.linkedData) {
      this.textChild.value = this.inputChild.value || this.inputChild.placeholder;
    }

    if (this.writeMode || (this.linkedData && !this.showEmptyLabel && this.inputChild.value === '')) {
      this.inputChild.show();
      this.textChild.hide();
    } else {
      this.inputChild.hide();
      this.textChild.show();
    }
  }
}
