import { Action, createReducer, on } from '@ngrx/store';
import { PlanningFilters } from '../../components/planning/filter/filter.component';
import { UiActions } from '../actions';

export interface State {
  planningFilters: PlanningFilters;
}

export const initialState: State = {
  planningFilters: {},
};

const UiReducer = createReducer(
  initialState,
  on(UiActions.savePlanningFilters, (state, { data }) => ({
    ...state,
    planningFilters: data,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return UiReducer(state, action);
}
