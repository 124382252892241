import { ActionReducerMap } from '@ngrx/store';
import * as FromUi from './ui.reducer';

export const trackingStateFeatureKey = 'tracking';

export interface TrackingState {
  ui: FromUi.State;
}

export const trackingReducer: ActionReducerMap<TrackingState> = {
  ui: FromUi.reducer,
};

export const getUiState = (state: TrackingState) => state.ui;
