import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RefRiskType, RefRiskTypeListApi } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class RefRiskTypeService {
  constructor(private _http: HttpClient) {}

  getAll(): Observable<RefRiskType[]> {
    return this._http
      .get<RefRiskTypeListApi>('ref-risk-types', {
        params: {
          paginate: 'false',
        },
      })
      .pipe(map(({ items }) => items));
  }
}
