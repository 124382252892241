import { AuthenticationService } from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first, tap } from 'rxjs/operators';
import { RequestStateEnum } from '../../../helpers/request';
import { AnonymousLayoutComponent } from '../layout/layout.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent extends AnonymousLayoutComponent implements OnInit {
  constructor(srv: AuthenticationService, fb: UntypedFormBuilder, private _router: Router) {
    super(srv, fb);
  }

  ngOnInit(): void {
    if (this._srv.isAuthenticated) {
      this._goToHomepage();
    }

    this.form = this._fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  submit() {
    if (this.form.valid) {
      const { username, password } = this.form.value;

      this._srv
        .login(username, password)
        .pipe(
          first(),
          tap(result => {
            this._state = result ? RequestStateEnum.SUCCESS : RequestStateEnum.FAILURE;

            if (result) {
              this._goToHomepage();
            } else {
              this.form.get('password').setValue('');
              this.form.get('password').markAsUntouched();
              this.form.get('password').markAsPristine();
            }
          })
        )
        .subscribe();
    }
  }

  private _goToHomepage() {
    this._router.navigate(['']);
  }
}
