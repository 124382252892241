<lib-drop-zone (dropItem)="onDrop($event)">
  <ng-container *ngFor="let unitOfWork of unitOfWork$ | async">
    <lib-unit-of-work-item
      *ngIf="isVisible(unitOfWork); else ghost"
      [unitOfWork]="unitOfWork"
      [trackingMode]="trackingMode"
      (update)="update(unitOfWork, $event)"
      (deleteRequest)="delete(unitOfWork)"
      (archiveRequest)="archiveToggle(unitOfWork)"
      libDragItem
      [dragData]="{ id: unitOfWork.id }"
    ></lib-unit-of-work-item>

    <ng-template #ghost>
      <div libDragItem></div>
    </ng-template>
  </ng-container>
  <div class="unit-of-work-create" [formGroup]="form" end>
    <lib-editable-data [linkedData]="false">
      <span libEditableDataLabel>Ajouter une unité de travail</span>
      <input libEditableDataInput placeholder="Nom de l'unité de travail" formControlName="name" />
    </lib-editable-data>
  </div>
</lib-drop-zone>
