<div class="title">Choix d'un nouveau profil d'activité</div>

<div class="form-wrapper" [formGroup]="form">
  <lib-select [options]="refProfiles" formControlName="refProfileId">
    <label>Profil d'activité</label>
  </lib-select>
  <lib-select [options]="refUnitOfProductions" formControlName="refUnitOfProductionId">
    <label>Unité de production de référence</label>
  </lib-select>
</div>

<div class="action-wrapper">
  <span (click)="cancel()" class="button shadow">Annuler</span>
  <span (click)="submit()" class="button" [ngClass]="{ disabled: !form.valid }">Valider</span>
</div>
