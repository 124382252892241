import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  UnitOfWorkDangerSource,
  UnitOfWorkDangerSourceApi,
  UnitOfWorkDangerSourceListApi,
  UnitOfWorkDangerSourceServiceResponse,
} from '../../store/models';
import { MeasureService } from './measure.service';

@Injectable({
  providedIn: 'root',
})
export class UnitOfWorkDangerSourceService {
  constructor(private _http: HttpClient, private _measureSrv: MeasureService) {}

  create(unitOfWorkId: number, dangerSourceId: number): Observable<UnitOfWorkDangerSourceServiceResponse> {
    return this._http
      .post<UnitOfWorkDangerSourceApi>(`unit-of-work-danger-sources`, {
        unitOfWorkId,
        dangerSourceId,
      })
      .pipe(map(unitOfWorkDangerSourceApi => this._transformer(unitOfWorkDangerSourceApi)));
  }

  delete(id: number): Observable<null> {
    return this._http.delete<null>(`unit-of-work-danger-sources/${id}`);
  }

  duplicate(id: number, unitOfWorkId?: number, dangerSourceId?: number): Observable<UnitOfWorkDangerSourceServiceResponse> {
    return this._http
      .post<UnitOfWorkDangerSourceApi>(`unit-of-work-danger-sources/duplicate/${id}`, {
        ...(unitOfWorkId ? { unitOfWorkId } : {}),
        ...(dangerSourceId ? { dangerSourceId } : {}),
      })
      .pipe(map(unitOfWorkDangerSourceApi => this._transformer(unitOfWorkDangerSourceApi)));
  }

  getAllByDangerSource(dangerSourceId: number): Observable<UnitOfWorkDangerSourceServiceResponse[]> {
    return this._http
      .get<UnitOfWorkDangerSourceListApi>('unit-of-work-danger-sources', {
        params: {
          dangerSourceId: dangerSourceId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(result => result.items.map(item => this._transformer(item))));
  }

  update(id: number, values: Partial<UnitOfWorkDangerSource>): Observable<UnitOfWorkDangerSourceServiceResponse> {
    return this._http
      .patch<UnitOfWorkDangerSourceApi>(`unit-of-work-danger-sources/${id}`, values)
      .pipe(map(unitOfWorkDangerSourceApi => this._transformer(unitOfWorkDangerSourceApi)));
  }

  private _transformer(itemApi: UnitOfWorkDangerSourceApi): UnitOfWorkDangerSourceServiceResponse {
    const { dangerSource, unitOfWork, measures, ...data } = itemApi;

    return {
      unitOfWorkDangerSource: {
        ...data,
        dangerSourceId: dangerSource.id,
        unitOfWorkId: unitOfWork.id,
      },
      measures: measures.map(measure => this._measureSrv.transformer(measure, data.id)),
    };
  }
}
