import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { History } from '../models';
import { getHistoryState } from '../reducers';
import { State } from '../reducers/history.reducer';
import * as unitOfProductionSelectors from './unit-of-production.selectors';

const getEntitiesFromState = createSelector(getHistoryState, (state: State): Dictionary<History> => state.entities);

export const getAll = createSelector(getEntitiesFromState, entities => Object.keys(entities).map(id => entities[id]));

export const getBySelectedUnitOfProduction = createSelector(getAll, unitOfProductionSelectors.getSelected, (entities, { id }) =>
  entities.filter(({ unitOfProductionId }) => unitOfProductionId === id).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
);
