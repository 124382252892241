import { ModelApi } from '../helpers/model.helper';
import { CompanyShort } from './company.model';
import { Product } from './product.model';

export enum ContractStatusEnum {
  IN_PROGRESS = 'in_progress',
  TERMINATED = 'terminated',
  CLOSED = 'closed',
}

export interface Contract {
  id: number;
  number: string;
  status: ContractStatusEnum;
  companyId: number;
  startAt: Date;
  productId: number;
  document: string;
  methodologicalPointDocument: string;
}

export interface ContractApi extends Omit<Contract, 'companyId' | 'productId'> {
  company: CompanyShort;
  product: Product;
}

export interface ContractListApi extends ModelApi<ContractApi> {}

export interface ContractServiceResponse {
  company: CompanyShort;
  contract: Contract;
  product: Product;
}
