import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { RefUnitOfProduction } from '../models';
import { getRefUnitOfProductionState } from '../reducers';
import { State } from '../reducers/ref-activity.reducer';
import * as RefProfileSelectors from './ref-profile.selectors';

const getEntitiesFromState = createSelector(getRefUnitOfProductionState, (state: State): Dictionary<RefUnitOfProduction> => state.entities);

export const getAll = createSelector(getEntitiesFromState, entities =>
  Object.keys(entities)
    .map(id => entities[id])
    .sort((a, b) => (a.name > b.name ? 1 : -1))
);

export const getBySelectedRefProfile = createSelector(getAll, RefProfileSelectors.getSelected, (refUnitOfProductions, refProfile) => {
  return refProfile ? refUnitOfProductions.filter(curr => curr.refProfileId === refProfile.id) || [] : [];
});
