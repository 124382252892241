import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';

import { RefProfile, RefUnitOfProduction, UnitOfProduction, UnitOfProductionStatusEnum, UserTypeEnum } from '../../../store/models';
import { AppState } from '../../../store/reducers';
import { RefProfileSelectors } from '../../../store/selectors';
import { UnitOfProductionProfileComponent } from '../profile/profile.component';

@Component({
  selector: 'lib-unit-of-production-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class UnitOfProductionItemComponent implements OnInit, OnChanges, OnDestroy {
  @Output() changeReference: EventEmitter<number> = new EventEmitter();
  @Output() deleteRequest: EventEmitter<null> = new EventEmitter();
  @Input() documentUrlMask: string[];
  @Output() duplicateRequest: EventEmitter<boolean> = new EventEmitter();
  form: UntypedFormGroup;
  @Input() historyUrlMask: string[];
  @Input() planningUrlMask: string[];
  profile$: Observable<RefProfile>;
  @Input() refUnitOfProductions: RefUnitOfProduction[];
  @Input() riskUrlMask: string[];
  @Input() topUrlMask: string[];
  @Input() trackingMode: boolean;
  @Input() unitOfProduction: UnitOfProduction;
  @Output() update: EventEmitter<Partial<UnitOfProduction>> = new EventEmitter();
  @Input() userType: UserTypeEnum;
  @Output() validateRequest: EventEmitter<null> = new EventEmitter();
  private _destroy$: Subject<boolean> = new Subject();

  @HostBinding('class')
  get className(): string {
    return 'unit-of-production-item';
  }

  get userIsManager(): boolean {
    return this.userType === UserTypeEnum.MANAGER;
  }

  get canBeValidated(): boolean {
    return this.unitOfProduction.status === UnitOfProductionStatusEnum.IN_WRITING && this.userType !== UserTypeEnum.CUSTOMER;
  }

  constructor(private _fb: UntypedFormBuilder, private _store: Store<AppState>, private _dialog: MatDialog) {}

  delete(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.deleteRequest.emit();
  }

  duplicate(e: Event, ask = false) {
    e.stopPropagation();
    e.preventDefault();

    this.duplicateRequest.emit(ask);
  }

  ngOnChanges(values) {
    if (values.refUnitOfProductions) {
      const refUnitOfProduction: RefUnitOfProduction = this.refUnitOfProductions.find(
        curr => curr.id === this.unitOfProduction.refUnitOfProductionId
      );
      this.profile$ = this._store.pipe(select(RefProfileSelectors.getById(refUnitOfProduction ? refUnitOfProduction.refProfileId : null)));
    }
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit() {
    this.form = this._fb.group(
      {
        name: [this.unitOfProduction.name, Validators.required],
        description: [this.unitOfProduction.description],
      },
      { updateOn: 'blur' }
    );

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.form.valid),
        tap(values => this.update.emit(values))
      )
      .subscribe();
  }

  selectProfile(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    const ref = this._dialog.open(UnitOfProductionProfileComponent, {
      data: {
        unitOfProduction: this.unitOfProduction,
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(value => value),
        tap(value => this.changeReference.emit(value))
      )
      .subscribe();
  }

  validate(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.validateRequest.emit();
  }
}
