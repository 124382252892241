import { AuthenticationService } from '@aequalib';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  get isAuthenticated(): boolean {
    return this._authSrv.isAuthenticated;
  }

  pageClass = '';
  private _destroy$: Subject<boolean> = new Subject();

  constructor(private _authSrv: AuthenticationService, private _layoutSrv: LayoutService) {}

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit() {
    this._layoutSrv.pageClass$
      .pipe(
        takeUntil(this._destroy$),
        tap(className => (this.pageClass = className))
      )
      .subscribe();
  }
}
