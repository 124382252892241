import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { RefProfileActions } from '../actions';
import { RefProfile } from '../models';

export interface State extends EntityState<RefProfile> {
  selected: number;
}

export const adapter: EntityAdapter<RefProfile> = createEntityAdapter<RefProfile>();

export const initialState: State = adapter.getInitialState({
  selected: null,
});

const RefProfileReducer = createReducer(
  initialState,
  // Load
  on(RefProfileActions.load, (state, { refProfiles }) => {
    return adapter.upsertMany(refProfiles, {
      ...state,
    });
  }),
  // Select
  on(RefProfileActions.select, (state, { id }) => ({
    ...state,
    selected: id,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return RefProfileReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
