import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Company, CompanyShort } from '../models';
import { getCompanyState } from '../reducers';
import { State } from '../reducers/company.reducer';

const getEntitiesFromState = createSelector(getCompanyState, (state: State): Dictionary<Company> => state.entities);
const getShortsFromState = createSelector(getCompanyState, (state: State): Dictionary<CompanyShort> => state.shorts);
const getSelectedFromState = createSelector(getCompanyState, (state: State) => state.selected);

export const getAll = createSelector(getEntitiesFromState, entities => Object.keys(entities).map(id => entities[id]) as Company[]);
export const getShorts = createSelector(getShortsFromState, entities => Object.keys(entities).map(id => entities[id]) as CompanyShort[]);
export const getSelected = createSelector(getAll, getSelectedFromState, (entities, companyId) =>
  entities.find(entity => entity.id === companyId)
);
export const getAllWithShorts = createSelector(
  getEntitiesFromState,
  getShortsFromState,
  (entities, shorts) => Object.values({ ...shorts, ...entities }) as (Company | CompanyShort)[]
);
