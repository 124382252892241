import { createAction, props } from '@ngrx/store';
import { DangerSource } from '../models';

// Load
export const loadSuccess = createAction('[DangerSource/API] Load Danger sources Success', props<{ dangerSources: DangerSource[] }>());
export const loadFailure = createAction('[DangerSource/API] Load Danger sources Failure');
// Update
export const updateSuccess = createAction('[DangerSource/API] Update Danger source Success', props<{ dangerSource: DangerSource }>());
export const updateFailure = createAction('[DangerSource/API] Update Danger source Failure');
// Move
export const moveSuccess = createAction('[DangerSource/API] Move Danger source Success', props<{ dangerSource: DangerSource }>());
export const moveFailure = createAction('[DangerSource/API] Move Danger source Failure');
// Create
export const createSuccess = createAction('[DangerSource/API] Create Danger source Success', props<{ dangerSource: DangerSource }>());
export const createFailure = createAction('[DangerSource/API] Create Danger source Failure');
// Delete
export const removeSuccess = createAction('[DangerSource/API] Delete Danger source Success', props<{ id: number }>());
export const removeFailure = createAction('[DangerSource/API] Delete Danger source Failure');
// Select
export const selectSuccess = createAction('[DangerSource/API] Select Danger source Success', props<{ dangerSource: DangerSource }>());
export const selectFailure = createAction('[DangerSource/API] Select Danger source Failure');
// Duplicate
export const duplicateSuccess = createAction('[DangerSource/API] Duplicate Danger source Success', props<{ dangerSource: DangerSource }>());
export const duplicateFailure = createAction('[DangerSource/API] Duplicate Danger source Failure');
