import { Action, createReducer, on } from '@ngrx/store';

import { AuthenticatedActions } from '../actions';
import { AuthenticatedUser, UserTypeEnum } from '../models/user.model';

export interface State extends AuthenticatedUser {
  date: Date;
}

export const initialState: State = {
  id: null,
  username: null,
  contractId: null,
  type: UserTypeEnum.CUSTOMER,
  date: null,
  config: null,
};

const AuthenticatedReducer = createReducer(
  initialState,
  // Logged in
  on(AuthenticatedActions.loggedIn, (state, { user }) => ({
    ...state,
    ...user,
    date: new Date(),
  })),
  // Logout
  on(AuthenticatedActions.logout, state => ({
    ...state,
    ...initialState,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return AuthenticatedReducer(state, action);
}
