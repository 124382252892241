import { AfterContentChecked, Component, ContentChildren, HostBinding, Input, QueryList } from '@angular/core';

import { DropdownItemDirective } from '../directives/dropdown-item.directive';

@Component({
  selector: 'lib-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements AfterContentChecked {
  @ContentChildren(DropdownItemDirective) links: QueryList<DropdownItemDirective>;
  @Input() position: 'before' | 'center' | 'after' = 'center';
  private _opened = false;

  @HostBinding('class')
  get className(): string {
    const classList = ['dropdown-menu', `pos-${this.position}`];

    if (this._opened) {
      classList.push('open');
    }

    return classList.join(' ');
  }

  close() {
    this._opened = false;
  }

  ngAfterContentChecked() {
    this.links.forEach(link => {
      if (!link.initiate) {
        link.elt.addEventListener('click', () => this.close());
        link.initiate = true;
      }
    });
  }

  toggle() {
    this._opened = !this._opened;
  }
}
