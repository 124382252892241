import { Component, forwardRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    },
  ],
})
export class PasswordComponent implements ControlValueAccessor, OnDestroy {
  control = new FormControl('');
  @Input() controlId: string = 'password';
  controlType = 'password';
  @Input() placeholder: string = 'Mot de passe';
  private _destroy$ = new Subject<boolean>();

  @HostBinding('class')
  get className(): string {
    const classList = ['app-password'];

    if (this.controlType !== 'password') {
      classList.push('is-visible');
    }

    return classList.join(' ');
  }

  constructor() {
    this.control.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap(value => {
          if (value === null) {
            return;
          }

          this._onChange(value);
          this._onTouched();
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  onViewClick() {
    this.controlType = this.controlType === 'password' ? 'text' : 'password';
  }

  registerOnChange(fn) {
    this._onChange = fn;
  }

  registerOnTouched(fn) {
    this._onTouched = fn;
  }

  writeValue(value: string) {
    if (value !== undefined) {
      this.control.setValue(value, { emitEvent: false });
    }
  }

  private _onChange = (_: string) => {};
  private _onTouched = () => {};
}
