<div class="counter">
  <span>{{ index + 1 }}</span>
</div>
<div class="risk">
  <span class="risk-type" class="{{ topMeasure.riskCategory.colorClass }}" [textFormat]="topMeasure.riskType.name"></span>
  <span class="danger-source" [textFormat]="topMeasure.dangerSource.name"></span>
</div>
<div class="unit-of-work" [textFormat]="topMeasure.unitOfWork.name"></div>
<div class="quotation">
  <span>{{ quotationLabel }}</span>
  <span>{{ quotationSummary }}</span>
</div>
<div class="remove"><a href="#" class="star" (click)="remove($event)"></a></div>
<div class="measure" [textFormat]="topMeasure.measure.name"></div>
<ng-container [formGroup]="form">
  <div class="amount" *ngIf="isPapripact" (click)="prevent($event)">
    <lib-editable-data [showEmptyLabel]="true" suffix="€">
      <span libEditableDataLabel></span>
      <input libEditableDataInput placeholder="--" formControlName="amount" />
    </lib-editable-data>
  </div>
  <div class="in-charge" (click)="prevent($event)">
    <lib-editable-data [showEmptyLabel]="true">
      <span libEditableDataLabel></span>
      <input libEditableDataInput placeholder="--" formControlName="inCharge" />
    </lib-editable-data>
  </div>
  <div class="date" (click)="prevent($event)">
    <lib-editable-data [showEmptyLabel]="true">
      <span libEditableDataLabel></span>
      <input
        libEditableDataInput
        libDateAutoFormatter
        placeholder="--"
        formControlName="predictedDate"
        [matDatepicker]="picker"
        (focus)="picker.open()"
      />
      <mat-datepicker #picker></mat-datepicker>
    </lib-editable-data>
  </div>
  <div class="result-indicator" *ngIf="isPapripact" (click)="prevent($event)">
    <lib-editable-data [showEmptyLabel]="true">
      <span libEditableDataLabel></span>
      <textarea libEditableDataInput placeholder="--" formControlName="resultIndicator"></textarea>
    </lib-editable-data>
  </div>
</ng-container>
