export const textFormatter = (text: string): string => {
  if (!text) {
    return '';
  }

  return text
    .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>')
    .replace(/~~(.+?)~~/g, '<i>$1</i>')
    .replace(/__(.+?)__/g, '<u>$1</u>')
    .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '<br />');
};
