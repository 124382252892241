import { AppState, Measure, UnitOfWork, UnitOfWorkSelectors } from '@aequalib';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Directive()
export abstract class MeasureModalDirective implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  selectedUnitOfWork: UnitOfWork;

  private _destroy$: Subject<boolean> = new Subject();

  constructor(
    protected _dialogRef: MatDialogRef<MeasureModalDirective>,
    protected _store: Store<AppState>,
    protected _fb: UntypedFormBuilder,
    public measure: Measure
  ) {}

  close() {
    this._dialogRef.close();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this._store
      .pipe(
        select(UnitOfWorkSelectors.getBySelectedUnitOfWorkDangerSource),
        takeUntil(this._destroy$),
        tap(unitOfWork => (this.selectedUnitOfWork = unitOfWork))
      )
      .subscribe();

    this._createForm();
  }

  protected abstract _createForm();

  submit() {
    if (this.form.valid) {
      this._dialogRef.close(this.form.value);
    }
  }
}
