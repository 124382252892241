import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { HistoryService } from '../../services/models/history.service';
import { HistoryActions, HistoryApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { AppState } from '../reducers';
import { UnitOfProductionSelectors } from '../selectors';

@Injectable()
export class HistoryEffects {
  cancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HistoryActions.cancel),
      withLatestFrom(this._store.pipe(select(UnitOfProductionSelectors.getSelected))),
      mergeMap(([{ id }, { id: unitOfProductionId }]) =>
        this._srv.cancel(id, unitOfProductionId).pipe(
          map(() => HistoryApiActions.cancelSuccess({ id })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(HistoryApiActions.cancelFailure(), `Erreur lors de l'annulation de la donnée d'historique`))
          )
        )
      )
    )
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HistoryActions.load),
      withLatestFrom(this._store.pipe(select(UnitOfProductionSelectors.getSelected))),
      switchMap(([, unitOfProduction]) =>
        this._srv.getByUnitOfProduction(unitOfProduction.id).pipe(
          map(histories => HistoryApiActions.loadSuccess({ histories })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(HistoryApiActions.loadFailure(), `Erreur lors de la récupération des données d'historique`))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: HistoryService, private _store: Store<AppState>) {}
}
