import { AppState, ContactFormActions, ContactFormSelectors, ContactSubjectEnum, LibSelectOption } from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { filter, first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  form: UntypedFormGroup;
  sended = false;
  subjectOptions: LibSelectOption[] = [
    { value: ContactSubjectEnum.EDIT_DUER, label: `Comment modifier mon DUER ?` },
    { value: ContactSubjectEnum.PRINT_DUER, label: `Comment imprimer mon DUER ?` },
    { value: ContactSubjectEnum.ADVISE, label: `J'ai besoin des conseils d'un préventeur` },
    { value: ContactSubjectEnum.HELP, label: `J'ai besoin d'aide sur le logiciel` },
    { value: ContactSubjectEnum.CALLBACK_DUER, label: `Me rappeler pour mettre à jour mon Document unique` },
    { value: ContactSubjectEnum.OTHER, label: `Autres` },
  ];

  constructor(private _fb: UntypedFormBuilder, private _store: Store<AppState>) {}

  ngOnInit(): void {
    this._store
      .pipe(
        select(ContactFormSelectors.getSendedWithSuccess),
        filter(sended => sended),
        first(),
        tap(sended => (this.sended = sended))
      )
      .subscribe();

    this._store.dispatch(ContactFormActions.reinit());

    this.form = this._fb.group({
      name: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: [ContactSubjectEnum.EDIT_DUER, Validators.required],
      message: ['', Validators.required],
    });
  }

  submit() {
    if (this.form.valid) {
      this._store.dispatch(ContactFormActions.send({ formData: this.form.value }));
    }
  }
}
