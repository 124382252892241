<h1>Connexion</h1>

<form (submit)="submit()" [formGroup]="form">
  <lib-message class="danger" *ngIf="isFailure">
    Une erreur s'est produite durant la connexion.
    <br />
    vérifier les données saisies ou recommencer ultérieurement.
  </lib-message>

  <div class="form-field">
    <label for="username">Identifiant</label>
    <input type="text" id="username" formControlName="username" placeholder="Identifiant" />
  </div>
  <div class="form-field">
    <label for="password">Mot de passe</label>
    <app-password formControlName="password"></app-password>
  </div>

  <div class="actions">
    <button type="submit" class="button" [ngClass]="{ disabled: !form.valid }">Se connecter</button>
    <a routerLink="/mot-de-passe-oublie">1ère connexion ou mot de passe oublié</a>
  </div>
</form>
