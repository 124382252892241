import {
  AppState,
  ConfirmModalComponent,
  History,
  HistoryActionEnum,
  HistoryActions,
  HistoryObjectEnum,
  HistorySelectors,
} from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  histories$: Observable<History[]>;

  constructor(private _store: Store<AppState>, private _dialog: MatDialog) {}

  cancel(e: MouseEvent, id: number) {
    e.preventDefault();
    e.stopPropagation();

    const ref = this._dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Êtes vous sûr de vouloir Annuler cette action',
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(confirm => confirm),
        tap(() => this._store.dispatch(HistoryActions.cancel({ id })))
      )
      .subscribe();
  }

  getActionLabel(action: HistoryActionEnum): string {
    switch (action) {
      case HistoryActionEnum.DELETE:
        return 'suppression';
    }
  }

  getObjectLabel(object: HistoryObjectEnum): string {
    switch (object) {
      case HistoryObjectEnum.MEASURE:
        return 'mesure';
      case HistoryObjectEnum.DANGER_SOURCE:
        return 'Situation dangereuse';
      case HistoryObjectEnum.UNIT_OF_WORK:
        return 'Unité de travail';
      case HistoryObjectEnum.UNIT_OF_WORK_DANGER_SOURCE:
        return 'UT <> SD';
    }

    return '';
  }

  ngOnInit(): void {
    this.histories$ = this._store.pipe(select(HistorySelectors.getBySelectedUnitOfProduction));
    this._store.dispatch(HistoryActions.load());
  }
}
