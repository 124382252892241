import { createAction, props } from '@ngrx/store';

import { DangerSourceImage } from '../models';

// Load
export const loadSuccess = createAction(
  '[DangerSourceImage/API] Load images of a danger source Success',
  props<{ images: DangerSourceImage[] }>()
);
export const loadFailure = createAction('[DangerSourceImage/API] Load images of a danger source Failure');
// Update
export const updateSuccess = createAction(
  '[DangerSourceImage/API] Update image of a danger source Success',
  props<{ image: DangerSourceImage }>()
);
export const updateFailure = createAction('[DangerSourceImage/API] Update image of a danger source Failure');
// Create
export const createSuccess = createAction(
  '[DangerSourceImage/API] Create image of a danger source Success',
  props<{ image: DangerSourceImage }>()
);
export const createFailure = createAction('[DangerSourceImage/API] Create image of a danger source Failure');
// Delete
export const removeSuccess = createAction('[DangerSourceImage/API] Delete image of a danger source Success', props<{ id: number }>());
export const removeFailure = createAction('[DangerSourceImage/API] Delete image of a danger source Failure');
