import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { UnitOfProductionService } from '../../services/models/unit-of-production.service';
import { RiskCategoryActions, UnitOfProductionActions, UnitOfProductionApiActions, UnitOfWorkActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { AppState } from '../reducers';
import {
  AuthenticatedSelectors,
  ContractSelectors,
  RiskCategorySelectors,
  UnitOfProductionSelectors,
  UnitOfWorkSelectors,
} from '../selectors';

@Injectable()
export class UnitOfProductionEffects {
  changeReference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfProductionActions.changeReference),
      withLatestFrom(this._store.pipe(select(ContractSelectors.getSelected))),
      mergeMap(([{ id, refUnitOfProductionId }, contract]) =>
        this._srv.update(id, { refUnitOfProductionId }, contract.id).pipe(
          mergeMap(unitOfProduction => [
            UnitOfProductionActions.cleanDependencies({ id: unitOfProduction.id }),
            UnitOfProductionApiActions.updateSuccess({ unitOfProduction }),
          ]),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(UnitOfProductionApiActions.updateFailure(), `Erreur lors de la modification de la référence`)
            )
          )
        )
      )
    )
  );

  clean$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfProductionActions.cleanDependencies),
      mergeMap(({ id }) =>
        of(id).pipe(
          withLatestFrom(
            this._store.pipe(select(UnitOfWorkSelectors.getAllByUnitOfProduction(id))),
            this._store.pipe(select(RiskCategorySelectors.getByUnitOfProduction(id)))
          )
        )
      ),
      mergeMap(([, unitOfWorks, riskCategories]) =>
        [].concat(
          unitOfWorks.map(({ id }) => UnitOfWorkActions.cleanOne({ id })),
          riskCategories.map(({ id }) => RiskCategoryActions.cleanOne({ id }))
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfProductionActions.create),
      withLatestFrom(this._store.pipe(select(ContractSelectors.getSelected))),
      mergeMap(([{ name }, contract]) =>
        this._srv.create(contract.id, name).pipe(
          map(unitOfProduction => UnitOfProductionApiActions.createSuccess({ unitOfProduction })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(UnitOfProductionApiActions.createFailure(), `Erreur lors de la création de l'unité de production`)
            )
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfProductionActions.remove),
      mergeMap(({ id }) =>
        this._srv.delete(id).pipe(
          map(() => UnitOfProductionApiActions.removeSuccess({ id })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(UnitOfProductionApiActions.removeFailure(), `Erreur lors de la suppression de l'unité de production`)
            )
          )
        )
      )
    )
  );

  duplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfProductionActions.duplicate),
      withLatestFrom(this._store.pipe(select(ContractSelectors.getSelected))),
      mergeMap(([{ id, contractId }, currentContract]) =>
        this._srv.duplicate(id, contractId ? contractId : currentContract.id).pipe(
          map(unitOfProduction => UnitOfProductionApiActions.duplicateSuccess({ unitOfProduction })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(UnitOfProductionApiActions.duplicateFailure(), `Erreur lors de la duplication de l'unité de production`)
            )
          )
        )
      )
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfProductionActions.load),
      withLatestFrom(this._store.pipe(select(ContractSelectors.getSelected))),
      switchMap(([, { id }]) =>
        this._srv.getAllForContract(id).pipe(
          map(unitOfProductions => UnitOfProductionApiActions.loadSuccess({ unitOfProductions })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(UnitOfProductionApiActions.loadFailure(), 'Erreur lors de la récupération des unités de production')
            )
          )
        )
      )
    )
  );

  select$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfProductionActions.select),
      withLatestFrom(
        this._store.pipe(select(ContractSelectors.getSelected)),
        this._store.pipe(select(UnitOfProductionSelectors.getSelected))
      ),
      mergeMap(([{ id }, contract, unitOfProduction]) =>
        unitOfProduction === undefined
          ? this._srv.select(id, contract.id).pipe(
              map(unitOfProductionResult => UnitOfProductionApiActions.selectSuccess({ unitOfProduction: unitOfProductionResult })),
              catchError(() =>
                of(true).pipe(
                  EffectHelper.failure(UnitOfProductionApiActions.selectFailure(), `Erreur lors de la selection de l'unité de production`)
                )
              )
            )
          : of(UnitOfProductionApiActions.selectSuccess({ unitOfProduction }))
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfProductionActions.update),
      withLatestFrom(this._store.pipe(select(ContractSelectors.getSelected))),
      mergeMap(([{ id, values }, contract]) =>
        this._srv.update(id, values, contract.id).pipe(
          map(unitOfProduction => UnitOfProductionApiActions.updateSuccess({ unitOfProduction })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(UnitOfProductionApiActions.updateFailure(), `Erreur lors de la sauvegarde de l'unité de production`)
            )
          )
        )
      )
    )
  );

  validate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfProductionActions.validate),
      withLatestFrom(this._store.pipe(select(ContractSelectors.getSelected)), this._store.pipe(select(AuthenticatedSelectors.getUserType))),
      mergeMap(([{ id }, contract, userType]) =>
        this._srv.validate(id, contract.id, userType).pipe(
          map(unitOfProduction => UnitOfProductionApiActions.validateSuccess({ unitOfProduction })),
          catchError(() => of(UnitOfProductionApiActions.validateFailure()))
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: UnitOfProductionService, private _store: Store<AppState>) {}
}
