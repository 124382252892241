import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { RiskTypeService } from '../../services/models/risk-type.service';
import { DangerSourceActions, RiskTypeActions, RiskTypeApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { AppState } from '../reducers';
import { RiskTypeSelectors } from '../selectors';

@Injectable()
export class RiskTypeEffects {
  loadByCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskTypeActions.loadByRiskCategory),
      mergeMap(({ riskCategoryId, loadTree }) =>
        this._srv.getAllByRiskCategory(riskCategoryId).pipe(
          mergeMap(riskTypes => [
            RiskTypeApiActions.loadSuccess({ riskTypes }),
            ...(loadTree ? riskTypes.map(({ id }) => DangerSourceActions.loadForRiskType({ riskTypeId: id })) : []),
          ]),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(RiskTypeApiActions.loadFailure(), 'Erreur lors de la récupération des types de risque'))
          )
        )
      )
    )
  );

  loadStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskTypeActions.loadStatuses),
      switchMap(() =>
        this._srv.getStatuses().pipe(
          map(statuses => RiskTypeApiActions.loadStatusesSuccess({ statuses })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(RiskTypeApiActions.loadStatusesFailure(), 'Erreur lors de la récupération des statuts de type de risque')
            )
          )
        )
      )
    )
  );

  move$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskTypeActions.move),
      map(({ id, riskCategoryId, position }) => ({
        id,
        values: {
          riskCategoryId,
          position,
        },
      })),
      mergeMap(({ id, values }) =>
        this._srv.update(id, values).pipe(
          map(riskType => RiskTypeApiActions.moveSuccess({ riskType })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(RiskTypeApiActions.moveFailure(), 'Erreur lors du déplacement du type de risque'))
          )
        )
      )
    )
  );

  select$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskTypeActions.select),
      withLatestFrom(this._store.pipe(select(RiskTypeSelectors.getSelected))),
      switchMap(([{ id }, riskType]) =>
        riskType === undefined
          ? this._srv.select(id).pipe(
              map(riskTypeApi => RiskTypeApiActions.selectSuccess({ riskType: riskTypeApi })),
              catchError(() =>
                of(true).pipe(EffectHelper.failure(RiskTypeApiActions.selectFailure(), 'Erreur lors de la selection du type de risque'))
              )
            )
          : of(RiskTypeApiActions.selectSuccess({ riskType }))
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskTypeActions.update),
      mergeMap(({ id, values }) =>
        this._srv.update(id, values).pipe(
          map(riskType => RiskTypeApiActions.updateSuccess({ riskType })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(RiskTypeApiActions.updateFailure(), 'Erreur lors de la sauvegarde du type de risque'))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: RiskTypeService, private _store: Store<AppState>) {}
}
