<ng-content></ng-content>
<div class="select-input-wrapper">
  <div class="selected-value">{{ selectedLabel }}</div>
  <input type="text" #formField />
  <div class="handle"></div>
  <div class="overlay"></div>
  <div class="available-options">
    <a href="#" *ngIf="allowEmpty" (click)="onSelect(null, $event)" [class]="getOptionClassList(null)">{{ placeholder }}</a>
    <a href="#" *ngFor="let option of options" (click)="onSelect(option, $event)" [class]="getOptionClassList(option)">
      {{ option.label }}
    </a>
  </div>
</div>
