import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { UnitOfWorkDangerSourceActions, UnitOfWorkDangerSourceApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { UnitOfWorkDangerSource } from '../models';

export interface State extends EntityState<UnitOfWorkDangerSource>, LoadingState {
  selected: number;
}

export const adapter: EntityAdapter<UnitOfWorkDangerSource> = createEntityAdapter<UnitOfWorkDangerSource>();

export const initialState: State = {
  ...adapter.getInitialState(LoadingStateHelper.initial()),
  selected: null,
};

const UnitOfWorkDangerSourceReducer = createReducer(
  initialState,
  // Load
  on(UnitOfWorkDangerSourceActions.load, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkDangerSourceActions.loadByDangerSource, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkDangerSourceApiActions.loadSuccess, (state, { unitOfWorkDangerSources }) => {
    return adapter.upsertMany(unitOfWorkDangerSources, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfWorkDangerSourceApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Select
  on(UnitOfWorkDangerSourceActions.select, (state, { id }) => ({
    ...state,
    ...LoadingStateHelper.start(),
    selected: id,
  })),
  // Update
  on(UnitOfWorkDangerSourceActions.update, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkDangerSourceApiActions.updateSuccess, (state, { unitOfWorkDangerSource }) => {
    return adapter.upsertOne(unitOfWorkDangerSource, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfWorkDangerSourceApiActions.updateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Create
  on(UnitOfWorkDangerSourceActions.create, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkDangerSourceApiActions.createSuccess, (state, { unitOfWorkDangerSource }) => {
    return adapter.addOne(unitOfWorkDangerSource, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfWorkDangerSourceApiActions.createFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Remove
  on(UnitOfWorkDangerSourceActions.remove, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkDangerSourceApiActions.removeSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfWorkDangerSourceApiActions.removeFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Create
  on(UnitOfWorkDangerSourceActions.duplicate, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfWorkDangerSourceApiActions.duplicateSuccess, (state, { unitOfWorkDangerSource }) => {
    return adapter.upsertOne(unitOfWorkDangerSource, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfWorkDangerSourceApiActions.duplicateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Clean
  on(UnitOfWorkDangerSourceActions.clean, state => adapter.removeAll(state))
);

export function reducer(state: State | undefined, action: Action) {
  return UnitOfWorkDangerSourceReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
