import { MeasureNotification } from './measure-notification.model';

export const MEASURE_TYPE = {
  PREVENTIVE: 'preventive',
  EFFECTIVE: 'effective',
} as const;

export type MeasureType = typeof MEASURE_TYPE[keyof typeof MEASURE_TYPE];

export interface Measure {
  id: number;
  name: string;
  type: MeasureType;
  periodic: boolean;
  top: boolean;
  topPosition: number;
  position: number;
  enabled: boolean;
  amount: number;
  inCharge: string;
  completionDate: Date;
  predictedDate: Date;
  notification: MeasureNotification;
  resultIndicator: string;
  unitOfWorkDangerSourceId: number;
  refMeasureId: number;
  riskTypeId?: number;
  riskCategoryId?: number;
  dangerSourceId?: number;
  unitOfWorkId?: number;
  unitOfProductionId?: number;
}

export interface MeasureApi extends Omit<Measure, 'unitOfWorkDangerSourceId' | 'refMeasureId'> {
  refMeasure: Pick<RefMeasure, 'id'>;
}

// Ref Measure
export interface RefMeasure {
  id: number;
  preventiveName: string;
  effectiveName: string;
  position: number;
}
