import { UntypedFormControl, ValidationErrors } from '@angular/forms';

interface LuxonDateTime {
  isLuxonDateTime: boolean;
  ts: number;
}

export class DateHelper {
  static formatForDisplay(date: Date | string): string {
    if (!date) {
      return null;
    }

    date = new Date(date);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  static formatToDate(date: string | Date | LuxonDateTime): Date {
    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      return DateHelper.stringToDate(date);
    }

    if (date instanceof Date) {
      return date;
    }

    return new Date(date.ts);
  }

  static stringToDate(date: string): Date {
    if (!date) {
      return null;
    }

    const [day, month, year] = date.split('/');
    return new Date(+year, +month - 1, +day);
  }

  static validator(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    if (!(typeof control.value === 'string')) {
      return null;
    }

    const testDate = DateHelper.stringToDate(control.value);

    if (control.value !== DateHelper.formatForDisplay(testDate)) {
      return { date: true };
    }

    return null;
  }
}
