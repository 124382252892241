import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LibSelectOption } from '../../../interfaces/select';
import { ContractActions } from '../../../store/actions';
import { Contract } from '../../../store/models';
import { AppState } from '../../../store/reducers';
import { ContractSelectors } from '../../../store/selectors';

@Component({
  selector: 'lib-contract-list-modal',
  templateUrl: './list.component.html',
})
export class ContractListComponent implements OnInit {
  contracts$: Observable<Contract[]>;
  form: UntypedFormGroup;
  options: LibSelectOption[];

  constructor(private _dialogRef: MatDialogRef<ContractListComponent>, private _store: Store<AppState>, private _fb: UntypedFormBuilder) {}

  cancel() {
    this._dialogRef.close(null);
  }

  confirm() {
    if (this.form.valid) {
      this._dialogRef.close(this.form.get('contract').value);
    }
  }

  getOptions(contracts: Contract[]) {
    return contracts
      .sort((a, b) => (a.number > b.number ? 1 : -1))
      .map(contract => ({
        label: contract.number,
        value: contract.id,
      }));
  }

  ngOnInit() {
    this._dialogRef.addPanelClass(['overflowable', 'narrow', 'alternative']);

    this.contracts$ = this._store.pipe(
      select(ContractSelectors.getThoseInProgress),
      tap(contracts => (this.options = this.getOptions(contracts)))
    );
    this._store.dispatch(ContractActions.loadInProgress());

    this.form = this._fb.group({
      contract: [10, Validators.required],
    });
  }
}
