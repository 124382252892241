import { Component, HostBinding, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MessengerActions } from '../../store/actions';
import { Message } from '../../store/model';
import { State } from '../../store/reducer';
import { messengerSelectors } from '../../store/selectors';

@Component({
  selector: 'lib-messenger-message-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class MessageListComponent implements OnInit {
  @HostBinding('class') className = 'messenger-list';
  messages$: Observable<Message[]>;

  constructor(private _store: Store<State>) {}

  ngOnInit(): void {
    this.messages$ = this._store.pipe(select(messengerSelectors.getAll));
  }

  read(id: string) {
    this._store.dispatch(MessengerActions.remove({ id }));
  }
}
