<ng-container [formGroup]="form">
  <div class="title-bar">
    <lib-editable-data class="title">
      <span libEditableDataLabel></span>
      <input libEditableDataInput placeholder="Nom de l'unité de production" formControlName="name" />
    </lib-editable-data>
    <lib-dropdown-menu>
      <a [routerLink]="topUrlMask | urlMask: unitOfProduction.id" libDropdownItem class="star" libDropdownItem>
        Voir TOP actions / Papripact
      </a>
      <ng-container *ngIf="!trackingMode">
        <ng-container *ngIf="canBeValidated">
          <a href="#" libDropdownItem (click)="validate($event)" *ngIf="!userIsManager">Valider DUER et soumettre au gestionnaire</a>
          <a href="#" libDropdownItem (click)="validate($event)" *ngIf="userIsManager">Valider DUER</a>
        </ng-container>
        <ng-container *ngIf="!canBeValidated">
          <span libDropdownItem class="disabled">Valider DUER</span>
        </ng-container>
        <a href="#" libDropdownItem (click)="selectProfile($event)">Sélectionner un profil d'activité</a>
        <a href="#" libDropdownItem (click)="duplicate($event)">Dupliquer pour ce contrat</a>
        <a href="#" libDropdownItem (click)="duplicate($event, true)">Dupliquer sur un autre contrat</a>
      </ng-container>
      <ng-container *ngIf="trackingMode">
        <a [routerLink]="planningUrlMask | urlMask: unitOfProduction.id" libDropdownItem>Planification</a>
        <a *ngIf="documentUrlMask" [routerLink]="documentUrlMask | urlMask: unitOfProduction.id" libDropdownItem>Voir le DUER en PDF</a>
        <a [routerLink]="historyUrlMask | urlMask: unitOfProduction.id" libDropdownItem>Historique des actions</a>
      </ng-container>
      <a href="#" *ngIf="!trackingMode" libDropdownItem (click)="delete($event)" class="warning">Supprimer</a>
    </lib-dropdown-menu>
  </div>
  <ng-container *ngIf="!trackingMode">
    <div class="profile" *ngIf="profile$ | async as profile; else noProfile">{{ profile.name }}</div>
    <ng-template #noProfile>
      <div class="profile">Aucun profil selectionné</div>
    </ng-template>
  </ng-container>

  <lib-editable-data class="item-desc" [showLegend]="true">
    <span libEditableDataLabel></span>
    <textarea libEditableDataInput placeholder="Ajouter une description" formControlName="description"></textarea>
  </lib-editable-data>
  <lib-unit-of-work-list [unitOfProductionId]="unitOfProduction.id" [trackingMode]="trackingMode"></lib-unit-of-work-list>
  <a [routerLink]="riskUrlMask | urlMask: unitOfProduction.id" class="button">Accéder à l'évaluation des risques</a>
</ng-container>
