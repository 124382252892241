import { CivilityEnum } from '../../helpers/civility.helper';
import { ModelApi } from '../helpers/model.helper';
import { RefActivity } from './ref-activity.model';

export enum ContactApFunctionEnum {
  DECISION_MAKER = 'decision_maker',
  REFERENT = 'prevention_technical_referent',
}

export interface Contact {
  id: number;
  firstname: string;
  lastname: string;
  civility: CivilityEnum;
  email: string;
  mobileNumber: string;
  apFunction: ContactApFunctionEnum;
}

export interface Company {
  id: number;
  nbMember: number;
  memberCSE: boolean;
  memberCSSCT: boolean;
  address: string;
  addressComplement: string;
  zipcode: string;
  city: string;
  socialReason: string;
  activityComplement: string;
  riskNumber: string;
  companyContributionRateAtMP: number;
  nationalContributionRateAtMP: number;
  companyFrequencyIndex: number;
  nationalFrequencyIndex: number;
  companyGravityRate: number;
  nationalGravityRate: number;
  contactDecisionMaker: Contact;
  contactReferent: Contact;
  refActivityId: number;
}

export interface CompanyApi extends Omit<Company, 'refActivityId'> {
  refActivity: RefActivity;
}

export interface CompanyListApi extends ModelApi<CompanyApi> {}

export interface CompanyShort extends Pick<Company, 'id' | 'socialReason'> {}
