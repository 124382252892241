<ng-container *appDuerTitle>
  <h1>Historique des actions</h1>
</ng-container>

<div class="header">
  <div class="date">Date</div>
  <div class="action">Action</div>
  <div class="object">Élement</div>
  <div class="label">Intitulé</div>
  <div class="cancel"></div>
</div>

<lib-line-item *ngFor="let history of histories$ | async">
  <div class="date">{{ history.createdAt | date: 'dd/MM/yyyy hh:mm' }}</div>
  <div class="action">{{ getActionLabel(history.action) }}</div>
  <div class="object">{{ getObjectLabel(history.objectClass) }}</div>
  <div class="label">{{ history.objectName }}</div>
  <div class="cancel"><a href="#" (click)="cancel($event, history.id)" class="button">Annuler</a></div>
</lib-line-item>
