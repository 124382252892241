import { Component } from '@angular/core';

@Component({
  selector: 'app-top-measure',
  templateUrl: './top-measure.component.html',
})
export class TopMeasureComponent {
  dangerSourceItemUrlMask = [
    '/mon-duer',
    'unite-de-production',
    '%unitOfProductionId',
    'risques',
    '%riskTypeId',
    'sources-de-danger',
    '%id',
  ];
}
