<ng-content></ng-content>
<div class="suffix" *ngIf="suffix">{{ suffix }}</div>

<div class="legend" *ngIf="writeMode && showLegend">
  <p>Aide à la mise en forme:</p>
  <ul>
    <li>
      <span>**Ma phrase** ➡</span>
      <strong>gras</strong>
    </li>
    <li>
      <span>~~Ma phrase~~ ➡</span>
      <i>italique</i>
    </li>
    <li>
      <span>__Ma phrase__ ➡</span>
      <u>souligné</u>
    </li>
    <li>
      <span>Maj + Entrée ➡ Saut de ligne</span>
    </li>
  </ul>
</div>
