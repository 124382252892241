import { AuthenticationService } from '@aequalib';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { RequestStateEnum } from '../../../helpers/request';

@Component({
  selector: 'app-anonymous-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class AnonymousLayoutComponent {
  form: UntypedFormGroup;
  protected _state = RequestStateEnum.AWAIT;

  get isSuccess() {
    return this._state === RequestStateEnum.SUCCESS;
  }
  get isFailure() {
    return this._state === RequestStateEnum.FAILURE;
  }

  constructor(protected _srv: AuthenticationService, protected _fb: UntypedFormBuilder) {}
}
