<app-header></app-header>
<div class="content-wrapper" [ngClass]="pageClass">
  <router-outlet></router-outlet>
</div>
<footer *ngIf="isAuthenticated">
  <div class="content">
    <a routerLink="/comment-lire-mon-duer">Comment lire mon DUER ?</a>
    <a routerLink="/mentions-legales">Mentions légales</a>
  </div>
</footer>
<lib-messenger-message-list></lib-messenger-message-list>
