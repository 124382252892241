import { inject } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { HydratedMeasureActions } from '../store/actions';
import { AppState } from '../store/reducers';
import { MeasureSelectors } from '../store/selectors';

export const hydratedMeasureDeactivationGuard = (): Observable<boolean> => {
  const store = inject(Store<AppState>);
  const result$ = store.pipe(
    select(MeasureSelectors.getTopBySelectedUnitOfProduction),
    filter(measures => measures.length === 0),
    first(),
    map(() => true)
  );

  store.dispatch(HydratedMeasureActions.clean());

  return result$;
};
