<span class="close" (click)="close()"></span>

<div class="title">Alerte mail</div>

<div class="field unit-of-work">
  <div class="label">Unité de travail concernée</div>
  <div class="value" *ngIf="selectedUnitOfWork" [textFormat]="selectedUnitOfWork.name"></div>
</div>

<div class="field name">
  <div class="label">Mesure</div>
  <div class="value" [textFormat]="measure.name"></div>
</div>

<div class="other-fields" [formGroup]="form">
  <div class="field">
    <div class="label">Date</div>
    <lib-editable-data class="date">
      <span libEditableDataLabel></span>
      <input
        libEditableDataInput
        libDateAutoFormatter
        placeholder="jj/mm/aaaa"
        formControlName="startAt"
        [matDatepicker]="picker"
        (focus)="picker.open()"
      />
      <mat-datepicker #picker></mat-datepicker>
    </lib-editable-data>
  </div>
  <div class="field">
    <div class="label">Fréquence</div>
    <lib-select [options]="frequencyOptions" formControlName="frequency"></lib-select>
  </div>
</div>

<div class="action-wrapper">
  <a href="#" (click)="delete($event)" *ngIf="hasNotification">Supprimer l'alerte</a>
  <button class="button strong" (click)="submit()" [disabled]="!form.valid">Valider</button>
</div>
