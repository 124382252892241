import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DropdownMenuComponent } from './components/dropdown-menu.component';
import { DropdownItemDirective } from './directives/dropdown-item.directive';

@NgModule({
  declarations: [DropdownMenuComponent, DropdownItemDirective],
  imports: [CommonModule],
  exports: [DropdownMenuComponent, DropdownItemDirective],
})
export class DropdownModule {}
