import { HydratedMeasure, MEASURE_TYPE, QuotationHelper } from '@aequalib';
import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-planning-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class PlanningItemComponent {
  @Input() hasPeriodicFilter: boolean;
  @Input() hydratedMeasure: HydratedMeasure;
  @Input() index: number;
  @Output() redirectRequest: EventEmitter<null> = new EventEmitter();

  @HostBinding('class')
  get className() {
    const { unitOfWorkDangerSource } = this.hydratedMeasure;
    return unitOfWorkDangerSource ? QuotationHelper.getClassName(unitOfWorkDangerSource.quotation) : '';
  }

  get date(): Date {
    const { measure } = this.hydratedMeasure;

    return this.hasPeriodicFilter && measure.type === MEASURE_TYPE.EFFECTIVE ? measure.completionDate : measure.predictedDate;
  }

  get quotationLabel() {
    const { unitOfWorkDangerSource } = this.hydratedMeasure;
    return unitOfWorkDangerSource ? QuotationHelper.getLabel(unitOfWorkDangerSource.quotation) : '';
  }

  get quotationSummary() {
    const { unitOfWorkDangerSource } = this.hydratedMeasure;
    return unitOfWorkDangerSource ? QuotationHelper.getSummary(unitOfWorkDangerSource) : '';
  }

  get typeLabel(): string {
    const { measure } = this.hydratedMeasure;
    return measure.type === MEASURE_TYPE.EFFECTIVE ? 'Existante' : 'Préconisée';
  }

  @HostListener('click', ['$event'])
  click(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.redirectRequest.emit();
  }
}
