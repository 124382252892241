import { AppState, DateHelper, FloatHelper, Measure } from '@aequalib';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MeasureModalDirective } from '../modal.component';

@Component({
  selector: 'app-measure-info',
  templateUrl: './info.component.html',
  styleUrls: ['../modal.component.scss', './info.component.scss'],
})
export class MeasureInfoComponent extends MeasureModalDirective implements OnInit, OnDestroy {
  constructor(
    dialogRef: MatDialogRef<MeasureInfoComponent>,
    store: Store<AppState>,
    fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data: { measure: Measure }
  ) {
    super(dialogRef, store, fb, data.measure);
  }

  protected _createForm(): void {
    this.form = this._fb.group(
      {
        name: [this.measure.name, Validators.required],
        amount: [FloatHelper.formatForDisplay(this.measure.amount), FloatHelper.validator()],
        inCharge: [this.measure.inCharge, Validators.maxLength(5)],
        completionDate: [this.measure.completionDate, DateHelper.validator],
      },
      { updateOn: 'blur' }
    );
  }
}
