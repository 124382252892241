import { Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { textFormatter } from '../../helpers/text.helper';

@Component({
  selector: 'lib-text-format, [textFormat]',
  template: '<span [innerHTML]="content"></span>',
})
export class TextFormatComponent {
  @Input() text!: string;
  @Input() textFormat!: string;

  get content(): SafeHtml {
    return textFormatter(this.text || this.textFormat);
  }
}
