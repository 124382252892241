import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FloatHelper } from '../../helpers/float.helper';
import { HydratedMeasureApi, HydratedMeasureListApi, HydratedMeasureResponse, Measure, MeasureApi, MeasureType } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class MeasureService {
  constructor(private _http: HttpClient) {}

  create(unitOfWorkDangerSourceId: number, type: MeasureType, name: string, refMeasureId?: number): Observable<Measure> {
    return this._http
      .post<MeasureApi>(`measures`, {
        unitOfWorkDangerSourceId,
        type,
        name,
        ...(refMeasureId && { refMeasureId }),
      })
      .pipe(map(measureApi => this._transformer(measureApi, unitOfWorkDangerSourceId)));
  }

  delete(id: number): Observable<null> {
    return this._http.delete<null>(`measures/${id}`);
  }

  loadAllByUnitOfProduction(unitOfProductionId: number): Observable<HydratedMeasureResponse[]> {
    return this._http
      .get<HydratedMeasureListApi>('planning-measures', {
        params: {
          unitOfProductionId: unitOfProductionId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(({ items }) => items.map(topMeasureApi => this._transformerTop(topMeasureApi, unitOfProductionId))));
  }

  loadPreventiveByUnitOfProduction(unitOfProductionId: number): Observable<HydratedMeasureResponse[]> {
    return this._http
      .get<HydratedMeasureListApi>('preventive-measures', {
        params: {
          unitOfProductionId: unitOfProductionId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(({ items }) => items.map(topMeasureApi => this._transformerTop(topMeasureApi, unitOfProductionId))));
  }

  loadTopByUnitOfProduction(unitOfProductionId: number): Observable<HydratedMeasureResponse[]> {
    return this._http
      .get<HydratedMeasureListApi>('top-measures', {
        params: {
          unitOfProductionId: unitOfProductionId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(({ items }) => items.map(topMeasureApi => this._transformerTop(topMeasureApi, unitOfProductionId))));
  }

  transformer(itemApi: MeasureApi, unitOfWorkDangerSourceId: number): Measure {
    return this._transformer(itemApi, unitOfWorkDangerSourceId);
  }

  update(id: number, values: Partial<Measure>, unitOfWorkDangerSourceId: number): Observable<Measure> {
    const apiValues = {
      ...values,
      ...FloatHelper.formatForApi(values.amount, 'amount'),
    };
    return this._http
      .patch<MeasureApi>(`measures/${id}`, {
        ...apiValues,
      })
      .pipe(map(measureApi => this._transformer(measureApi, unitOfWorkDangerSourceId)));
  }

  private _transformer({ refMeasure, ...itemApi }: MeasureApi, unitOfWorkDangerSourceId: number): Measure {
    return {
      ...itemApi,
      predictedDate: itemApi.predictedDate ? new Date(itemApi.predictedDate) : null,
      completionDate: itemApi.completionDate ? new Date(itemApi.completionDate) : null,
      unitOfWorkDangerSourceId,
      refMeasureId: refMeasure ? refMeasure.id : null,
    };
  }

  private _transformerTop(itemApi: HydratedMeasureApi, unitOfProductionId: number): HydratedMeasureResponse {
    const { unitOfWorkDangerSource, measure, unitOfWork, dangerSource, riskCategory, riskType } = itemApi;
    return {
      unitOfWorkDangerSource,
      unitOfWork,
      dangerSource,
      riskCategory,
      riskType,
      measure: {
        ...this._transformer(measure, unitOfWorkDangerSource.id),
        unitOfProductionId,
        unitOfWorkId: unitOfWork.id,
        dangerSourceId: dangerSource.id,
        riskCategoryId: riskCategory.id,
        riskTypeId: riskType.id,
      },
    };
  }
}
