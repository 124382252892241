import { AppState, RiskType, RiskTypeSelectors, UnitOfProduction, UnitOfProductionSelectors } from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-danger-source-list',
  templateUrl: './list.component.html',
  styleUrls: ['list.component.scss'],
})
export class DangerSourceListComponent implements OnInit {
  riskType$: Observable<RiskType>;
  unitOfProduction$: Observable<UnitOfProduction>;

  constructor(private _store: Store<AppState>) {}

  getDangerSourceItemUrlMask(unitOfProduction: UnitOfProduction, riskType: RiskType) {
    return [
      '/mon-duer',
      'unite-de-production',
      unitOfProduction.id.toString(),
      'risques',
      riskType.id.toString(),
      'sources-de-danger',
      '%id',
    ];
  }

  ngOnInit(): void {
    this.unitOfProduction$ = this._store.pipe(select(UnitOfProductionSelectors.getSelected));
    this.riskType$ = this._store.pipe(select(RiskTypeSelectors.getSelected));
  }
}
