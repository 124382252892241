import { CivilityEnum, CivilityHelper, Company, Contact, FloatHelper, LibSelectOption, RefActivity } from '@aequalib';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

interface CompanyFormValues {
  refActivityId: string;
  nbMember: string;
  memberCSE: boolean;
  memberCSSCT: boolean;
  socialReason: string;
  address: string;
  addressComplement: string;
  zipcode: string;
  city: string;
  riskNumber: string;
  indicators: {
    companyContributionRateAtMP: string;
    nationalContributionRateAtMP: string;
    companyFrequencyIndex: string;
    nationalFrequencyIndex: string;
    companyGravityRate: string;
    nationalGravityRate: string;
  };
}

@Component({
  selector: 'app-company-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class CompanyEditComponent implements OnChanges, OnDestroy {
  @Input() activities: RefActivity[];
  civilityOptions: LibSelectOption[] = [
    {
      label: CivilityHelper.getLabel(CivilityEnum.MALE),
      value: CivilityEnum.MALE,
    },
    {
      label: CivilityHelper.getLabel(CivilityEnum.FEMALE),
      value: CivilityEnum.FEMALE,
    },
  ];
  @Input() company: Company;
  companyform: UntypedFormGroup;
  decisionMakerform: UntypedFormGroup;
  referentForm: UntypedFormGroup;
  @Output() updateCompany: EventEmitter<Partial<Company>> = new EventEmitter();
  @Output() updateDecisionMaker: EventEmitter<Partial<Contact>> = new EventEmitter();
  @Output() updateReferent: EventEmitter<Partial<Contact>> = new EventEmitter();
  private _destroy$: Subject<boolean> = new Subject();

  get activityLabel() {
    const activity = this.activities.find(({ id }) => this.company.refActivityId === id);

    return activity ? activity.name : '';
  }

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.company) {
      const { contactDecisionMaker, contactReferent, ...company } = this.company;
      const { firstChange, currentValue, previousValue } = changes.company;

      if (firstChange) {
        this._createCompanyForm(company);
        this._createDecisionMakerForm(contactDecisionMaker);
        this._createReferentForm(contactReferent);
      } else if (currentValue.id !== previousValue.id) {
        this._updateCompanyForm(company);
        this._updateDecisionMakerForm(contactDecisionMaker);
        this._updateReferentForm(contactReferent);
      }
    }
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  private _createCompanyForm(company: Partial<Company>) {
    this.companyform = this._fb.group(
      {
        nbMember: [null, [Validators.pattern('0|([1-9][0-9]*)')]],
        memberCSE: [null, Validators.required],
        memberCSSCT: [null, Validators.required],
        activityComplement: [null],
        riskNumber: [null],
        indicators: this._fb.group({
          companyContributionRateAtMP: [null, FloatHelper.validator()],
          nationalContributionRateAtMP: [null, FloatHelper.validator()],
          companyFrequencyIndex: [null, FloatHelper.validator()],
          nationalFrequencyIndex: [null, FloatHelper.validator()],
          companyGravityRate: [null, FloatHelper.validator()],
          nationalGravityRate: [null, FloatHelper.validator()],
        }),
      },
      {
        updateOn: 'blur',
      }
    );

    this._updateCompanyForm(company);

    this.companyform.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.companyform.valid),
        tap((values: CompanyFormValues) => this._updateCompany(values))
      )
      .subscribe();
  }

  private _createDecisionMakerForm(contact: Contact) {
    this.decisionMakerform = this._fb.group(
      {
        civility: [null, Validators.required],
        lastname: [null, Validators.required],
      },
      {
        updateOn: 'blur',
      }
    );

    this._updateDecisionMakerForm(contact);

    this.decisionMakerform.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.decisionMakerform.valid),
        tap(values =>
          this.updateDecisionMaker.emit({
            ...values,
            id: contact && contact.id,
          })
        )
      )
      .subscribe();
  }

  private _createReferentForm(contact: Contact) {
    this.referentForm = this._fb.group(
      {
        civility: [null, Validators.required],
        lastname: [null, Validators.required],
        mobileNumber: [null, Validators.pattern('[0-9]{10}')],
        email: [null, [Validators.required, Validators.email]],
      },
      {
        updateOn: 'blur',
      }
    );

    this._updateReferentForm(contact);

    this.referentForm.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap(values => {
          if (!this.referentForm.valid) {
            this.referentForm.markAllAsTouched();
            return;
          }

          this.updateReferent.emit({
            ...values,
            id: contact && contact.id,
          });
        })
      )
      .subscribe();
  }

  private _updateCompany({ nbMember, refActivityId, indicators, ...others }: CompanyFormValues) {
    const indicatorsValue = Object.entries(indicators).reduce(
      (prev, [key, value]) => ({ ...prev, [key]: FloatHelper.stringToNumber(value) }),
      {}
    );

    this.updateCompany.emit({
      ...others,
      nbMember: +nbMember,
      refActivityId: +refActivityId,
      ...indicatorsValue,
    });
  }

  private _updateCompanyForm(company: Partial<Company>) {
    this.companyform.patchValue(
      {
        nbMember: company.nbMember,
        memberCSE: company.memberCSE,
        memberCSSCT: company.memberCSSCT,
        activityComplement: company.activityComplement,
        riskNumber: company.riskNumber,
        indicators: {
          companyContributionRateAtMP: FloatHelper.formatForDisplay(company.companyContributionRateAtMP),
          nationalContributionRateAtMP: FloatHelper.formatForDisplay(company.nationalContributionRateAtMP),
          companyFrequencyIndex: FloatHelper.formatForDisplay(company.companyFrequencyIndex),
          nationalFrequencyIndex: FloatHelper.formatForDisplay(company.nationalFrequencyIndex),
          companyGravityRate: FloatHelper.formatForDisplay(company.companyGravityRate),
          nationalGravityRate: FloatHelper.formatForDisplay(company.nationalGravityRate),
        },
      },
      {
        emitEvent: false,
      }
    );
  }

  private _updateDecisionMakerForm(contact: Contact) {
    this.decisionMakerform.patchValue(
      {
        civility: contact && contact.civility,
        lastname: contact && contact.lastname,
      },
      {
        emitEvent: false,
      }
    );
  }

  private _updateReferentForm(contact: Contact) {
    this.referentForm.patchValue(
      {
        civility: contact && contact.civility,
        lastname: contact && contact.lastname,
        mobileNumber: contact && contact.mobileNumber,
        email: contact && contact.email,
      },
      {
        emitEvent: false,
      }
    );
  }
}
