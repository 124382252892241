<ng-container *ngIf="unitOfProduction$ | async as unitOfProduction">
  <lib-risk-category-list
    [dangerSourceListUrlMask]="getDangerSourceListUrlMask(unitOfProduction)"
    [riskDetailUrlMask]="getRiskDetailUrlMask()"
    [trackingMode]="true"
  ></lib-risk-category-list>

  <ng-container *appDuerTitle>
    <h1 [textFormat]="unitOfProduction.name"></h1>
    <h2 *ngIf="company$ | async as company">[Entreprise] {{ company.socialReason }}</h2>
  </ng-container>
</ng-container>
