import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DangerSource } from '../../../store/models';

@Component({
  selector: 'lib-danger-source-comment',
  templateUrl: './comment.component.html',
})
export class DangerSourceCommentComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dangerSource: DangerSource },
    private _dialogRef: MatDialogRef<DangerSourceCommentComponent>,
    private _fb: UntypedFormBuilder
  ) {}

  close() {
    this._dialogRef.close();
  }

  ngOnInit() {
    this.form = this._fb.group({
      comment: [this.data.dangerSource.comment],
    });
  }

  valid() {
    this._dialogRef.close({
      comment: this.form.get('comment').value,
    });
  }
}
