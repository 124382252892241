import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DangerSourceImageActions, RiskCategoryActions } from '../../../store/actions';
import { AppState } from '../../../store/reducers';
import { DangerSourceImageSelectors } from '../../../store/selectors';

@Component({
  selector: 'lib-picture-gallery',
  templateUrl: './picture-gallery.component.html',
  styleUrls: ['./picture-gallery.component.scss'],
})
export class UnitOfProductionPictureGalleryComponent implements OnInit {
  categories$: Observable<any>;
  createForm: number | null = null;
  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.categories$ = this._store.pipe(
      select(DangerSourceImageSelectors.getAllForSelectedUnitOfProdution),
      map(categories =>
        categories.map(category => {
          let shouldExpandCategory = false;

          const updatedTypes = category.types.map(type => {
            let shouldExpandType = false;

            const updatedDangerSources = type.dangerSources.map(dangerSource => {
              if (dangerSource.images && dangerSource.images.length > 0) {
                shouldExpandType = true;
              }
              return dangerSource;
            });

            if (shouldExpandType) {
              shouldExpandCategory = true;
            }

            return {
              ...type,
              isExpanded: shouldExpandType,
              dangerSources: updatedDangerSources,
            };
          });

          return {
            ...category,
            isExpanded: shouldExpandCategory,
            types: updatedTypes,
          };
        })
      )
    );

    this._store.dispatch(RiskCategoryActions.load({ loadTree: true }));
    this._store.dispatch(DangerSourceImageActions.loadForSelectedUnitOfProduction());
  }

  onCreateFormClick(e: MouseEvent, dangerSourceId: number) {
    e.preventDefault();

    this.createForm = dangerSourceId;
  }

  toggleCategory(e: MouseEvent, category: any) {
    e.preventDefault();

    category.isExpanded = !category.isExpanded;
  }

  toggleType(e: MouseEvent, type: any) {
    e.preventDefault();

    type.isExpanded = !type.isExpanded;
  }
}
