export enum CivilityEnum {
  MALE = 'male',
  FEMALE = 'female',
}

export class CivilityHelper {
  static getLabel(civility: CivilityEnum) {
    return civility === CivilityEnum.MALE ? 'Mr' : 'Mme';
  }
}
