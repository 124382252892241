import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { UnitOfProduction } from '../models';
import { getUnitOfProductionState } from '../reducers';
import { State } from '../reducers/unit-of-production.reducer';
import * as ContractSelectors from './contract.selectors';

const getEntitiesFromState = createSelector(getUnitOfProductionState, (state: State): Dictionary<UnitOfProduction> => state.entities);
const getSelectedFromState = createSelector(getUnitOfProductionState, (state: State) => state.selected);

export const getAll = createSelector(getEntitiesFromState, entities => Object.keys(entities).map(id => entities[id]));

export const getAllByCurrentContract = createSelector(getAll, ContractSelectors.getSelected, (entities, { id }) =>
  entities.filter(entity => entity.contractId === id)
);

export const getSelected = createSelector(getAll, getSelectedFromState, (entities, id) => entities.find(entity => entity.id === id));
