<span class="close" (click)="close()"></span>

<p class="title">Souhaitez-vous renseigner ou modifier la date de mise à jour ?</p>
<div class="content" [formGroup]="form">
  <div class="date-wrapper">
    <div class="field">
      <label>Date de mise à jour</label>
      <input
        libDateAutoFormatter
        type="text"
        formControlName="date"
        placeholder="JJ/MM/AAAA"
        [matDatepicker]="pickerFrom"
        (click)="pickerFrom.open()"
      />
      <mat-datepicker #pickerFrom></mat-datepicker>
    </div>

    <div class="field">
      <label>Utiliser la date ci-dessus dans le DUER ?</label>
      <lib-radio-group formControlName="display">
        <lib-radio-input [value]="true"><label>Oui</label></lib-radio-input>
        <lib-radio-input [value]="false"><label>Non</label></lib-radio-input>
      </lib-radio-group>
    </div>
  </div>
  <div class="signature-wrapper">
    <div class="field">
      <label>Signature</label>
      <lib-signature formControlName="signature"></lib-signature>
    </div>
  </div>
</div>

<div class="action-wrapper">
  <button (click)="submit()" class="button" [disabled]="!form.valid">Valider</button>
</div>
