import { v4 as uuidV4 } from 'uuid';

import { Message, MessageData, MessageTypeEnum } from '../store/model';

export class MessengerHelper {
  static create = (data: MessageData, type: MessageTypeEnum): Message => ({
    ...data,
    type,
    id: uuidV4(),
  });

  static createError = (data: MessageData): Message => MessengerHelper.create(data, MessageTypeEnum.ERROR);
  static createInfo = (data: MessageData): Message => MessengerHelper.create(data, MessageTypeEnum.INFO);
  static createSuccess = (data: MessageData): Message => MessengerHelper.create(data, MessageTypeEnum.SUCCESS);
  static createWarning = (data: MessageData): Message => MessengerHelper.create(data, MessageTypeEnum.WARNING);
}
