import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PageService } from '../../services/models/page.service';
import { PageActions, PageApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';

@Injectable()
export class PageEffects {
  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PageActions.load),
      switchMap(() => this._srv.getList()),
      map(pages => PageApiActions.loadSuccess({ pages })),
      catchError(() => of(true).pipe(EffectHelper.failure(PageApiActions.loadFailure(), 'Erreur lors de la récupération des pages')))
    );
  });

  constructor(private actions$: Actions, private _srv: PageService) {}
}
