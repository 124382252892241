import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { UnitOfWorkDangerSource } from '../../../store/models';

interface QuotationTypeLabels {
  label: string;
  compactLabel?: string;
  values: string[];
}

interface QuotationLabels {
  frequency: QuotationTypeLabels;
  severity: QuotationTypeLabels;
  proficiency: QuotationTypeLabels;
}

const labels: QuotationLabels = {
  frequency: {
    label: 'Fréquence',
    values: ['Faible', 'Occasionnelle', 'Moyenne', 'Forte'],
  },
  severity: {
    label: 'Gravité',
    values: ['Lésions bénignes', 'Lésions réversibles et significatives', 'Lésions irréversibles ou graves', 'Mortelle'],
  },
  proficiency: {
    label: 'Maîtrise du risque',
    compactLabel: 'Maîtrise',
    values: [
      'Pas ou peu conscience du risque',
      'Faible maîtrise du risque',
      'Bonne maîtrise du risque',
      'Toutes les actions sont mises en place',
    ],
  },
};

@Component({
  selector: 'lib-unit-of-work-quotation',
  templateUrl: './unit-of-work-quotation.component.html',
  styleUrls: ['./unit-of-work-quotation.component.scss'],
})
export class UnitOfWorkQuotationComponent {
  @Input() unitOfWorkDangerSource: UnitOfWorkDangerSource;
  @Output() update: EventEmitter<Partial<UnitOfWorkDangerSource>> = new EventEmitter();
  private _compact = false;

  @HostBinding()
  get className() {
    const classList = ['lib-unit-of-work-quotation'];

    if (this.compact) {
      classList.push('compact');
    } else {
      classList.push('expanded');
    }

    return classList.join(' ');
  }

  @Input()
  set compact(val: any) {
    this._compact = coerceBooleanProperty(val);
  }
  get compact(): boolean {
    return this._compact;
  }

  change(type: string, value: number) {
    this.update.emit({
      [type]: value,
    });
  }

  getTypeLabel(type) {
    return this.compact && labels[type].compactLabel ? labels[type].compactLabel : labels[type].label;
  }

  getValueClass(type: string, value: number) {
    const classList = ['quotation-value'];
    if (this.unitOfWorkDangerSource[type] === value) {
      classList.push('selected');
    }

    return classList.join(' ');
  }

  getValueLabel(type, value) {
    return labels[type].values[value - 1];
  }
}
