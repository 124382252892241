<div class="risk-category" [ngClass]="category.entity.colorClass" *ngFor="let category of categories$ | async">
  <a href="#" class="category-title" (click)="toggleCategory($event, category)">
    {{ category.entity.name }}
  </a>
  <div *ngIf="category.isExpanded">
    <div class="risk-type" *ngFor="let type of category.types">
      <a href="#" class="type-title" (click)="toggleType($event, type)">
        {{ type.entity.name }}
      </a>
      <div *ngIf="type.isExpanded">
        <div class="danger-source" *ngFor="let dangerSource of type.dangerSources">
          <div class="danger-source-title">
            <span>{{ dangerSource.entity.name }}</span>
            <lib-dropdown-menu>
              <a href="#" libDropdownItem (click)="onCreateFormClick($event, dangerSource.entity.id)">Ajouter</a>
            </lib-dropdown-menu>
          </div>
          <lib-picture-list
            class="inline"
            [images]="dangerSource.images"
            [dangerSourceId]="dangerSource.entity.id"
            [showCreate]="createForm === dangerSource.entity.id"
            (createEnd)="createForm = null"
          ></lib-picture-list>
        </div>
      </div>
    </div>
  </div>
</div>
