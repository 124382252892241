<lib-form-image [ngModel]="image.image" disabled></lib-form-image>
<ng-container [formGroup]="form">
  <div class="other-controls">
    <lib-editable-data>
      <label libEditableDataLabel></label>
      <input libEditableDataInput type="text" placeholder="Légende photo" formControlName="name" />
    </lib-editable-data>
    <lib-radio-group formControlName="status">
      <lib-radio-input [value]="statuses.CONTINUE" class="success"><label>À maintenir</label></lib-radio-input>
      <lib-radio-input [value]="statuses.EDIT" class="warning"><label>À modifier</label></lib-radio-input>
    </lib-radio-group>
  </div>
  <lib-dropdown-menu position="after">
    <a href="#" libDropdownItem (click)="delete($event)" class="warning">Supprimer</a>
  </lib-dropdown-menu>
</ng-container>
