import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[libDragItem]',
})
export class DragItemDirective implements OnInit {
  @Input() dragData: { [key: string]: any };
  isDragged = false;
  private _cloneWrapper: HTMLElement;

  get domEl(): HTMLElement {
    return this._el.nativeElement as HTMLElement;
  }

  set position(value: number) {
    this.domEl.style.order = value.toString();
  }

  get position(): number {
    return +(this.domEl.style.order || 0);
  }

  constructor(private _el: ElementRef) {}

  contains(pos): boolean {
    const ref = this.domEl.style.display === 'none' ? this._cloneWrapper : this.domEl;
    let current = ref;
    let top = 0;
    while (current !== document.body) {
      top += current.offsetTop;
      current = current.offsetParent as HTMLElement;
    }
    top -= window.scrollY;
    const middle = top + ref.offsetHeight / 2;

    return middle <= pos;
  }

  ngOnInit() {
    if (this.dragData) {
      this.domEl.setAttribute('draggable', 'true');
      this.domEl.classList.add('drag-item');

      this._createCloneWrapper();
      this.domEl.addEventListener('dragstart', e => this._dragStart(e));
      this.domEl.addEventListener('dragend', () => this._dragEnd());
      // InputElements react weirdly into a draggable element so disable it when input is focused
      this.domEl.addEventListener('editable:focus', () => this.domEl.removeAttribute('draggable'));
      this.domEl.addEventListener('editable:blur', () => this.domEl.setAttribute('draggable', 'true'));
    } else {
      this.domEl.classList.add('ghost-item');
    }
  }

  private _createCloneWrapper() {
    this._cloneWrapper = document.createElement('div');
    this._cloneWrapper.classList.add('drag-element-wrapper');
    this._cloneWrapper.style.height = '0';
    this._cloneWrapper.style.overflow = 'hidden';
  }

  private _dragEnd() {
    this._cloneWrapper.innerHTML = '';
    this._cloneWrapper.remove();

    this.domEl.style.display = '';
    this.isDragged = false;
  }

  private _dragStart(e: DragEvent) {
    if (this.domEl.querySelector('*:focus')) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const clone = this.domEl.cloneNode(true) as HTMLElement;

    this._cloneWrapper.appendChild(clone);
    this._cloneWrapper.style.order = this.domEl.style.order;
    this.domEl.parentElement.insertBefore(this._cloneWrapper, this.domEl);

    e.dataTransfer.setDragImage(clone, 0, 0);
    setTimeout(() => {
      this.domEl.style.display = 'none';
    }, 10);

    Object.keys(this.dragData).forEach(key => {
      e.dataTransfer.setData(key, this.dragData[key].toString());
    });
    this.isDragged = true;

    e.dataTransfer.setData('startPosition', this.position.toString());
    e.dataTransfer.setData('originalHeight', this.domEl.offsetHeight.toString());
  }
}
