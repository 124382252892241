import { AppState, UnitOfProduction, UnitOfProductionSelectors } from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-unit-of-production-item',
  templateUrl: './item.component.html',
})
export class UnitOfProductionItemComponent implements OnInit {
  unitOfProduction$: Observable<UnitOfProduction>;
  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.unitOfProduction$ = this._store.pipe(select(UnitOfProductionSelectors.getSelected));
  }
}
