<lib-line-item [formGroup]="form" [disabled]="!measure.enabled">
  <lib-editable-data class="name">
    <span libEditableDataLabel></span>
    <textarea libEditableDataInput placeholder="Intitulé de la mesure" formControlName="name"></textarea>
  </lib-editable-data>
  <div class="indicators">
    <span class="periodic" *ngIf="measure.periodic"></span>
  </div>

  <ng-container actions>
    <lib-dropdown-menu>
      <a href="#" libDropdownItem (click)="changePeriodic($event)">
        {{ measure.periodic ? 'Définir comme mesure non périodique' : 'Définir comme mesure périodique' }}
      </a>
      <a href="#" libDropdownItem (click)="showInfo($event)">Informations complémentaires</a>
      <a href="#" libDropdownItem (click)="delete($event)" class="warning">Supprimer</a>
    </lib-dropdown-menu>
    <a href="#" (click)="showInfo($event)" class="more"></a>
  </ng-container>
</lib-line-item>
