<ng-container *ngIf="unitOfProduction$ | async as unitOfProduction">
  <router-outlet></router-outlet>
</ng-container>

<ng-container *appDuerAction>
  <ng-container *ngIf="unitOfProduction$ | async as unitOfProduction">
    <span class="label" [textFormat]="unitOfProduction.name"></span>
    <lib-dropdown-menu position="after">
      <a [routerLink]="['/mon-duer/unite-de-production', unitOfProduction.id, 'top']" class="star" libDropdownItem>
        Voir TOP actions / Papripact
      </a>
      <a [routerLink]="['/mon-duer/unite-de-production', unitOfProduction.id, 'planification']" libDropdownItem>Planification</a>
      <a *ngIf="unitOfProduction.riskAssessmentExportUrl" [libRiskAssessmentExport]="unitOfProduction" libDropdownItem>
        Voir le DUER en PDF
      </a>
      <a [routerLink]="['/mon-duer/unite-de-production', unitOfProduction.id, 'galerie-photo']" libDropdownItem>Photos par risques</a>
      <a [routerLink]="['/mon-duer/unite-de-production', unitOfProduction.id, 'historique']" libDropdownItem>Historique des actions</a>
    </lib-dropdown-menu>
  </ng-container>
</ng-container>
