import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { RefActivityService } from '../../services/models/ref-activity.service';
import { RefActivityActions, RefActivityApiActions, RefProfileActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';

@Injectable()
export class RefActivityEffects {
  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefActivityActions.load),
      switchMap(() =>
        this._srv.getAll().pipe(
          mergeMap(data => {
            const refActivities = data.map(entry => entry.refActivity);
            const refProfiles = data.map(entry => entry.refProfile);
            return [RefActivityApiActions.loadSuccess({ refActivities }), RefProfileActions.load({ refProfiles })];
          }),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(RefActivityApiActions.loadFailure(), 'Erreur lors de la récupération des activités de référence')
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: RefActivityService) {}
}
