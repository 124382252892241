import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { RiskType } from '../models';
import { getRiskTypeState } from '../reducers';
import { State } from '../reducers/risk-type.reducer';

const getEntitiesFromState = createSelector(getRiskTypeState, (state: State): Dictionary<RiskType> => state.entities);
const getSelectedFromState = createSelector(getRiskTypeState, (state: State) => state.selected);

export const getAll = createSelector(getEntitiesFromState, entities =>
  Object.keys(entities)
    .map(id => entities[id])
    .sort((a, b) => (a.position < b.position ? -1 : 1))
);

export const getAllByCategory = (id: number) => createSelector(getAll, entities => entities.filter(entity => entity.riskCategoryId === id));

export const getPossibleStatuses = createSelector(getRiskTypeState, (state: State) => state.possibleStatuses);

export const getSelected = createSelector(getAll, getSelectedFromState, (entities, id) => entities.find(entity => entity.id === id));

export const getRefDangerSourcesBySelected = createSelector(getSelected, entity =>
  [...entity.refDangerSources].sort((a, b) => (a.position < b.position ? -1 : 1))
);
