import { DateHelper, FloatHelper, MeasureNotification } from '@aequalib';
import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter, first, tap } from 'rxjs/operators';
import { MeasureAlertComponent } from 'src/app/containers/measure/modal/alert/alert.component';
import { MeasureItemDirective } from '../item.component';

@Component({
  selector: 'app-measure-item-preventive',
  templateUrl: './preventive.component.html',
  styleUrls: ['../item.component.scss', './preventive.component.scss'],
})
export class MeasureItemPreventiveComponent extends MeasureItemDirective {
  @Output() notificationDeleteRequest: EventEmitter<null> = new EventEmitter();
  @Output() notificationRequest: EventEmitter<Partial<MeasureNotification>> = new EventEmitter();

  get amountFilled(): boolean {
    const { value } = this.form.get('amount');

    return value || value === '0';
  }

  get hasNotification() {
    if (!this.measure.notification) {
      return false;
    }

    return !this.measure.notification.endAt;
  }

  get notificationLabel(): string {
    return this.hasNotification ? `Modifier l'alerte` : 'Créer une alerte';
  }

  constructor(private _dialog: MatDialog, fb: UntypedFormBuilder) {
    super(fb);
  }

  alert(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    const ref = this._dialog.open(MeasureAlertComponent, {
      data: {
        measure: this.measure,
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(values => values),
        tap(values => {
          if (values.delete) {
            this.notificationDeleteRequest.emit();
          } else {
            this.notificationRequest.emit({
              ...values,
              startAt: DateHelper.formatToDate(values.startAt),
            });
          }
        })
      )
      .subscribe();
  }

  changeTop(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.update.emit({
      top: !this.measure.top,
    });
  }

  protected _createForm() {
    this.form = this._fb.group(
      {
        name: [this.measure.name, Validators.required],
        amount: [FloatHelper.formatForDisplay(this.measure.amount), FloatHelper.validator()],
        inCharge: [this.measure.inCharge, Validators.maxLength(5)],
        predictedDate: [this.measure.predictedDate, DateHelper.validator],
      },
      { updateOn: 'blur' }
    );
  }

  protected _submitForm(values) {
    const { amount, predictedDate, ...others } = values;
    this.update.emit({
      ...others,
      amount: FloatHelper.stringToNumber(amount),
      predictedDate: DateHelper.formatToDate(predictedDate),
    });
  }
}
