import { inject } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

import { AppState } from '../store/reducers';
import { ContractSelectors } from '../store/selectors';

export const contractResolver = (): Observable<boolean> => {
  const store = inject(Store<AppState>);
  const loaded$ = store.pipe(
    select(ContractSelectors.getSelected),
    filter(contract => contract !== undefined),
    first(),
    switchMap(() => of(true))
  );

  return loaded$;
};
