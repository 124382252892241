import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { UnitOfWorkDangerSource } from '../models';
import { getUnitOfWorkDangerSourceState } from '../reducers';
import { State } from '../reducers/unit-of-work-danger-source.reducer';
import * as DangerSourceSelectors from './danger-source.selectors';

const getEntitiesFromState = createSelector(
  getUnitOfWorkDangerSourceState,
  (state: State): Dictionary<UnitOfWorkDangerSource> => state.entities
);
const getSelectedFromState = createSelector(getUnitOfWorkDangerSourceState, (state: State) => state.selected);

export const getAll = createSelector(getEntitiesFromState, entities => Object.keys(entities).map(id => entities[id]));

export const getAllByDangerSource = (dangerSourceId: number) =>
  createSelector(getAll, entities => entities.filter(entity => entity.dangerSourceId === dangerSourceId));

export const getAllBySelectedDangerSource = createSelector(getAll, DangerSourceSelectors.getSelected, (entities, dangerSource) =>
  entities.filter(entity => entity.dangerSourceId === dangerSource.id)
);

export const getSelected = createSelector(getAll, getSelectedFromState, (entities, id) => entities.find(entity => entity.id === id));
