import { AppState, Contract, ContractSelectors, Product, ProductSelectors } from '@aequalib';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['../default/default.component.scss', './product.component.scss'],
})
export class ProductComponent implements OnInit, OnDestroy {
  contractsWithDocument$: Observable<Contract[]>;
  product$: Observable<Product>;
  private _contractsHasDocument = false;
  private _destroy$: Subject<boolean> = new Subject();
  private _projectHasDocument = false;

  get hasDocument(): boolean {
    return this._projectHasDocument || this._contractsHasDocument;
  }

  constructor(private _route: ActivatedRoute, private _store: Store<AppState>) {}

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this._route.params
      .pipe(
        takeUntil(this._destroy$),
        map(({ productId }) => +productId),
        tap(productId => {
          this.product$ = this._store.pipe(
            select(ProductSelectors.getById(productId)),
            filter(product => product !== undefined),
            tap(({ document }) => (this._projectHasDocument = !!document))
          );
          this.contractsWithDocument$ = this._store.pipe(
            select(ContractSelectors.getAll),
            map(contracts => contracts.filter(contract => contract.productId === productId)),
            map(contracts => contracts.filter(({ document }) => document)),
            tap(contracts => (this._contractsHasDocument = contracts.length > 0))
          );
        })
      )
      .subscribe();
  }
}
