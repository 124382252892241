import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { RefActivity } from '../models';
import { getRefActivityState } from '../reducers';
import { State } from '../reducers/ref-activity.reducer';

const getEntitiesFromState = createSelector(getRefActivityState, (state: State): Dictionary<RefActivity> => state.entities);

export const getAll = createSelector(getEntitiesFromState, entities => Object.keys(entities).map(id => entities[id]));
