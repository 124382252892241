import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { UnitOfWork } from '../models';
import { getUnitOfWorkState } from '../reducers';
import { State } from '../reducers/unit-of-work.reducer';
import * as UnitOfProductionSelectors from './unit-of-production.selectors';
import * as UnitOfWorkDangerSourceSelectors from './unit-of-work-danger-source.selectors';

const getEntitiesFromState = createSelector(getUnitOfWorkState, (state: State): Dictionary<UnitOfWork> => state.entities);

export const getAll = createSelector(getEntitiesFromState, entities =>
  Object.keys(entities)
    .map(id => entities[id])
    .sort((a, b) => (a.position < b.position ? -1 : 1))
);

export const getAllByUnitOfProduction = (id: number) =>
  createSelector(getAll, entities => entities.filter(entity => entity.unitOfProductionId === id));

export const getAllBySelectedUnitOfProduction = createSelector(
  getAll,
  UnitOfProductionSelectors.getSelected,
  (entities, unitOfProduction) => entities.filter(entity => entity.unitOfProductionId === unitOfProduction.id)
);

export const getAllAvailableBySelectedUnitOfProduction = createSelector(getAllBySelectedUnitOfProduction, entities =>
  entities.filter(({ enabled, archived }) => enabled && !archived)
);

export const getBySelectedUnitOfWorkDangerSource = createSelector(
  getAll,
  UnitOfWorkDangerSourceSelectors.getSelected,
  (entities, unitOfWorkDangerSource) => {
    if (!unitOfWorkDangerSource) {
      return undefined;
    }

    return entities.find(entity => entity.id === unitOfWorkDangerSource.unitOfWorkId);
  }
);

export const getById = (id: number) => createSelector(getAll, entities => entities.find(entity => entity.id === id));
