import * as Sentry from '@sentry/angular-ivy';

export const sentryInit = (dsn: string, release: string, environment: string) => {
  Sentry.init({
    dsn,
    release,
    environment,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^\//],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};
