import { HydratedMeasure, LibSelectOption, MeasureSelectors, RiskCategory, RiskType } from '@aequalib';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TrackingState, trackingStateFeatureKey } from '../reducers';

const selectFeature = createFeatureSelector<TrackingState>(trackingStateFeatureKey);
const selectUi = createSelector(selectFeature, state => state.ui);
export const getPlanningFilters = createSelector(selectUi, state => state.planningFilters);

const riskOptions = (hydratedMeasures: HydratedMeasure[]): LibSelectOption[] => {
  const uniqItems: {
    [key: number]: { riskType: RiskType; riskCategory: RiskCategory };
  } = hydratedMeasures
    .map(({ riskType, riskCategory }) => ({ riskType, riskCategory }))
    .reduce((prev, { riskType, riskCategory }) => ({ ...prev, [riskType.id]: { riskType, riskCategory } }), {});

  return Object.entries(uniqItems)
    .sort(([, aVal], [, bVal]) => (aVal.riskType.name > bVal.riskType.name ? 1 : -1))
    .map(([value, { riskType }]) => ({ value, label: riskType.name }));
};

const unitOfWorkOptions = (hydratedMeasures: HydratedMeasure[]): LibSelectOption[] => {
  const uniqItems: { [key: number]: string } = hydratedMeasures
    .map(({ unitOfWork }) => unitOfWork)
    .reduce((prev, curr) => ({ ...prev, [curr.id]: curr.name }), {});

  return Object.entries(uniqItems).map(([value, label]) => ({ value, label }));
};

const responsibleOptions = (hydratedMeasures: HydratedMeasure[]): LibSelectOption[] => {
  const uniqItems: { [key: number]: string } = hydratedMeasures
    .map(({ measure }) => measure.inCharge)
    .reduce((prev, curr) => ({ ...prev, [curr]: curr }), {});
  return Object.entries(uniqItems).map(([value, label]) => ({ value, label }));
};

export const getPlanningFilterOptions = createSelector(MeasureSelectors.getHydratedBySelectedUnitOfProduction, hydratedMeasures => {
  return {
    risk: riskOptions(hydratedMeasures),
    unitOfWork: unitOfWorkOptions(hydratedMeasures),
    responsible: responsibleOptions(hydratedMeasures),
  };
});
