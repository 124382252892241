import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Contract, ContractStatusEnum } from '../models';
import { getContractState } from '../reducers';
import { State } from '../reducers/contract.reducer';
import * as ProductSelectors from './product.selectors';

const getEntitiesFromState = createSelector(getContractState, (state: State): Dictionary<Contract> => state.entities);
const getSelectedFromState = createSelector(getContractState, (state: State) => state.selected);

export const getAll = createSelector(getEntitiesFromState, entities => Object.keys(entities).map(id => entities[id]));
export const getThoseInProgress = createSelector(getAll, entities =>
  entities.filter(({ status }) => status === ContractStatusEnum.IN_PROGRESS)
);
export const getThoseWithDU = createSelector(getAll, ProductSelectors.getAll, (contracts, products) =>
  contracts.filter(({ productId }) => {
    const product = products.find(({ id }) => id === productId);
    return product && product.generatedRiskAssessmentDocument;
  })
);
export const getThoseWithoutDU = createSelector(getAll, ProductSelectors.getAll, (contracts, products) =>
  contracts.filter(({ productId }) => {
    const product = products.find(({ id }) => id === productId);
    return product && !product.generatedRiskAssessmentDocument;
  })
);
export const getSelectedId = getSelectedFromState;
export const getSelected = createSelector(getAll, getSelectedFromState, (entities, selectedId) =>
  entities.find(({ id }) => id === selectedId)
);
export const getSelectedHasDU = createSelector(getSelected, ProductSelectors.getAll, (contract, products) => {
  const product = products.find(({ id }) => id === contract.productId);
  return product && product.generatedRiskAssessmentDocument;
});
export const getById = id => createSelector(getAll, entities => entities.find(entity => entity.id === id));
