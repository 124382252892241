import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { HistoryActions, HistoryApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { History } from '../models';

export interface State extends EntityState<History>, LoadingState {}

export const adapter: EntityAdapter<History> = createEntityAdapter<History>();

export const initialState: State = adapter.getInitialState({
  ...LoadingStateHelper.initial(),
  selected: null,
});

const HistoryReducer = createReducer(
  initialState,
  // Load
  on(HistoryActions.load, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(HistoryApiActions.loadSuccess, (state, { histories }) => {
    return adapter.upsertMany(histories, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(HistoryApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Cancel
  on(HistoryActions.cancel, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(HistoryApiActions.cancelSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(HistoryApiActions.cancelFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return HistoryReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
