import { createAction, props } from '@ngrx/store';

import { Measure } from '../models';

// Load
export const loadTopFailure = createAction('[HydratedMeasure/Api] Load Top Measures for current Unit of production entity Failure');
export const loadAllFailure = createAction('[HydratedMeasure/Api] Load All Measures for current Unit of production entity Failure');
export const loadPreventiveFailure = createAction(
  '[HydratedMeasure/Api] Load Preventive Measures for current Unit of production entity Failure'
);
// Move
export const moveTopSuccess = createAction('[HydratedMeasure/API] Move Top Measure Success', props<{ measure: Measure }>());
export const moveTopFailure = createAction('[HydratedMeasure/API] Move Top Measure Failure');
