import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { RiskCategory } from '../models';
import { getRiskCategoryState } from '../reducers';
import { State } from '../reducers/risk-category.reducer';
import * as UnitOfProductionSelectors from './unit-of-production.selectors';

const getEntitiesFromState = createSelector(getRiskCategoryState, (state: State): Dictionary<RiskCategory> => state.entities);

export const getAll = createSelector(getEntitiesFromState, entities =>
  Object.keys(entities)
    .map(id => entities[id])
    .sort((a, b) => (a.position < b.position ? -1 : 1))
);

export const getAllBySelectedUnitOfProduction = createSelector(
  getAll,
  UnitOfProductionSelectors.getSelected,
  (categories, unitOfProduction) =>
    unitOfProduction ? categories.filter(category => category.unitOfProductionId === unitOfProduction.id) : undefined
);

export const getByUnitOfProduction = id =>
  createSelector(getAll, categories => categories.filter(category => category.unitOfProductionId === id));
