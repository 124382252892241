import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ProductActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { Product } from '../models';

export interface State extends EntityState<Product>, LoadingState {}

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>();

export const initialState: State = adapter.getInitialState(LoadingStateHelper.initial());

const ProductReducer = createReducer(
  initialState,
  // Load
  on(ProductActions.load, (state, { products }) => {
    return adapter.upsertMany(products, {
      ...state,
    });
  })
);

export function reducer(state: State | undefined, action: Action) {
  return ProductReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
