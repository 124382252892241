import {
  AppState,
  DangerSource,
  DangerSourceSelectors,
  MeasureActions,
  QuotationHelper,
  RiskType,
  RiskTypeSelectors,
  UnitOfProduction,
  UnitOfProductionSelectors,
  UnitOfWork,
  UnitOfWorkActions,
  UnitOfWorkDangerSource,
  UnitOfWorkDangerSourceActions,
  UnitOfWorkDangerSourceSelectors,
  UnitOfWorkSelectors,
} from '@aequalib';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-danger-source-show',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class DangerSourceEditComponent implements OnInit, OnDestroy {
  dangerSource$: Observable<DangerSource>;
  riskType$: Observable<RiskType>;
  selectedUnitOfWork: UnitOfWork;
  selectedUnitOfWorkDangerSource: UnitOfWorkDangerSource;
  unitOfProduction$: Observable<UnitOfProduction>;
  unitOfWorkDangerSources$: Observable<UnitOfWorkDangerSource[]>;
  unitOfWorks$: Observable<UnitOfWork[]>;

  private _destroy$: Subject<boolean> = new Subject();

  get quotationClass(): string {
    if (this.selectedUnitOfWorkDangerSource) {
      return QuotationHelper.getClassName(this.selectedUnitOfWorkDangerSource.quotation);
    }

    return '';
  }

  get quotationLabel(): string {
    if (this.selectedUnitOfWorkDangerSource) {
      return QuotationHelper.getLabel(this.selectedUnitOfWorkDangerSource.quotation);
    }

    return '';
  }

  get editMode() {
    return this.selectedUnitOfWorkDangerSource !== undefined;
  }

  constructor(private _store: Store<AppState>) {}

  create(unitOfWork: UnitOfWork) {
    this._store.dispatch(UnitOfWorkDangerSourceActions.create({ unitOfWorkId: unitOfWork.id }));
  }

  delete(unitOfWorkDangerSource: UnitOfWorkDangerSource) {
    this._store.dispatch(UnitOfWorkDangerSourceActions.remove({ id: unitOfWorkDangerSource.id }));
    if (this.selectedUnitOfWorkDangerSource === unitOfWorkDangerSource) {
      this._store.dispatch(UnitOfWorkDangerSourceActions.select({ id: null }));
    }
  }

  duplicate(unitOfWorkDangerSource: UnitOfWorkDangerSource) {
    if (!this.selectedUnitOfWorkDangerSource) {
      return;
    }

    if (this.selectedUnitOfWorkDangerSource.unitOfWorkId !== unitOfWorkDangerSource.unitOfWorkId) {
      this._store.dispatch(UnitOfWorkDangerSourceActions.duplicate({ unitOfWorkDangerSource }));
    }
  }

  duplicateForDangerSource({ unitOfWorkDangerSource, dangerSourceId, unitOfWorkId }) {
    this._store.dispatch(
      UnitOfWorkDangerSourceActions.duplicateToAnotherDangerSource({
        unitOfWorkDangerSource,
        dangerSourceId,
        unitOfWorkId,
      })
    );
  }

  ngOnDestroy() {
    this._store.dispatch(UnitOfWorkDangerSourceActions.select({ id: null }));
    this._store.dispatch(MeasureActions.highlight({ id: null }));
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this.unitOfProduction$ = this._store.pipe(select(UnitOfProductionSelectors.getSelected));
    this.dangerSource$ = this._store.pipe(select(DangerSourceSelectors.getSelected));
    this.unitOfWorkDangerSources$ = this._store.pipe(select(UnitOfWorkDangerSourceSelectors.getAllBySelectedDangerSource));
    this.unitOfWorks$ = this._store.pipe(select(UnitOfWorkSelectors.getAllAvailableBySelectedUnitOfProduction));
    this.riskType$ = this._store.pipe(select(RiskTypeSelectors.getSelected));
    this._store
      .pipe(
        select(UnitOfWorkDangerSourceSelectors.getSelected),
        takeUntil(this._destroy$),
        tap(unitOfWorkDangerSource => (this.selectedUnitOfWorkDangerSource = unitOfWorkDangerSource))
      )
      .subscribe();
    this._store
      .pipe(
        select(UnitOfWorkSelectors.getBySelectedUnitOfWorkDangerSource),
        takeUntil(this._destroy$),
        tap(unitOfWork => (this.selectedUnitOfWork = unitOfWork))
      )
      .subscribe();

    this._store.dispatch(UnitOfWorkDangerSourceActions.load());
    this._store.dispatch(UnitOfWorkActions.load());
  }

  select(unitOfWorkDangerSource: UnitOfWorkDangerSource) {
    this._store.dispatch(UnitOfWorkDangerSourceActions.select({ id: unitOfWorkDangerSource.id }));
  }

  update({ unitOfWorkDangerSource, values }: { unitOfWorkDangerSource: UnitOfWorkDangerSource; values: Partial<UnitOfWorkDangerSource> }) {
    this._store.dispatch(
      UnitOfWorkDangerSourceActions.update({
        id: unitOfWorkDangerSource.id,
        values,
      })
    );
  }
}
