import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { DangerSource } from '../models';
import { getDangerSourceState } from '../reducers';
import { State } from '../reducers/danger-source.reducer';
import * as RiskTypeSelectors from './risk-type.selectors';

const getEntitiesFromState = createSelector(getDangerSourceState, (state: State): Dictionary<DangerSource> => state.entities);
const getSelectedFromState = createSelector(getDangerSourceState, (state: State) => state.selected);

export const getAll = createSelector(getEntitiesFromState, entities =>
  Object.keys(entities)
    .map(id => entities[id])
    .sort((a, b) => (a.position < b.position ? -1 : 1))
);

export const getAllBySelectedRiskType = createSelector(getAll, RiskTypeSelectors.getSelected, (entities, riskType) =>
  entities.filter(({ riskTypeId }) => riskTypeId === riskType.id)
);

export const getEnabledBySelectedRiskType = createSelector(getAllBySelectedRiskType, entities => entities.filter(entity => entity.enabled));

export const getSelected = createSelector(getAll, getSelectedFromState, (entities, id) => entities.find(entity => entity.id === id));

export const getRefMeasuresBySelected = createSelector(getSelected, entity => entity.refMeasures);
