import { createAction, props } from '@ngrx/store';
import { UnitOfWorkDangerSource } from '../models';

export const load = createAction('[UnitOfWorkDangerSource] Load Links between Unit of work and Selected Danger source');
export const loadByDangerSource = createAction(
  '[UnitOfWorkDangerSource] Load Links between Unit of work and specific Danger source',
  props<{ dangerSourceId: number }>()
);
export const update = createAction(
  '[UnitOfWorkDangerSource] Update Link between Unit of work and Danger source',
  props<{ id: number; values: Partial<UnitOfWorkDangerSource> }>()
);
export const create = createAction(
  '[UnitOfWorkDangerSource] Create Link between Unit of work and Danger source',
  props<{ unitOfWorkId: number }>()
);
export const remove = createAction('[UnitOfWorkDangerSource] Delete Link between Unit of work and Danger source', props<{ id: number }>());
export const duplicate = createAction(
  '[UnitOfWorkDangerSource] Duplicate selected Link between Unit of work and Danger source into another',
  props<{ unitOfWorkDangerSource: UnitOfWorkDangerSource }>()
);
export const duplicateToAnotherDangerSource = createAction(
  '[UnitOfWorkDangerSource] Duplicate selected Link between Unit of work and Danger source into another Danger source',
  props<{ unitOfWorkDangerSource: UnitOfWorkDangerSource; dangerSourceId: number; unitOfWorkId: number }>()
);
export const clean = createAction('[UnitOfWorkDangerSource] Clean store of Link between Unit of work and Danger source');
export const select = createAction(
  '[UnitOfWorkDangerSource] Select a Link between Unit of work and Danger source',
  props<{ id: number }>()
);
