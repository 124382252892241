import { RefRiskType } from '@aequalib';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-ref-risk-type-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class RefRiskTypeItemComponent {
  open = false;
  @Input() refRiskType: RefRiskType;

  @HostBinding('class')
  get className(): string {
    const classList = ['ref-risk-type-item'];
    if (this.open) {
      classList.push('open');
    }

    return classList.join(' ');
  }
}
