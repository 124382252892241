import {
  AppState,
  AuthenticatedSelectors,
  AuthenticatedUser,
  AuthenticationService,
  Company,
  CompanySelectors,
  CompanyShort,
  Contract,
  ContractActions,
  ContractSelectors,
  PageActions,
  PageList,
  PageSelectors,
  Product,
  ProductSelectors,
  UnitOfProduction,
  UnitOfProductionActions,
  UnitOfProductionSelectors,
} from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppConfigService } from '@prestaconcept/config-loader';
import { asapScheduler, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  companies$: Observable<(Company | CompanyShort)[]>;
  contractsWithDU$: Observable<Contract[]>;
  pages$: Observable<PageList[]>;
  productsWithoutDU$: Observable<Product[]>;
  selectedContract$: Observable<Contract>;
  selectedContractHasDU$: Observable<boolean>;
  unitOfProductions$: Observable<UnitOfProduction[]>;
  user$: Observable<AuthenticatedUser>;

  get defaultLogoClassName(): string {
    return location.hostname === this._configSrv.get('ofracarHost') ? 'ofracar-logo' : 'aequalis-logo';
  }

  get isAuthenticated(): boolean {
    return this._authSrv.isAuthenticated;
  }

  constructor(
    private _store: Store<AppState>,
    private _authSrv: AuthenticationService,
    private _router: Router,
    private _configSrv: AppConfigService
  ) {}

  getContractLabel(contract: Contract, companies?: CompanyShort[]) {
    const parts = [contract.number];
    if (companies) {
      const company = companies.find(({ id }) => contract.companyId === id);
      if (company) {
        parts.push(company.socialReason);
      }
    }
    return parts.reverse().join(' - ');
  }

  getNavClass(url: string): string {
    return this._router.url === url ? 'active' : '';
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this._authSrv.logout();
    this._router.navigate(['/login']);
  }

  ngOnInit(): void {
    this.user$ = this._store.pipe(
      select(AuthenticatedSelectors.getUser),
      tap(() => {
        if (this.isAuthenticated) {
          asapScheduler.schedule(() => {
            this._store.dispatch(UnitOfProductionActions.load());
            this._store.dispatch(PageActions.load());
          });
        }
      })
    );
    this.companies$ = this._store.pipe(select(CompanySelectors.getAllWithShorts));
    this.contractsWithDU$ = this._store.pipe(select(ContractSelectors.getThoseWithDU));
    this.pages$ = this._store.pipe(select(PageSelectors.getPagesForList));
    this.productsWithoutDU$ = this._store.pipe(select(ProductSelectors.getThoseWithoutDU));
    this.selectedContract$ = this._store.pipe(select(ContractSelectors.getSelected));
    this.selectedContractHasDU$ = this._store.pipe(select(ContractSelectors.getSelectedHasDU));
    this.unitOfProductions$ = this._store.pipe(select(UnitOfProductionSelectors.getAllByCurrentContract));
  }

  select(contractId: number) {
    this._store.dispatch(ContractActions.select({ contractId }));
  }
}
