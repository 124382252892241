import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { RefUnitOfProductionService } from '../../services/models/ref-unit-of-production.service';
import { RefProfileActions, RefUnitOfProductionActions, RefUnitOfProductionApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';

@Injectable()
export class RefUnitOfProductionEffects {
  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefUnitOfProductionActions.load),
      switchMap(() =>
        this._srv.getAll().pipe(
          mergeMap(data => {
            const refUnitOfProductions = data.map(entry => entry.refUnitOfProduction);
            const refProfiles = data.map(entry => entry.refProfile);
            return [RefUnitOfProductionApiActions.loadSuccess({ refUnitOfProductions }), RefProfileActions.load({ refProfiles })];
          }),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(
                RefUnitOfProductionApiActions.loadFailure(),
                'Erreur lors de la récupération des unités de production de référence'
              )
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: RefUnitOfProductionService) {}
}
