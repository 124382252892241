import { CompanyEffects } from './company.effects';
import { ContactFormEffects } from './contact-form.effects';
import { ContractEffects } from './contract.effects';
import { DangerSourceImageEffects } from './danger-source-image.effects';
import { DangerSourceEffects } from './danger-source.effects';
import { HistoryEffects } from './history.effects';
import { HydratedMeasureEffects } from './hydrated-measure.effects';
import { MeasureNotificationEffects } from './measure-notification.effects';
import { MeasureEffects } from './measure.effects';
import { PageEffects } from './page.effects';
import { RefActivityEffects } from './ref-activity.effects';
import { RefRiskTypeEffects } from './ref-risk-type.effects';
import { RefUnitOfProductionEffects } from './ref-unit-of-production.effects';
import { RiskCategoryEffects } from './risk-category.effects';
import { RiskTypeEffects } from './risk-type.effects';
import { UnitOfProductionEffects } from './unit-of-production.effects';
import { UnitOfWorkDangerSourceEffects } from './unit-of-work-danger-source.effects';
import { UnitOfWorkEffects } from './unit-of-work.effects';

export const EFFECT_SERVICES = [
  UnitOfProductionEffects,
  UnitOfWorkEffects,
  RefActivityEffects,
  RefRiskTypeEffects,
  RefUnitOfProductionEffects,
  CompanyEffects,
  RiskCategoryEffects,
  RiskTypeEffects,
  DangerSourceEffects,
  DangerSourceImageEffects,
  UnitOfWorkDangerSourceEffects,
  MeasureEffects,
  MeasureNotificationEffects,
  HydratedMeasureEffects,
  ContractEffects,
  ContactFormEffects,
  HistoryEffects,
  PageEffects,
];
