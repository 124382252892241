import { AuthenticationService } from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first, tap } from 'rxjs/operators';

import { RequestStateEnum } from '../../../helpers/request';
import { AnonymousLayoutComponent } from '../layout/layout.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent extends AnonymousLayoutComponent implements OnInit {
  private _token: string;

  constructor(srv: AuthenticationService, fb: UntypedFormBuilder, private _route: ActivatedRoute) {
    super(srv, fb);
  }

  get allDirty(): boolean {
    return Object.keys(this.form.controls).every(key => this.form.get(key).dirty);
  }

  ngOnInit(): void {
    this._token = this._route.snapshot.params.token;

    this.form = this._fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirm: ['', Validators.required],
      },
      {
        validators: (form: UntypedFormGroup) => {
          if (form.get('password').value !== form.get('confirm').value) {
            return { same: true };
          }
          return null;
        },
      }
    );
  }

  submit() {
    if (this.form.valid) {
      const { password } = this.form.value;

      this._srv
        .resetPassword(this._token, password)
        .pipe(
          first(),
          tap(result => {
            this._state = result ? RequestStateEnum.SUCCESS : RequestStateEnum.FAILURE;

            this.form.patchValue({
              password: '',
              confirm: '',
            });

            this.form.markAsUntouched();
            this.form.markAsPristine();
          })
        )
        .subscribe();
    }
  }
}
