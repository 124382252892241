import { AppState, AuthenticatedSelectors, CivilityHelper, Contract, ContractSelectors } from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  contract$: Observable<Contract>;
  owner = '';

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this._store
      .pipe(
        select(AuthenticatedSelectors.getUser),
        first(),
        tap(({ config }) => config && config.ownerCompany && this._defineData(config.ownerCompany))
      )
      .subscribe();

    this.contract$ = this._store.pipe(select(ContractSelectors.getSelected));
  }

  private _defineData({ civility, lastname }) {
    this.owner = `${CivilityHelper.getLabel(civility)} ${lastname}`;
  }
}
