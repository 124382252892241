import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DangerSourceImage, DangerSourceImageApi, DangerSourceImageListApi, DangerSourceImageStatusEnum } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class DangerSourceImageService {
  constructor(private _http: HttpClient) {}

  create(
    dangerSourceId: number,
    values: { image: string; name: string; status: DangerSourceImageStatusEnum }
  ): Observable<DangerSourceImage> {
    return this._http
      .post<DangerSourceImageApi>(`danger-source-images`, {
        dangerSourceId,
        ...values,
      })
      .pipe(map(imageApi => this._transformer(imageApi)));
  }

  delete(id: number): Observable<null> {
    return this._http.delete<null>(`danger-source-images/${id}`);
  }

  getAllByDangerSource(dangerSourceId: number): Observable<DangerSourceImage[]> {
    return this._http
      .get<DangerSourceImageListApi>('danger-source-images', {
        params: {
          dangerSourceId: dangerSourceId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(({ items }) => items.map(imageApi => this._transformer(imageApi))));
  }

  getAllByUnitOfProduction(unitOfProductionId: number): Observable<DangerSourceImage[]> {
    return this._http
      .get<DangerSourceImageListApi>('danger-source-images', {
        params: {
          unitOfProductionId: unitOfProductionId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(({ items }) => items.map(imageApi => this._transformer(imageApi))));
  }

  update(id: number, values: Partial<DangerSourceImage>): Observable<DangerSourceImage> {
    return this._http.patch<DangerSourceImageApi>(`danger-source-images/${id}`, values).pipe(map(imageApi => this._transformer(imageApi)));
  }

  private _transformer(itemApi: DangerSourceImageApi): DangerSourceImage {
    const { dangerSource, ...data } = itemApi;

    return {
      ...data,
      dangerSourceId: dangerSource.id,
    };
  }
}
