<div class="main-content">
  <ng-content></ng-content>

  <div class="actions">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>

<div class="additional-content">
  <ng-content select="[additional]"></ng-content>
</div>
