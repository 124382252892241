import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { LibSelectOption } from '../../../interfaces/select';
import { RiskType, RiskTypeStatus } from '../../../store/models';

@Component({
  selector: 'lib-risk-type-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class RiskTypeItemComponent implements OnInit, OnDestroy {
  @Input() dangerSourceListUrlMask: string[];
  form: UntypedFormGroup;
  @Input() possibleStatuses: RiskTypeStatus[];
  @Input() riskDetailUrlMask: string[];
  @Input() riskType: RiskType;
  statusOptions!: LibSelectOption[];
  @Input() trackingMode: boolean;
  @Output() update: EventEmitter<Partial<RiskType>> = new EventEmitter();
  private _destroy$: Subject<boolean> = new Subject();

  get status() {
    const current = this.statusOptions.find(status => status.value === this.riskType.status);
    return current ? current.label : '';
  }

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this.statusOptions = this.possibleStatuses.map(status => ({
      label: status.label,
      value: status.value,
    }));

    this.form = this._fb.group({
      status: [this.riskType.status, Validators.required],
    });

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.form.valid),
        tap(values => this.update.emit(values))
      )
      .subscribe();
  }
}
