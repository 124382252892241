<ng-container *ngIf="userType$ | async as userType">
  <ng-container *ngIf="refUnitOfProductions$ | async as refUnitOfProductions">
    <lib-unit-of-production-item
      *ngFor="let unitOfProduction of unitOfProductions$ | async"
      [unitOfProduction]="unitOfProduction"
      [refUnitOfProductions]="refUnitOfProductions"
      [userType]="userType"
      [riskUrlMask]="riskUrlMask"
      [topUrlMask]="topUrlMask"
      [planningUrlMask]="planningUrlMask"
      [documentUrlMask]="documentUrlMask"
      [historyUrlMask]="historyUrlMask"
      [trackingMode]="trackingMode"
      (update)="update(unitOfProduction, $event)"
      (deleteRequest)="delete(unitOfProduction)"
      (validateRequest)="validate(unitOfProduction, userType)"
      (duplicateRequest)="duplicate(unitOfProduction, $event)"
      (changeReference)="changeReference(unitOfProduction, $event)"
    ></lib-unit-of-production-item>
  </ng-container>
  <div class="unit-of-production-create" [formGroup]="form" *ngIf="!trackingMode">
    <lib-editable-data class="title" [linkedData]="false">
      <span libEditableDataLabel>Ajouter une nouvelle unité de production</span>
      <input libEditableDataInput placeholder="Nom de l'unité de production" formControlName="name" />
    </lib-editable-data>
  </div>
</ng-container>
