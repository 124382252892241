import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { RiskCategoryActions, RiskTypeActions } from '../../../store/actions';
import { RiskCategory } from '../../../store/models';
import { AppState } from '../../../store/reducers';
import { RiskCategorySelectors } from '../../../store/selectors';

@Component({
  selector: 'lib-risk-category-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class RiskCategoryListComponent implements OnInit, OnDestroy {
  categories$: Observable<RiskCategory[]>;
  @Input() dangerSourceListUrlMask: string[];
  @Input() riskDetailUrlMask: string[];
  @Input() trackingMode = false;
  private _destroy$: Subject<boolean> = new Subject();

  constructor(private _store: Store<AppState>, private _router: Router) {}

  ngOnDestroy(): void {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this.categories$ = this._store.pipe(select(RiskCategorySelectors.getAllBySelectedUnitOfProduction));
    this._storeDispatch();

    this._router.events
      .pipe(
        takeUntil(this._destroy$),
        filter((e: Event) => e instanceof NavigationEnd),
        tap(() => this._storeDispatch())
      )
      .subscribe();
  }

  private _storeDispatch(): void {
    this._store.dispatch(RiskCategoryActions.load({ loadTree: false }));
    this._store.dispatch(RiskTypeActions.loadStatuses());
  }
}
