import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { RefRiskType } from '../models';
import { getRefRiskTypeState } from '../reducers';
import { State } from '../reducers/ref-risk-type.reducer';

const getEntitiesFromState = createSelector(getRefRiskTypeState, (state: State): Dictionary<RefRiskType> => state.entities);

export const getAll = createSelector(getEntitiesFromState, entities =>
  Object.keys(entities)
    .map(id => entities[id])
    .sort((a, b) => (a.name > b.name ? 1 : -1))
);
