import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { MessengerHelper } from '../helpers/helper';
import { MessengerActions } from './actions';

@Injectable()
export class MessengerEffects {
  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessengerActions.appendError),
      map(({ message }) => MessengerHelper.createError(message)),
      map(message => MessengerActions.append({ message }))
    )
  );

  constructor(private actions$: Actions) {}
}
