import { Action } from '@ngrx/store';
import { mergeMap } from 'rxjs/operators';
import { MessengerActions } from '../../submodules/messenger/store/actions';

export class EffectHelper {
  static failure(action: Action | Action[], message: string, title: string = null) {
    const actions: Action[] = Array.isArray(action) ? action : [action];

    return mergeMap(() => [
      ...actions,
      MessengerActions.appendError({
        message: { content: message, title },
      }),
    ]);
  }
}
