import { Directive, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { LayoutService } from '../services/layout.service';

@Directive({
  selector: '[appDuerTitle]',
})
export class DuerTitleDirective implements OnInit, OnDestroy {
  constructor(private templateRef: TemplateRef<any>, private _srv: LayoutService) {}

  ngOnDestroy() {
    this._srv.duerTitle = null;
  }

  ngOnInit() {
    this._srv.duerTitle = this.templateRef;
  }
}
