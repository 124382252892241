<ng-container *ngIf="dangerSource$ | async as dangerSource">
  <ng-container *appDuerTitle>
    <ng-container *ngIf="unitOfProduction$ | async as unitOfProduction">
      <ng-container *ngIf="riskType$ | async as riskType">
        <h1>
          <a [routerLink]="['/mon-duer/unite-de-production', unitOfProduction.id, 'risques', riskType.id, 'sources-de-danger']">
            [SD]
            <lib-text-format [text]="dangerSource.name"></lib-text-format>
          </a>
        </h1>
        <h2>
          <a [routerLink]="['/mon-duer/unite-de-production', unitOfProduction.id, 'risques']">
            [Risque]
            <lib-text-format [text]="riskType.name"></lib-text-format>
          </a>
        </h2>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="unitOfWorkDangerSources$ | async as unitOfWorkDangerSources">
    <ng-container *appSidebar="quotationClass">
      <div class="sidebar-title">Unités de travail</div>
      <lib-danger-source-unit-of-work
        *ngFor="let unitOfWork of unitOfWorks$ | async"
        [unitOfWork]="unitOfWork"
        [unitOfWorkDangerSources]="unitOfWorkDangerSources"
        [selected]="selectedUnitOfWorkDangerSource"
        (create)="create(unitOfWork)"
        (update)="update($event)"
        (deleteRequest)="delete($event)"
        (duplicateRequest)="duplicate($event)"
        (duplicateToDangerSourceRequest)="duplicateForDangerSource($event)"
        (selectRequest)="select($event)"
      ></lib-danger-source-unit-of-work>
      <div class="comment" *ngIf="dangerSource.comment" [textFormat]="dangerSource.comment"></div>
    </ng-container>

    <ng-container *ngIf="editMode; else view">
      <div class="measure-types">
        <app-measure-list
          class="measure-effective-list"
          [preventive]="false"
          [unitOfWorkDangerSource]="selectedUnitOfWorkDangerSource"
        ></app-measure-list>
        <app-measure-list
          class="measure-preventive-list"
          [preventive]="true"
          [unitOfWorkDangerSource]="selectedUnitOfWorkDangerSource"
        ></app-measure-list>
      </div>
      <lib-unit-of-work-quotation
        [unitOfWorkDangerSource]="selectedUnitOfWorkDangerSource"
        (update)="update({ unitOfWorkDangerSource: selectedUnitOfWorkDangerSource, values: $event })"
        additional
      ></lib-unit-of-work-quotation>
      <div [class]="quotationClass + ' quotation-result'">
        <span class="quotation-label">{{ quotationLabel }}</span>
        <div class="desc">
          <span class="unit-of-work" [textFormat]="selectedUnitOfWork?.name"></span>
          <span class="danger-source" [textFormat]="dangerSource.name"></span>
        </div>
      </div>
    </ng-container>

    <ng-template #view>
      <lib-message class="danger">
        Pour modifier des mesures, veuillez
        <ng-container *ngIf="unitOfWorkDangerSources.length > 0">sélectionner</ng-container>
        <ng-container *ngIf="unitOfWorkDangerSources.length === 0">COCHER</ng-container>
        une unité de travail.
      </lib-message>
    </ng-template>
  </ng-container>
</ng-container>
