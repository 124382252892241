import { Directive, ElementRef, EventEmitter } from '@angular/core';

@Directive()
export abstract class EditableDataBaseDirective<T extends HTMLElement> {
  event$: EventEmitter<string> = new EventEmitter<string>();
  protected _value: string;
  constructor(protected _el: ElementRef) {}

  protected get _domEl() {
    return this._el.nativeElement as T;
  }

  hide() {
    this._domEl.style.position = 'absolute';
    this._domEl.style.opacity = '0';
    this._domEl.style.overflow = 'hidden';
    this._domEl.style.width = '0';
  }

  show() {
    this._domEl.style.position = '';
    this._domEl.style.opacity = '';
    this._domEl.style.overflow = '';
    this._domEl.style.width = '';
  }

  abstract get value();
  abstract set value(val);
}
