export enum ContactSubjectEnum {
  EDIT_DUER = 'edit_duer',
  PRINT_DUER = 'print_duer',
  ADVISE = 'advise',
  HELP = 'help',
  CALLBACK_DUER = 'callback_duer',
  OTHER = 'other',
}

export interface ContactFormData {
  name: string;
  company: string;
  email: string;
  subject: ContactSubjectEnum;
  message: string;
}
