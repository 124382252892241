import { AppState, RefRiskType, RefRiskTypeActions, RefRiskTypeSelectors } from '@aequalib';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-ref-risk-type-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class RefRiskTypeListComponent implements OnInit, OnDestroy {
  evenRefRiskTypes: RefRiskType[];
  oddRefRiskTypes: RefRiskType[];
  private _destroy$: Subject<boolean> = new Subject();

  constructor(private _store: Store<AppState>) {}

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this._store
      .pipe(
        takeUntil(this._destroy$),
        select(RefRiskTypeSelectors.getAll),
        tap(refRiskTypes => {
          this.oddRefRiskTypes = refRiskTypes.filter((item, idx) => idx % 2 === 0);
          this.evenRefRiskTypes = refRiskTypes.filter((item, idx) => idx % 2 === 1);
        })
      )
      .subscribe();

    this._store.dispatch(RefRiskTypeActions.load());
  }
}
