<h1>Réinitialisation de mot de passe</h1>

<form (submit)="submit()" [formGroup]="form" *ngIf="!isSuccess; else message">
  <lib-message class="danger" *ngIf="isFailure">Une erreur s'est produite durant la mise à jour de votre mot de passe.</lib-message>

  <div class="form-field">
    <label for="password">Mot de passe</label>
    <app-password formControlName="password"></app-password>
    <p class="error" *ngIf="form.get('password').touched && form.get('password').hasError('minlength')">
      Le mot de passe doivent faire au moins 6 caractères
    </p>
  </div>
  <div class="form-field">
    <label for="confirm">Confirmation</label>
    <app-password controlId="confirm" formControlName="confirm" placeholder="Confirmation"></app-password>
    <p class="error" *ngIf="allDirty && form.hasError('same')">Les deux mots de passe doivent être identiques</p>
  </div>

  <div class="actions">
    <button type="submit" class="button" [ngClass]="{ disabled: !form.valid }">Envoyer</button>
    <a routerLink="/login">Retour</a>
  </div>
</form>

<ng-template #message>
  <p>Votre mot de passe à été mis à jour</p>
  <div class="actions">
    <a routerLink="/login" class="button">Se connecter</a>
  </div>
</ng-template>
