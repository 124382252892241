<lib-line-item [formGroup]="form" [disabled]="!unitOfWork.enabled || unitOfWork.archived">
  <lib-checkbox-input formControlName="enabled" *ngIf="!trackingMode"></lib-checkbox-input>
  <lib-editable-data>
    <span libEditableDataLabel></span>
    <input libEditableDataInput placeholder="Nom de l'unité de travail" formControlName="name" />
  </lib-editable-data>
  <div class="workforce">
    <label></label>
    <lib-editable-data>
      <span libEditableDataLabel></span>
      <input libEditableDataInput formControlName="workforce" />
    </lib-editable-data>
  </div>
  <lib-dropdown-menu>
    <a href="#" *ngIf="trackingMode" libDropdownItem (click)="archive($event)" class="warning">{{ archiveLabel }}</a>
    <a href="#" libDropdownItem (click)="delete($event)" class="warning">Supprimer</a>
  </lib-dropdown-menu>
</lib-line-item>
