import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RiskType, RiskTypeApi, RiskTypeListApi, RiskTypeStatus, RiskTypeStatusListApi } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class RiskTypeService {
  constructor(private _http: HttpClient) {}

  getAllByRiskCategory(riskCategoryId: number): Observable<RiskType[]> {
    return this._http
      .get<RiskTypeListApi>('risk-types', {
        params: {
          riskCategoryId: riskCategoryId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(result => result.items.map(item => this._transformer(item))));
  }

  getStatuses(): Observable<RiskTypeStatus[]> {
    return this._http
      .get<RiskTypeStatusListApi>(`risk-types/statuses`, {
        params: {
          paginate: 'false',
        },
      })
      .pipe(map(({ items }) => items));
  }

  select(id: number): Observable<RiskType> {
    return this._http.get<RiskTypeApi>(`risk-types/${id}`).pipe(map(riskTypeApi => this._transformer(riskTypeApi)));
  }

  update(id: number, values: Partial<RiskType>): Observable<RiskType> {
    return this._http.patch<RiskTypeApi>(`risk-types/${id}`, values).pipe(map(riskTypeApi => this._transformer(riskTypeApi)));
  }

  private _transformer(itemApi: RiskTypeApi): RiskType {
    const { riskCategory, countMeasureEffective, countMeasurePreventive, ...data } = itemApi;
    return {
      ...data,
      countMeasureEffective: countMeasureEffective || 0,
      countMeasurePreventive: countMeasurePreventive || 0,
      riskCategoryId: riskCategory.id,
    };
  }
}
