import { createAction, props } from '@ngrx/store';

import { UnitOfProduction } from '../models';

// Load
export const loadSuccess = createAction(
  '[UnitOfProduction/API] Load Units of Production Success',
  props<{ unitOfProductions: UnitOfProduction[] }>()
);
export const loadFailure = createAction('[UnitOfProduction/API] Load Units of Production Failure');
// Update
export const updateSuccess = createAction(
  '[UnitOfProduction/API] Update Unit of Production Success',
  props<{ unitOfProduction: UnitOfProduction }>()
);
export const updateFailure = createAction('[UnitOfProduction/API] Update Unit of Production Failure');
// Create
export const createSuccess = createAction(
  '[UnitOfProduction/API] Create Unit of Production Success',
  props<{ unitOfProduction: UnitOfProduction }>()
);
export const createFailure = createAction('[UnitOfProduction/API] Create Unit of Production Failure');
// Delete
export const removeSuccess = createAction('[UnitOfProduction/API] Delete Unit of Production Success', props<{ id: number }>());
export const removeFailure = createAction('[UnitOfProduction/API] Delete Unit of Production Failure');
// Select
export const selectSuccess = createAction(
  '[UnitOfProduction/API] Select Unit of Production Success',
  props<{ unitOfProduction: UnitOfProduction }>()
);
export const selectFailure = createAction('[UnitOfProduction/API] Select Unit of Production Failure');
// Validate
export const validateSuccess = createAction(
  '[UnitOfProduction/API] Validate Unit of Production Success',
  props<{ unitOfProduction: UnitOfProduction }>()
);
export const validateFailure = createAction('[UnitOfProduction/API] Validate Unit of Production Failure');
// Duplicate
export const duplicateSuccess = createAction(
  '[UnitOfProduction/API] Duplicate Unit of Production Success',
  props<{ unitOfProduction: UnitOfProduction }>()
);
export const duplicateFailure = createAction('[UnitOfProduction/API] Duplicate Unit of Production Failure');
