import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

export const authenticationGuard = (unauthorizedRoute: string) => {
  return ({ queryParams }: ActivatedRouteSnapshot): Observable<UrlTree> | UrlTree | true => {
    const authSrv = inject(AuthenticationService);
    const router = inject(Router);
    const unauthorized = router.createUrlTree([unauthorizedRoute]);

    // if token exist in queryString, use it in priority
    const token = queryParams?.token || null;
    if (token !== null) {
      // first: logout potential existing user
      authSrv.logout();

      return authSrv.loginByToken(token).pipe(
        map(() =>
          router.createUrlTree([router.url], {
            queryParams: {
              ...queryParams,
              token: null,
            },
          })
        ),
        catchError(() => of(unauthorized))
      );
    }

    // check if user is already logged in
    if (authSrv.isAuthenticated === true) {
      return true;
    }

    // child Guard can react to unauthorized user
    return unauthorized;
  };
};
