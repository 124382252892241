import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { UnitOfWorkService } from '../../services/models/unit-of-work.service';
import { UnitOfWorkActions, UnitOfWorkApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { AppState } from '../reducers';
import { UnitOfProductionSelectors } from '../selectors';

@Injectable()
export class UnitOfWorkEffects {
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkActions.create),
      mergeMap(({ unitOfProductionId, name }) =>
        this._srv.create(unitOfProductionId, name).pipe(
          map(unitOfWork => UnitOfWorkApiActions.createSuccess({ unitOfWork })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(UnitOfWorkApiActions.createFailure(), `Erreur lors de la création de l'unité de travail`))
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkActions.remove),
      mergeMap(({ id }) =>
        this._srv.delete(id).pipe(
          map(() => UnitOfWorkApiActions.removeSuccess({ id })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(UnitOfWorkApiActions.removeFailure(), `Erreur lors de la suppression de l'unité de travail`))
          )
        )
      )
    )
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkActions.load),
      withLatestFrom(this._store.pipe(select(UnitOfProductionSelectors.getSelected))),
      switchMap(([, unitOfProduction]) =>
        this._srv.getAllByUnitOfProduction(unitOfProduction.id).pipe(
          map(unitOfWorks => UnitOfWorkApiActions.loadSuccess({ unitOfWorks })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(UnitOfWorkApiActions.loadFailure(), 'Erreur lors de la récupération des unités de travail'))
          )
        )
      )
    )
  );

  loadByUnitOfProduction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkActions.loadByUnitOfProduction),
      mergeMap(({ unitOfProductionId }) =>
        this._srv.getAllByUnitOfProduction(unitOfProductionId).pipe(
          map(unitOfWorks => UnitOfWorkApiActions.loadSuccess({ unitOfWorks })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(UnitOfWorkApiActions.loadFailure(), 'Erreur lors de la récupération des unités de travail'))
          )
        )
      )
    )
  );

  move$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkActions.move),
      map(({ id, unitOfProductionId, position }) => ({
        id,
        values: {
          unitOfProductionId,
          position,
        },
      })),
      mergeMap(({ id, values }) =>
        this._srv.update(id, values).pipe(
          map(unitOfWork => UnitOfWorkApiActions.moveSuccess({ unitOfWork })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(UnitOfWorkApiActions.moveFailure(), `Erreur lors du déplacement de l'unité de travail`))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkActions.update),
      mergeMap(({ id, values }) =>
        this._srv.update(id, values).pipe(
          map(unitOfWork => UnitOfWorkApiActions.updateSuccess({ unitOfWork })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(UnitOfWorkApiActions.updateFailure(), `Erreur lors de la sauvegarde de l'unité de travail`))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: UnitOfWorkService, private _store: Store<AppState>) {}
}
