import { Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DateHelper } from '../../../helpers/date.helper';
import { FloatHelper } from '../../../helpers/float.helper';
import { QuotationHelper } from '../../../helpers/quotation.helper';
import { UrlMaskPipe } from '../../../pipes/url-mask.pipe';
import { HydratedMeasure, Measure } from '../../../store/models';

@Component({
  selector: 'lib-top-measure-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  providers: [UrlMaskPipe],
})
export class HydratedMeasureItemComponent implements OnInit, OnDestroy {
  @Input() dangerSourceItemUrlMask: string[];
  @Output() deleteRequest: EventEmitter<null> = new EventEmitter();
  form: UntypedFormGroup;
  @Input() index: number;
  @Input() isPapripact: boolean = false;
  @Output() redirectRequest: EventEmitter<null> = new EventEmitter();
  @Input() topMeasure: HydratedMeasure;
  @Output() updateRequest: EventEmitter<Partial<Measure>> = new EventEmitter();
  private _destroy$: Subject<boolean> = new Subject();

  @HostBinding('class')
  get className() {
    return QuotationHelper.getClassName(this.topMeasure.unitOfWorkDangerSource.quotation);
  }

  get quotationLabel() {
    return QuotationHelper.getLabel(this.topMeasure.unitOfWorkDangerSource.quotation);
  }

  get quotationSummary() {
    return QuotationHelper.getSummary(this.topMeasure.unitOfWorkDangerSource);
  }

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit() {
    this.form = this._fb.group(
      {
        predictedDate: [this.topMeasure.measure.predictedDate, [Validators.required, DateHelper.validator]],
        amount: [FloatHelper.formatForDisplay(this.topMeasure.measure.amount), FloatHelper.validator()],
        inCharge: [this.topMeasure.measure.inCharge, Validators.maxLength(5)],
        resultIndicator: [this.topMeasure.measure.resultIndicator],
      },
      {
        updateOn: 'blur',
      }
    );

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.form.valid),
        tap(({ predictedDate, amount, ...others }) =>
          this.updateRequest.emit({
            predictedDate: DateHelper.formatToDate(predictedDate),
            amount: FloatHelper.stringToNumber(amount),
            ...others,
          })
        )
      )
      .subscribe();
  }

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.redirectRequest.emit();
  }

  prevent(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
  }

  remove(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.deleteRequest.emit();
  }
}
