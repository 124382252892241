import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type ExportModalData = {
  date: Date | null;
};

@Component({
  selector: 'lib-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
})
export class ExportModalComponent {
  form!: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ExportModalData, private _dialogRef: MatDialogRef<ExportModalComponent>) {
    this._dialogRef.addPanelClass(['overflowable', 'alternative']);
    this.form = new FormGroup({
      date: new FormControl(this.data.date),
      display: new FormControl(true),
      signature: new FormControl(''),
    });
  }

  close() {
    this._dialogRef.close(null);
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    this._dialogRef.close(this.form.value);
  }
}
