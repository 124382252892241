import { ModelApi } from '../helpers/model.helper';
import { DangerSource } from './danger-source.model';
import { Measure, MeasureApi } from './measure.model';
import { UnitOfWork } from './unit-of-work.model';

export enum QuotationEnum {
  LOW = 'low',
  NORMAL = 'normal',
  IMPORTANT = 'important',
  CRITICAL = 'critical',
}

export interface UnitOfWorkDangerSource {
  id: number;
  frequency: number;
  severity: number;
  proficiency: number;
  quotation: QuotationEnum;
  unitOfWorkId: number;
  dangerSourceId: number;
}

export interface UnitOfWorkDangerSourceServiceResponse {
  unitOfWorkDangerSource: UnitOfWorkDangerSource;
  measures: Measure[];
}

export interface UnitOfWorkDangerSourceApi extends Omit<UnitOfWorkDangerSource, 'unitOfWorkId' | 'dangerSourceId'> {
  unitOfWork: Pick<UnitOfWork, 'id'>;
  dangerSource: Pick<DangerSource, 'id'>;
  measures: MeasureApi[];
}

export interface UnitOfWorkDangerSourceListApi extends ModelApi<UnitOfWorkDangerSourceApi> {}
