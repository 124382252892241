<div class="content">
  <form (submit)="submit()" [formGroup]="form" *ngIf="!sended; else successMsg">
    <div class="form-field">
      <label for="name">Nom *</label>
      <input type="text" id="name" formControlName="name" placeholder="votre nom" />
      <p class="error" *ngIf="form.get('name').touched && form.get('name').hasError('required')">Votre nom est obligatoire</p>
    </div>
    <div class="form-field">
      <label for="email">Adresse email*</label>
      <input type="text" id="email" formControlName="email" placeholder="votre email" />
      <p class="error" *ngIf="form.get('email').touched && !form.get('email').valid">Votre adresse email doit être un email valide</p>
    </div>
    <div class="form-field">
      <label for="company">Société*</label>
      <input type="text" id="company" formControlName="company" placeholder="votre société" />
      <p class="error" *ngIf="form.get('company').touched && form.get('company').hasError('required')">Votre société est obligatoire</p>
    </div>

    <lib-select [options]="subjectOptions" class="input-render" formControlName="subject">
      <label>Objet *</label>
    </lib-select>

    <div class="form-field">
      <label for="message">Votre message*</label>
      <textarea id="message" formControlName="message" placeholder="votre message"></textarea>
      <p class="error" *ngIf="form.get('message').touched && form.get('message').hasError('required')">Votre message est obligatoire</p>
    </div>

    <div class="actions">
      <button type="submit" class="button" [ngClass]="{ disabled: !form.valid }">Valider la demande</button>
    </div>
  </form>

  <ng-template #successMsg>
    <div class="success">
      <h2>Votre demande à été transmise.</h2>
      <a routerLink="/" class="button">Revenir à l'accueil</a>
    </div>
  </ng-template>
</div>

<app-insert [contact]="true">
  <ng-container title>Une question ? Une demande particulière ?</ng-container>
  <p>
    Vous bénéficiez d'un accès illimité à notre service d'assistance
    <strong>Experts en Ligne</strong>
    , pour répondre à toutes vos questions concernant la prévention des risques professionnels :
  </p>
</app-insert>
