import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { DangerSourceImageActions, DangerSourceImageApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { DangerSourceImage } from '../models';

export interface State extends EntityState<DangerSourceImage>, LoadingState {}

export const adapter: EntityAdapter<DangerSourceImage> = createEntityAdapter<DangerSourceImage>();

export const initialState: State = adapter.getInitialState(LoadingStateHelper.initial());

const DangerSourceImageReducer = createReducer(
  initialState,
  // Load
  on(DangerSourceImageActions.load, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(DangerSourceImageApiActions.loadSuccess, (state, { images }) => {
    return adapter.upsertMany(images, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(DangerSourceImageApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Update
  on(DangerSourceImageActions.update, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(DangerSourceImageApiActions.updateSuccess, (state, { image }) => {
    return adapter.upsertOne(image, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(DangerSourceImageApiActions.updateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Create
  on(DangerSourceImageActions.create, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(DangerSourceImageApiActions.createSuccess, (state, { image }) => {
    return adapter.addOne(image, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(DangerSourceImageApiActions.createFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Remove
  on(DangerSourceImageActions.remove, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(DangerSourceImageApiActions.removeSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(DangerSourceImageApiActions.removeFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return DangerSourceImageReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
