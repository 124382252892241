<lib-unit-of-production-list
  [riskUrlMask]="['/mon-duer/unite-de-production', '%id', 'risques']"
  [topUrlMask]="['/mon-duer/unite-de-production', '%id', 'top']"
  [planningUrlMask]="['/mon-duer/unite-de-production', '%id', 'planification']"
  [documentUrlMask]="['/mon-duer/unite-de-production', '%id', 'documents']"
  [historyUrlMask]="['/mon-duer/unite-de-production', '%id', 'historique']"
  [trackingMode]="true"
></lib-unit-of-production-list>

<ng-container *ngIf="company$ | async as company">
  <ng-container *appDuerTitle>
    <h1 *ngIf="contract$ | async as contract">[Contrat] {{ contract.number }}</h1>
    <h2>[Entreprise] {{ company.socialReason }}</h2>
  </ng-container>

  <ng-container *appSidebar>
    <ng-container *ngIf="company$ | async as company">
      <ng-container *ngIf="activities$ | async as activities">
        <app-company-edit
          [company]="company"
          [activities]="activities"
          (updateCompany)="updateCompany(company.id, $event)"
          (updateReferent)="updateReferent(company.id, $event)"
          (updateDecisionMaker)="updateDecisionMaker(company.id, $event)"
        ></app-company-edit>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
