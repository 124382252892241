import { AppState, Company, CompanySelectors, UnitOfProduction, UnitOfProductionSelectors } from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-risk-list',
  templateUrl: './list.component.html',
})
export class RiskListComponent implements OnInit {
  company$: Observable<Company>;
  unitOfProduction$: Observable<UnitOfProduction>;

  constructor(private _store: Store<AppState>) {}

  getDangerSourceListUrlMask(unitOfProduction: UnitOfProduction) {
    return ['/mon-duer/unite-de-production', unitOfProduction.id.toString(), 'risques', '%id', 'sources-de-danger'];
  }

  getRiskDetailUrlMask() {
    return ['/detail-des-risques'];
  }

  ngOnInit(): void {
    this.unitOfProduction$ = this._store.pipe(select(UnitOfProductionSelectors.getSelected));
    this.company$ = this._store.pipe(select(CompanySelectors.getSelected));
  }
}
