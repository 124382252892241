import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { ConfirmModalComponent } from '../../../components/actions/confirm/confirm.component';
import { UrlMaskPipe } from '../../../pipes/url-mask.pipe';
import { HydratedMeasureActions, MeasureActions, UnitOfProductionActions, UnitOfWorkDangerSourceActions } from '../../../store/actions';
import { HydratedMeasure, Measure, UnitOfProduction } from '../../../store/models';
import { AppState } from '../../../store/reducers';
import { MeasureSelectors, UnitOfProductionSelectors } from '../../../store/selectors';

@Component({
  selector: 'lib-top-measure-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [UrlMaskPipe],
})
export class HydratedMeasureListComponent implements OnInit {
  @Input() dangerSourceItemUrlMask: string[];
  topMeasures$: Observable<HydratedMeasure[]>;
  unitOfProduction$: Observable<UnitOfProduction>;

  get currentDate() {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    return `${day}/${month}/${date.getFullYear()}`;
  }

  constructor(private _store: Store<AppState>, private _dialog: MatDialog, private _urlMaskPipe: UrlMaskPipe, private _router: Router) {}

  ngOnInit(): void {
    this.topMeasures$ = this._store.pipe(select(MeasureSelectors.getHydratedTopBySelectedUnitOfProduction));
    this.unitOfProduction$ = this._store.pipe(select(UnitOfProductionSelectors.getSelected));

    this._store.dispatch(HydratedMeasureActions.loadTop());
  }

  onDrop(data) {
    this._store.dispatch(HydratedMeasureActions.moveTop({ id: +data.id, topPosition: data.position }));
  }

  print(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    window.print();
  }

  redirectToDangerSource({ measure, dangerSource, riskType, unitOfWorkDangerSource }: HydratedMeasure) {
    const url = this._urlMaskPipe.transform(
      this.dangerSourceItemUrlMask,
      [dangerSource.id, measure.unitOfProductionId, riskType.id],
      ['id', 'unitOfProductionId', 'riskTypeId']
    );

    this._store.dispatch(UnitOfWorkDangerSourceActions.select({ id: unitOfWorkDangerSource.id }));
    this._store.dispatch(MeasureActions.highlight({ id: measure.id }));

    this._router.navigate(url);
  }

  removeTop(topMeasure: HydratedMeasure) {
    const dialogRef = this._dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Êtes-vous sur de vouloir supprimer cette mesure du top ?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        filter(confirm => confirm),
        tap(() =>
          this._store.dispatch(
            MeasureActions.update({
              id: topMeasure.measure.id,
              values: {
                top: false,
              },
            })
          )
        )
      )
      .subscribe();
  }

  setPapripact(val: boolean, unitOfProductionId: number) {
    this._store.dispatch(UnitOfProductionActions.update({ id: unitOfProductionId, values: { papripact: val } }));
  }

  updateMeasure(topMeasure: HydratedMeasure, values: Partial<Measure>) {
    this._store.dispatch(MeasureActions.update({ id: topMeasure.measure.id, values }));
  }
}
