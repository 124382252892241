import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { DangerSourceService } from '../../services/models/danger-source.service';
import { DangerSourceActions, DangerSourceApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { AppState } from '../reducers';
import { DangerSourceSelectors, RiskTypeSelectors } from '../selectors';

@Injectable()
export class DangerSourceEffects {
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceActions.create),
      withLatestFrom(this._store.pipe(select(RiskTypeSelectors.getSelected))),
      mergeMap(([{ name, refDangerSourceId }, riskType]) =>
        this._srv.create(riskType.id, name, refDangerSourceId).pipe(
          map(dangerSource => DangerSourceApiActions.createSuccess({ dangerSource })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(DangerSourceApiActions.createFailure(), 'Erreur lors de la création de la situation dangereuse')
            )
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceActions.remove),
      mergeMap(({ id }) =>
        this._srv.delete(id).pipe(
          map(() => DangerSourceApiActions.removeSuccess({ id })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(DangerSourceApiActions.removeFailure(), 'Erreur lors de la suppression de la situation dangereuse')
            )
          )
        )
      )
    )
  );

  duplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceActions.duplicate),
      withLatestFrom(this._store.pipe(select(RiskTypeSelectors.getSelected))),
      mergeMap(([{ id }, riskType]) =>
        this._srv.duplicate(id, riskType.id).pipe(
          map(dangerSource => DangerSourceApiActions.duplicateSuccess({ dangerSource })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(DangerSourceApiActions.duplicateFailure(), `Erreur lors de la duplication de la situation dangereuse`)
            )
          )
        )
      )
    )
  );

  loadBySelectedRiskType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceActions.load),
      withLatestFrom(this._store.pipe(select(RiskTypeSelectors.getSelected))),
      switchMap(([, riskType]) =>
        this._srv.getAllByRiskType(riskType.id).pipe(
          map(dangerSources => DangerSourceApiActions.loadSuccess({ dangerSources })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(DangerSourceApiActions.loadFailure(), 'Erreur lors de la récupération des situations dangereuses')
            )
          )
        )
      )
    )
  );

  loadForRiskType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceActions.loadForRiskType),
      mergeMap(({ riskTypeId }) =>
        this._srv.getAllByRiskType(riskTypeId).pipe(
          map(dangerSources => DangerSourceApiActions.loadSuccess({ dangerSources })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(DangerSourceApiActions.loadFailure(), 'Erreur lors de la récupération des situations dangereuses')
            )
          )
        )
      )
    )
  );

  move$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceActions.move),
      map(({ id, position }) => ({
        id,
        values: {
          position,
        },
      })),
      mergeMap(({ id, values }) =>
        this._srv.update(id, values).pipe(
          map(dangerSource => DangerSourceApiActions.moveSuccess({ dangerSource })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(DangerSourceApiActions.moveFailure(), 'Erreur lors du déplacement de la situation dangereuse')
            )
          )
        )
      )
    )
  );

  select$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceActions.select),
      withLatestFrom(this._store.pipe(select(DangerSourceSelectors.getSelected))),
      switchMap(([{ id }, dangerSource]) =>
        dangerSource === undefined
          ? this._srv.select(id).pipe(
              map(dangerSourceApi => DangerSourceApiActions.selectSuccess({ dangerSource: dangerSourceApi })),
              catchError(() =>
                of(true).pipe(
                  EffectHelper.failure(DangerSourceApiActions.selectFailure(), 'Erreur lors de la selection de la situation dangereuse')
                )
              )
            )
          : of(DangerSourceApiActions.selectSuccess({ dangerSource }))
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DangerSourceActions.update),
      mergeMap(({ id, values }) =>
        this._srv.update(id, values).pipe(
          map(dangerSource => DangerSourceApiActions.updateSuccess({ dangerSource })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(DangerSourceApiActions.updateFailure(), 'Erreur lors de la sauvegarde de la situation dangereuse')
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: DangerSourceService, private _store: Store<AppState>) {}
}
