import {
  AfterContentChecked,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';

import { DragItemDirective } from '../../directives/drag-item.directive';
import { ShadowItemDirective } from '../../directives/shadow-item.directive';

@Component({
  selector: 'lib-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss'],
})
export class DropZoneComponent implements AfterContentChecked {
  @HostBinding('class') className = 'drop-zone';
  @ContentChildren(DragItemDirective) dragItems: QueryList<DragItemDirective>;
  @Output() dropItem: EventEmitter<{ [key: string]: any }> = new EventEmitter();
  @ViewChild(ShadowItemDirective) shadow: ShadowItemDirective;
  private _dragover = false;
  private _timer: number;

  get zoneClassName() {
    return this._dragover ? 'dragover' : '';
  }

  constructor() {}

  ngAfterContentChecked(): void {
    let position = 1;
    this.dragItems.forEach(item => (item.position = position++));
  }

  @HostListener('dragover', ['$event'])
  onDragover(e: DragEvent) {
    e.preventDefault();

    this._dragover = true;
    clearTimeout(this._timer);

    const top = e.clientY;
    const current = this.dragItems
      .toArray()
      .reverse()
      .find(item => item.contains(top));

    if (current) {
      this.shadow.position = current.position;
    } else {
      this.shadow.position = 0;
    }

    this.shadow.domEl.style.height = `${e.dataTransfer.getData('originalHeight')}px`;
  }

  @HostListener('drop', ['$event'])
  onDrop(e: DragEvent) {
    const position = this.dragItems.filter(item => item.position <= this.shadow.position).filter(item => !item.isDragged).length;
    const data = {
      position,
    };
    e.dataTransfer.types.filter(key => key !== 'position').forEach(key => (data[key] = e.dataTransfer.getData(key)));

    this._dragover = false;
    this.dropItem.emit(data);
  }

  @HostListener('dragleave')
  onLeave() {
    this._timer = window.setTimeout(() => (this._dragover = false), 100);
  }
}
