import { inject } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';

import { CompanyActions } from '../store/actions';
import { AppState } from '../store/reducers';
import { CompanySelectors, ContractSelectors } from '../store/selectors';

export const companyResolver = (): Observable<boolean> => {
  const store = inject(Store<AppState>);
  const loaded$ = store.pipe(
    select(CompanySelectors.getSelected),
    filter(company => company !== undefined),
    first(),
    switchMap(() => of(true))
  );

  store
    .pipe(
      select(ContractSelectors.getSelected),
      filter(contract => contract !== undefined),
      first(),
      tap(() => store.dispatch(CompanyActions.loadForCurrentContract()))
    )
    .subscribe();

  return loaded$;
};
