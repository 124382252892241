<div class="content" *ngIf="user$ | async as user">
  <div class="logo">
    <ng-container *ngIf="isAuthenticated; else defaultLogo">
      <img *ngIf="user.config?.partner.logo; else defaultLogo" [src]="user.config.partner.logo" />
    </ng-container>
  </div>
  <ng-container *ngIf="isAuthenticated">
    <nav>
      <a routerLink="/" title="Accueil" class="home" [class]="getNavClass('/')">Accueil</a>
      <ng-container handle *ngIf="selectedContract$ | async as contract">
        <ng-container *ngIf="selectedContractHasDU$ | async">
          <a routerLink="/mon-duer" title="Mon Entreprise" [class]="getNavClass('/mon-duer')">Mon Entreprise</a>

          <ng-container *ngIf="unitOfProductions$ | async as unitOfProductions">
            <a
              *ngIf="unitOfProductions.length === 1"
              [routerLink]="['/mon-duer', 'unite-de-production', unitOfProductions[0].id, 'risques']"
              title="Mon DUER"
              [class]="getNavClass('/mon-duer/unite-de-production/' + unitOfProductions[0].id + '/risques')"
            >
              Mon DUER
            </a>

            <lib-dropdown-menu class="navigation" position="after" *ngIf="unitOfProductions.length > 1">
              <ng-container handle>Mon DUER</ng-container>
              <a
                *ngFor="let unitOfProduction of unitOfProductions"
                [routerLink]="['/mon-duer', 'unite-de-production', unitOfProduction.id, 'risques']"
                [class]="getNavClass('/mon-duer/unite-de-production/' + unitOfProduction.id + '/risques')"
                libDropdownItem
                [textFormat]="unitOfProduction.name"
              ></a>
            </lib-dropdown-menu>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="productsWithoutDU$ | async as products">
        <lib-dropdown-menu class="navigation" position="after" *ngIf="products.length > 0">
          <ng-container handle>Autres produits</ng-container>
          <a [routerLink]="['/produit', product.id]" libDropdownItem *ngFor="let product of products">
            {{ product.name }}
          </a>
        </lib-dropdown-menu>
      </ng-container>
      <lib-dropdown-menu *ngIf="pages$ | async as pages" class="navigation" position="after">
        <ng-container handle>Boite à outils</ng-container>
        <a *ngFor="let page of pages" [routerLink]="['/', 'static', page.code]" libDropdownItem>{{ page.title }}</a>
        <a routerLink="/detail-des-risques" libDropdownItem>Risques détaillés</a>
      </lib-dropdown-menu>
      <a routerLink="/contact" title="Poser une question" [class]="getNavClass('/contact')">Poser une question</a>
    </nav>
    <ng-container *ngIf="companies$ | async as companies">
      <ng-container *ngIf="contractsWithDU$ | async as contracts">
        <lib-dropdown-menu position="after" class="navigation contract-list" *ngIf="contracts.length > 1; else currentContract">
          <ng-container handle *ngIf="selectedContract$ | async as contract">
            {{ getContractLabel(contract, companies) }}
          </ng-container>
          <a *ngFor="let contract of contracts" (click)="select(contract.id)" libDropdownItem>
            {{ getContractLabel(contract, companies) }}
          </a>
        </lib-dropdown-menu>

        <ng-template #currentContract>
          <span class="contract-label" *ngIf="selectedContract$ | async as contract">
            {{ getContractLabel(contract, companies) }}
          </span>
        </ng-template>
      </ng-container>
    </ng-container>
    <a href="#" (click)="logout($event)" class="logout" title="Se déconnecter">Se déconnecter</a>
  </ng-container>
</div>

<ng-template #defaultLogo>
  <span [class]="defaultLogoClassName"></span>
</ng-template>
