<ng-container *ngIf="images$ | async as images">
  <span class="close" (click)="close()"></span>

  <div class="title">
    Photos
    <span>{{ images.length }}</span>
  </div>
  <div class="subtitle">Les photos uploadés doivent être au format .JPEG et doivent faire moins de 2 Mo.</div>

  <lib-picture-list [images]="images" [dangerSourceId]="data.dangerSource.id"></lib-picture-list>

  <div *ngIf="loading$ | async as loading" class="loading"></div>
</ng-container>
