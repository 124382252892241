import { QuotationEnum, UnitOfWorkDangerSource } from '../store/models';

export class QuotationHelper {
  static getClassName(quotation: QuotationEnum): string {
    let className = '';

    switch (quotation) {
      case QuotationEnum.LOW:
        className = 'quotation-low';
        break;
      case QuotationEnum.NORMAL:
        className = 'quotation-normal';
        break;
      case QuotationEnum.IMPORTANT:
        className = 'quotation-important';
        break;
      case QuotationEnum.CRITICAL:
        className = 'quotation-critical';
        break;
    }

    return className;
  }

  static getLabel(quotation: QuotationEnum): string {
    let label = '';

    switch (quotation) {
      case QuotationEnum.LOW:
        label = 'Risque faible';
        break;
      case QuotationEnum.NORMAL:
        label = 'Risque moyen';
        break;
      case QuotationEnum.IMPORTANT:
        label = 'Risque élevé';
        break;
      case QuotationEnum.CRITICAL:
        label = 'Risque très élevé';
        break;
    }

    return label;
  }

  static getSummary(unitOfWorkDangerSource: UnitOfWorkDangerSource) {
    const { frequency, severity, proficiency } = unitOfWorkDangerSource;
    return `F ${frequency} - G ${severity} - M ${proficiency}`;
  }
}
