import { AppState, CompanyActions } from '@aequalib';
import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-duer-change-contract',
  template: '',
  styles: [''],
})
export class DuerChangeContractComponent implements AfterViewInit {
  constructor(private _router: Router, private _store: Store<AppState>) {}

  ngAfterViewInit() {
    this._store.dispatch(CompanyActions.unselect());
    setTimeout(() => {
      this._router.navigate(['/mon-duer']);
    }, 0);
  }
}
