import { createAction, props } from '@ngrx/store';
import { Contract } from '../models';

export const loadSuccess = createAction('[Contract/API] Load Contracts Success', props<{ contracts: Contract[] }>());
export const loadFailure = createAction('[Contract/API] Load Contracts Failure');
export const refreshSelectedSuccess = createAction(
  '[Contract/API] Refresh Data for selected Contract Success',
  props<{ contract: Contract }>()
);
export const refreshSelectedFailure = createAction('[Contract/API] Refresh Data for selected Contract Failure');
