import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class FloatHelper {
  static formatForApi(value: number, key: string) {
    if (value === undefined) {
      return null;
    }

    return { [key]: FloatHelper.numberToString(value) };
  }

  static formatForDisplay(value: number, precision = 2) {
    if (!value) {
      return null;
    }

    const [integer, decimal] = value.toString().split('.');

    return `${integer}.${decimal ? decimal.padEnd(precision, '0') : '00'}`;
  }

  static numberToString(value: number): string {
    if (!value && value !== 0) {
      return null;
    }

    return value.toString();
  }

  static stringToNumber(value: string): number {
    return !value ? null : +value;
  }

  static validator(precision = 2): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const valuePattern = new RegExp(`^\\d*(\\.\\d{1,${precision}})?$`, 'g');

      if (!control.value) {
        return null;
      }

      if (!control.value.match(valuePattern)) {
        return { float: true };
      }

      return null;
    };
  }
}
