import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private _duerAction = new BehaviorSubject<TemplateRef<any> | null>(null);
  private _duerTitle = new BehaviorSubject<TemplateRef<any> | null>(null);
  private _pageClass = new BehaviorSubject<string | null>(null);
  private _sidebar = new BehaviorSubject<TemplateRef<any> | null>(null);
  private _sidebarClass = new BehaviorSubject<string | null>(null);

  get duerTitle$(): Observable<TemplateRef<any> | null> {
    return this._duerTitle.asObservable();
  }

  get sidebar$(): Observable<TemplateRef<any> | null> {
    return this._sidebar.asObservable();
  }
  get sidebarClass$(): Observable<string | null> {
    return this._sidebarClass.asObservable();
  }

  get duerAction$(): Observable<TemplateRef<any> | null> {
    return this._duerAction.asObservable();
  }
  get pageClass$(): Observable<string | null> {
    return this._pageClass.asObservable();
  }

  set duerTitle(tpl: TemplateRef<any> | null) {
    setTimeout(() => this._duerTitle.next(tpl));
  }

  set sidebar(tpl: TemplateRef<any> | null) {
    setTimeout(() => this._sidebar.next(tpl));
  }
  set sidebarClass(className: string | null) {
    setTimeout(() => this._sidebarClass.next(className));
  }

  set duerAction(tpl: TemplateRef<any> | null) {
    setTimeout(() => this._duerAction.next(tpl));
  }
  set pageClass(className: string | null) {
    setTimeout(() => this._pageClass.next(className));
  }
}
