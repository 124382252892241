import { DateHelper, HydratedMeasure, MEASURE_TYPE, MeasureSelectors, QuotationEnum } from '@aequalib';
import { createSelector } from '@ngrx/store';
import { PlanningFilters } from '../../components/planning/filter/filter.component';
import * as UiSelectors from './ui.selectors';

const applyFilter = (hydratedMeasure: HydratedMeasure, filters: PlanningFilters): boolean => {
  return Object.entries(filters).every(([key, value]: [string, any]) => {
    const { unitOfWorkDangerSource, riskType, unitOfWork, measure } = hydratedMeasure;
    if (key === 'quotation') {
      return unitOfWorkDangerSource ? (value as QuotationEnum[]).includes(unitOfWorkDangerSource.quotation) : false;
    }

    if (key === 'risk') {
      return riskType ? riskType.id === parseInt(value, 10) : false;
    }

    if (key === 'unitOfWork') {
      return unitOfWork ? unitOfWork.id === parseInt(value, 10) : false;
    }

    if (key === 'responsible') {
      return measure.inCharge === value;
    }

    if (key === 'notification') {
      const { notification } = measure;
      return value ? notification !== null : notification === null;
    }

    if (key === 'periodic') {
      return value === measure.periodic;
    }

    if (key === 'dateFrom') {
      const { predictedDate } = measure;
      const date = DateHelper.formatToDate(value);
      return predictedDate !== null ? predictedDate >= date : false;
    }

    if (key === 'dateTo') {
      const { predictedDate } = measure;
      const date = DateHelper.formatToDate(value);
      return predictedDate !== null ? predictedDate <= date : false;
    }

    return true;
  });
};

export const getForPlanning = createSelector(
  MeasureSelectors.getHydratedAndSortedBySelectedUnitOfProduction,
  UiSelectors.getPlanningFilters,
  (measures, filters) => {
    return measures
      .filter(measure => ([null, undefined].includes(filters.periodic) ? measure.measure.type === MEASURE_TYPE.PREVENTIVE : true))
      .filter(measure => applyFilter(measure, filters));
  }
);
