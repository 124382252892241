import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Product } from '../models';
import { getProductState } from '../reducers';
import { State } from '../reducers/product.reducer';

const getEntitiesFromState = createSelector(getProductState, (state: State): Dictionary<Product> => state.entities);

export const getAll = createSelector(getEntitiesFromState, entities => Object.keys(entities).map(id => entities[id]));

export const getThoseWithDU = createSelector(getAll, products =>
  products.filter(({ generatedRiskAssessmentDocument }) => generatedRiskAssessmentDocument)
);
export const getThoseWithoutDU = createSelector(getAll, products =>
  products.filter(({ generatedRiskAssessmentDocument }) => !generatedRiskAssessmentDocument)
);

export const getById = id => createSelector(getAll, entities => entities.find(entity => entity.id === id));
