import {
  AppState,
  Contract,
  ContractSelectors,
  UnitOfProduction,
  UnitOfProductionSelectors,
  UnitOfWork,
  UnitOfWorkActions,
  UnitOfWorkSelectors,
} from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-unit-of-production-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class UnitOfProductionDocumentComponent implements OnInit {
  contract$: Observable<Contract>;
  form: UntypedFormGroup;
  unitOfProduction$: Observable<UnitOfProduction>;
  unitOfWorks$: Observable<UnitOfWork[]>;

  constructor(private _store: Store<AppState>, private _fb: UntypedFormBuilder) {}

  getByUnitOfWork(unitOfProduction: UnitOfProduction, e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.form.valid) {
      return;
    }

    const selected = this.form.value.selected as number[];
    const url = unitOfProduction.riskAssessmentExportUrl;
    const queryString = selected.map(id => `unitOfWorkIds[]=${id}`).join('&');
    window.open(`${url}?${queryString}`);
  }

  isChecked(id: number) {
    const selected = (this.form.value.selected as number[]) || [];
    return selected.includes(id);
  }

  ngOnInit(): void {
    this.unitOfProduction$ = this._store.pipe(select(UnitOfProductionSelectors.getSelected));
    this.unitOfWorks$ = this._store.pipe(select(UnitOfWorkSelectors.getAllAvailableBySelectedUnitOfProduction));
    this.contract$ = this._store.pipe(select(ContractSelectors.getSelected));
    this._store.dispatch(UnitOfWorkActions.load());

    this.form = this._fb.group({
      selected: [null, Validators.required],
    });
  }
}
