import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { DangerSourceImage } from '../models';
import { getDangerSourceImageState } from '../reducers';
import { State } from '../reducers/danger-source-image.reducer';
import * as DangerSourceSelectors from './danger-source.selectors';
import * as RiskCategorySelectors from './risk-category.selectors';
import * as RiskTypeSelectors from './risk-type.selectors';

const getEntitiesFromState = createSelector(getDangerSourceImageState, (state: State): Dictionary<DangerSourceImage> => state.entities);
const getLoadingFromState = createSelector(getDangerSourceImageState, (state: State): boolean => state.loading);

export const getAll = createSelector(
  getEntitiesFromState,
  entities => Object.keys(entities).map(id => entities[id]) as DangerSourceImage[]
);

export const getAllByDangerSource = (dangerSourceId: number) =>
  createSelector(getAll, entities => entities.filter(entity => entity.dangerSourceId === dangerSourceId));

export const isLoading = createSelector(getLoadingFromState, loading => loading);

export const getAllForSelectedUnitOfProdution = createSelector(
  getAll,
  RiskCategorySelectors.getAllBySelectedUnitOfProduction,
  RiskTypeSelectors.getAll,
  DangerSourceSelectors.getAll,
  (images, categories, types, dangerSources) => {
    const getDangerSources = typeId =>
      dangerSources
        .filter(dangerSource => dangerSource.riskTypeId === typeId)
        .map(dangerSource => ({
          entity: dangerSource,
          images: images.filter(({ dangerSourceId }) => dangerSourceId === dangerSource.id),
        }));

    const getTypes = categoryId =>
      types
        .filter(type => type.riskCategoryId === categoryId)
        .map(type => ({
          entity: type,
          dangerSources: getDangerSources(type.id),
        }))
        .filter(({ dangerSources }) => dangerSources.length > 0);

    return categories
      .map(category => ({
        entity: category,
        types: getTypes(category.id),
      }))
      .filter(({ types }) => types.length > 0);
  }
);
