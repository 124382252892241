import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { RiskTypeActions } from '../../../store/actions';
import { RiskCategory, RiskType, RiskTypeStatus } from '../../../store/models';
import { AppState } from '../../../store/reducers';
import { RiskTypeSelectors } from '../../../store/selectors';

@Component({
  selector: 'lib-risk-category-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class RiskCategoryItemComponent implements OnInit {
  @Input() category: RiskCategory;
  @Input() dangerSourceListUrlMask: string[];
  possibleStatuses$: Observable<RiskTypeStatus[]>;
  @Input() riskDetailUrlMask: string[];
  riskType$: Observable<any>;
  @Input() trackingMode: boolean;

  @HostBinding('class')
  get className(): string {
    const classList = ['risk-category-item', this.category.colorClass];

    return classList.join(' ');
  }

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.riskType$ = this._store.pipe(select(RiskTypeSelectors.getAllByCategory(this.category.id)));
    this.possibleStatuses$ = this._store.pipe(select(RiskTypeSelectors.getPossibleStatuses));
  }

  onDrop(data) {
    this._store.dispatch(RiskTypeActions.move({ id: +data.id, riskCategoryId: this.category.id, position: data.position }));
  }

  update(type: RiskType, values: Partial<RiskType>) {
    this._store.dispatch(RiskTypeActions.update({ id: type.id, values }));
  }
}
