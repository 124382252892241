import { createAction, props } from '@ngrx/store';

import { DangerSourceImage, DangerSourceImageStatusEnum } from '../models';

export const load = createAction('[DangerSourceImage] Load images of a danger source', props<{ dangerSourceId: number }>());
export const update = createAction(
  '[DangerSourceImage] Update image of a danger source',
  props<{ id: number; values: Partial<DangerSourceImage> }>()
);
export const create = createAction(
  '[DangerSourceImage] Create image of a danger source',
  props<{ dangerSourceId: number; values: { image: string; name: string; status: DangerSourceImageStatusEnum } }>()
);
export const remove = createAction('[DangerSourceImage] Delete image of a danger source', props<{ dangerSourceId: number; id: number }>());

export const loadForSelectedUnitOfProduction = createAction('[DangerSourceImage] Load images for the selected Unit of production');
