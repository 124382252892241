import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { RefProfile } from '../models';
import { getRefProfileState } from '../reducers';
import { State } from '../reducers/ref-profile.reducer';

const getEntitiesFromState = createSelector(getRefProfileState, (state: State): Dictionary<RefProfile> => state.entities);
const getSelectedFromState = createSelector(getRefProfileState, (state: State) => state.selected);

export const getAll = createSelector(getEntitiesFromState, entities =>
  Object.keys(entities)
    .map(id => entities[id])
    .sort((a, b) => (a.name > b.name ? 1 : -1))
);

export const getById = id => createSelector(getAll, refProfiles => (id ? refProfiles.find(curr => curr.id === id) : undefined));

export const getSelected = createSelector(getAll, getSelectedFromState, (entities, id) => entities.find(entity => entity.id === id));
