import { ModelApi } from '../helpers/model.helper';

export enum HistoryActionEnum {
  DELETE = 'delete',
}

export enum HistoryObjectEnum {
  UNIT_OF_PRODUCTION = 'unit_of_production',
  UNIT_OF_WORK_DANGER_SOURCE = 'unit_of_work_danger_source',
  DANGER_SOURCE = 'danger_source',
  UNIT_OF_WORK = 'unit_of_work',
  MEASURE = 'measure',
}

export interface History {
  id: number;
  objectClass: HistoryObjectEnum;
  objectId: number;
  action: HistoryActionEnum;
  createdAt: Date;
  objectName: string;
  unitOfProductionId: number;
}

export interface HistoryApi extends Omit<History, 'unitOfProductionId'> {}

export interface HistoryListApi extends ModelApi<HistoryApi> {}
