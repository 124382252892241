import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'lib-line-item',
  templateUrl: './line-item.component.html',
  styleUrls: ['./line-item.component.scss'],
})
export class LineItemComponent {
  private _disabled = false;

  @Input()
  set disabled(val) {
    this._disabled = coerceBooleanProperty(val);
  }

  @HostBinding('class')
  get className() {
    const classList = ['lib-line-item'];
    if (this._disabled) {
      classList.push('disabled');
    }

    return classList.join(' ');
  }
}
