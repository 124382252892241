import { createAction, props } from '@ngrx/store';
import { Measure, MeasureType } from '../models';

export const load = createAction('[Measure] Load Measures from unitOfWorkDangerSource entity', props<{ measures: Measure[] }>());
export const update = createAction('[Measure] Update Measure', props<{ id: number; values: Partial<Measure> }>());
export const create = createAction('[Measure] Create Measure', props<{ name: string; measureType: MeasureType; refMeasureId?: number }>());
export const remove = createAction('[Measure] Delete Measure', props<{ id: number }>());
export const removeByUnitOfWorkDangerSource = createAction(
  '[Measure] Delete Measures linked to a unitOfWorkDangerSource',
  props<{ id: number }>()
);
export const clean = createAction('[Measure] Clean store of Measure');
export const move = createAction('[Measure] Move Measure', props<{ id: number; measureType: MeasureType; position: number }>());

export const highlight = createAction('[Measure] Highlight Measure', props<{ id: number }>());
