import { Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter, first, tap } from 'rxjs/operators';
import { DangerSourceSelectorComponent } from '../../../containers/danger-source/selector/selector.component';
import { QuotationHelper } from '../../../helpers/quotation.helper';
import { UnitOfWork, UnitOfWorkDangerSource } from '../../../store/models';
import { ConfirmModalComponent } from '../../actions/confirm/confirm.component';

@Component({
  selector: 'lib-danger-source-unit-of-work',
  templateUrl: './unit-of-work.component.html',
  styleUrls: ['./unit-of-work.component.scss'],
})
export class DangerSourceUnitOfWorkComponent implements OnChanges {
  @Output() create: EventEmitter<null> = new EventEmitter();
  @Output() deleteRequest: EventEmitter<UnitOfWorkDangerSource> = new EventEmitter();
  @Output() duplicateRequest: EventEmitter<UnitOfWorkDangerSource> = new EventEmitter();
  @Output() duplicateToDangerSourceRequest: EventEmitter<{
    unitOfWorkDangerSource: UnitOfWorkDangerSource;
    dangerSourceId: number;
    unitOfWorkId: number;
  }> = new EventEmitter();
  form: UntypedFormGroup;
  @Output() selectRequest: EventEmitter<UnitOfWorkDangerSource> = new EventEmitter();
  @Input() selected: UnitOfWorkDangerSource;
  @Input() unitOfWork: UnitOfWork;
  unitOfWorkDangerSource: UnitOfWorkDangerSource;
  @Input() unitOfWorkDangerSources: UnitOfWorkDangerSource[];
  @Output() update: EventEmitter<{
    unitOfWorkDangerSource: UnitOfWorkDangerSource;
    values: Partial<UnitOfWorkDangerSource>;
  }> = new EventEmitter();
  private _hasDangerSourceLink: boolean;

  get canDuplicate() {
    return !this.isSelected && this.selected;
  }

  @HostBinding('class')
  get className(): string {
    const classList = [];

    if (this.unitOfWorkDangerSource) {
      classList.push(QuotationHelper.getClassName(this.unitOfWorkDangerSource.quotation));
    }

    if (this.isSelected) {
      classList.push('selected');
    }

    return classList.join(' ');
  }

  get hasDangerSourceLink(): boolean {
    return this._hasDangerSourceLink;
  }

  // used by ng-model
  set hasDangerSourceLink(value: boolean) {
    this._hasDangerSourceLink = value;

    if (this._hasDangerSourceLink) {
      this.create.emit();
    } else {
      this.delete();
    }
  }

  get isSelected(): boolean {
    return this.selected ? this.selected.unitOfWorkId === this.unitOfWork.id : false;
  }

  constructor(private _dialog: MatDialog) {}

  confirmDuplicateForDangerSource(dangerSourceId: number, unitOfWorkId: number) {
    const dialogRef = this._dialog.open(ConfirmModalComponent, {
      data: {
        title: `Cette duplication supprimera toutes les mesures de l'UT correspondante dans la situation dangereuse selectionnée`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        filter(confirm => confirm),
        tap(() => {
          this.duplicateToDangerSourceRequest.emit({
            unitOfWorkDangerSource: this.unitOfWorkDangerSource,
            dangerSourceId,
            unitOfWorkId,
          });
        })
      )
      .subscribe();
  }

  delete(e?: Event) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const dialogRef = this._dialog.open(ConfirmModalComponent, {
      data: {
        title: `Etes vous sur de vouloir marquer cette unité de travail comme non concernée ?`,
        message: `Cela entrainera la suppression des mesures liées à cette unité de travail pour la source danger actuelle.`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        tap(confirm => {
          if (confirm && this.unitOfWorkDangerSource) {
            this.deleteRequest.emit(this.unitOfWorkDangerSource);
          } else {
            this._hasDangerSourceLink = true;
          }
        })
      )
      .subscribe();
  }

  duplicate(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.canDuplicate) {
      return;
    }

    const dialogRef = this._dialog.open(ConfirmModalComponent, {
      data: {
        title: `Etes vous sur de vouloir dupliquer les données de l'UT selectionnée dans cette UT ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        tap(confirm => {
          if (confirm && this.unitOfWorkDangerSource) {
            this.duplicateRequest.emit(this.unitOfWorkDangerSource);
          }
        })
      )
      .subscribe();
  }

  duplicateForDangerSource(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    const dialogRef = this._dialog.open(DangerSourceSelectorComponent, {
      data: {
        dangerSourceId: this.unitOfWorkDangerSource.dangerSourceId,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        filter(data => data),
        tap(({ dangerSource, unitOfWork }) => this.confirmDuplicateForDangerSource(dangerSource, unitOfWork))
      )
      .subscribe();
  }

  ngOnChanges(changes) {
    if (changes.unitOfWorkDangerSources) {
      this.unitOfWorkDangerSource = this.unitOfWorkDangerSources.find(current => current.unitOfWorkId === this.unitOfWork.id);
      this._hasDangerSourceLink = this.unitOfWorkDangerSource !== undefined;
    }
  }

  select(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    if (this.unitOfWorkDangerSource) {
      this.selectRequest.emit(this.unitOfWorkDangerSource);
    }
  }

  updateQuotation(values: Partial<UnitOfWorkDangerSource>) {
    this.update.emit({
      unitOfWorkDangerSource: this.unitOfWorkDangerSource,
      values,
    });
  }
}
