import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { first, tap } from 'rxjs/operators';

import { RequestStateEnum } from '../../../helpers/request';
import { AnonymousLayoutComponent } from '../layout/layout.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent extends AnonymousLayoutComponent implements OnInit {
  ngOnInit(): void {
    this.form = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  submit() {
    if (this.form.valid) {
      const { email } = this.form.value;

      this._srv
        .forgotPassword(email)
        .pipe(
          first(),
          tap(result => {
            this._state = result ? RequestStateEnum.SUCCESS : RequestStateEnum.FAILURE;
          })
        )
        .subscribe();
    }
  }
}
