import { CivilityEnum } from '../../helpers/civility.helper';

export enum UserTypeEnum {
  EXPERT = 'expert',
  MANAGER = 'manager',
  CUSTOMER = 'customer',
}

export interface AuthenticatedUser {
  id: number;
  username: string;
  contractId: number;
  type: UserTypeEnum;
  config?: {
    ownerCompany: {
      civility: CivilityEnum;
      lastname: string;
    };
    partner: {
      color: string;
      logo: string;
    };
  };
}
