<div class="counter">
  <span>{{ index + 1 }}</span>
</div>
<div class="risk">
  <span class="risk-type" class="{{ hydratedMeasure.riskCategory?.colorClass }}" [textFormat]="hydratedMeasure.riskType?.name"></span>
  <span class="danger-source" [textFormat]="hydratedMeasure.dangerSource?.name"></span>
</div>
<div class="unit-of-work" [textFormat]="hydratedMeasure.unitOfWork?.name"></div>
<div class="quotation">
  <span>{{ quotationLabel }}</span>
  <span>{{ quotationSummary }}</span>
</div>
<div class="top"><span class="star" [ngClass]="{ inactive: !hydratedMeasure.measure.top }"></span></div>
<div class="measure" [textFormat]="hydratedMeasure.measure.name"></div>
<div class="periodic" *ngIf="hasPeriodicFilter">{{ typeLabel }}</div>
<div class="date">{{ date | date: 'dd/MM/yyyy' }}</div>
<div class="responsible">{{ hydratedMeasure.measure.inCharge }}</div>
