import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppConfigService } from '@prestaconcept/config-loader';
import { AuthService, AuthStorageService, AuthenticationResponse as PfwAuthenticationResponse } from '@prestaconcept/security';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthenticatedActions, ContractActions } from '../store/actions';
import { AuthenticatedUser } from '../store/models/user.model';
import { AppState } from '../store/reducers';

interface UserResponse extends Omit<AuthenticatedUser, 'contractId'> {}

interface AuthenticationResponse extends PfwAuthenticationResponse {
  contractId: number;
  user: UserResponse;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends AuthService {
  get anonymousUrls(): string[] {
    return [...super.anonymousUrls, `auto-authenticate`, `forgot-password`, `reset-password`];
  }

  constructor(
    http: HttpClient,
    _authStorageSrv: AuthStorageService,
    configSrv: AppConfigService,
    router: Router,
    private _store: Store<AppState>
  ) {
    super(http, _authStorageSrv, configSrv, router);
    this._checkAuthenticationState();
  }

  forgotPassword(email: string): Observable<boolean> {
    return this._http.post<AuthenticationResponse>('forgot-password', { email }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  loginByToken(token: string): Observable<boolean> {
    return this._http.post<AuthenticationResponse>('auto-authenticate', { token }).pipe(
      tap(response => this._storeAuthentication(response)),
      map(() => true),
      catchError(() => of(false))
    );
  }

  logout(): void {
    this._authStorageSrv.removeItem('refresh_token');
    this._authStorageSrv.removeItem('access_token');
    this._authStorageSrv.removeItem('user');
  }

  resetPassword(token: string, password: string): Observable<boolean> {
    return this._http.post<AuthenticationResponse>('reset-password', { token, password }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  protected _storeAuthentication(response: AuthenticationResponse): void {
    if (response.token) {
      super._storeAuthentication(response);

      const { user, contractId } = response;

      if (user?.id) {
        const userData: AuthenticatedUser = {
          id: user.id,
          username: user.username,
          type: user.type,
          contractId: contractId,
          ...(user.config && { config: user.config }),
        };
        this._authStorageSrv.setItem(
          'user',
          JSON.stringify({
            ...userData,
            contractId: contractId,
          })
        );

        this._storeAuthenticationInState(userData, contractId);
      }
    }
  }

  private _checkAuthenticationState() {
    if (this.token !== null) {
      const userData = this._authStorageSrv.getItem('user');
      if (userData !== null) {
        const { contractId, ...user } = JSON.parse(userData);
        this._storeAuthenticationInState(user, contractId);
      }
    }
  }

  private _storeAuthenticationInState(user: AuthenticatedUser, contractId: number): void {
    this._store.dispatch(AuthenticatedActions.loggedIn({ user }));
    if (contractId) {
      this._store.dispatch(ContractActions.select({ contractId }));
    }
  }
}
