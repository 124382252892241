import { createAction, props } from '@ngrx/store';

import { RiskType, RiskTypeStatus } from '../models';

// Load
export const loadSuccess = createAction('[RiskType/API] Load risk types Success', props<{ riskTypes: RiskType[] }>());
export const loadFailure = createAction('[RiskType/API] Load risk types Failure');
// Load Statuses
export const loadStatusesSuccess = createAction(
  '[RiskType/Status/API] Load statuses for risk type Success',
  props<{ statuses: RiskTypeStatus[] }>()
);
export const loadStatusesFailure = createAction('[RiskType/Status/API] Load statuses for risk type Failure');
// Update
export const updateSuccess = createAction('[RiskType/API] Update risk type Success', props<{ riskType: RiskType }>());
export const updateFailure = createAction('[RiskType/API] Update risk type Failure');
// Move
export const moveSuccess = createAction('[RiskType/API] Move risk type Success', props<{ riskType: RiskType }>());
export const moveFailure = createAction('[RiskType/API] Move risk type Failure');
// Select
export const selectSuccess = createAction('[RiskType/API] Select risk type Success', props<{ riskType: RiskType }>());
export const selectFailure = createAction('[RiskType/API] Select risk type Failure');
