<div class="title"><ng-content select="[title]"></ng-content></div>
<ng-content></ng-content>
<div class="contact" *ngIf="contact">
  <div class="item tel">
    <a href="tel:0800730118">0800 730 118</a>
    <div class="info">Services et appels gratuits</div>
  </div>
  <div class="item mail">
    <a href="mailto:info@aequalis-prevention.com">info@aequalis-prevention.com</a>
  </div>
</div>
