import {
  AppState,
  ConfirmModalComponent,
  DangerSourceSelectors,
  Measure,
  MEASURE_TYPE,
  MeasureActions,
  MeasureCreateComponent,
  MeasureNotification,
  MeasureNotificationActions,
  MeasureSelectors,
  RefMeasure,
  UnitOfWorkDangerSource,
} from '@aequalib';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { CreateResponse } from '../../../../../../shared/src/lib/components/actions/create/create.component';

@Component({
  selector: 'app-measure-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class MeasureListComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  highlightedMeasureId$: Observable<number | null>;
  measures$: Observable<Measure[]>;
  @Input() preventive: boolean;
  refMeasures$: Observable<RefMeasure[]>;
  @Input() unitOfWorkDangerSource: UnitOfWorkDangerSource;
  private _destroy$: Subject<boolean> = new Subject();

  get title(): string {
    return this.preventive ? 'Mesures préconisées' : 'Mesures existantes';
  }

  get placeholder(): string {
    const labelType = this.preventive ? 'préconisée' : 'existante';
    return `Ajouter une mesure ${labelType}`;
  }

  get type() {
    return this.preventive ? MEASURE_TYPE.PREVENTIVE : MEASURE_TYPE.EFFECTIVE;
  }

  constructor(private _store: Store<AppState>, private _fb: UntypedFormBuilder, private _dialog: MatDialog) {}

  delete(measure: Measure) {
    const ref = this._dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Êtes vous sûr de vouloir supprimer cette mesure',
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(confirm => confirm),
        tap(() => this._store.dispatch(MeasureActions.remove({ id: measure.id })))
      )
      .subscribe();
  }

  getEnabledCount(measures: Measure[]): number {
    return measures.filter(measure => measure.enabled).length;
  }

  isVisible(measure: Measure): boolean {
    return measure.enabled;
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    const measureSelector = this.preventive
      ? MeasureSelectors.getPreventiveBySelectedUnitOfWorkDangerSource
      : MeasureSelectors.getEffectiveBySelectedUnitOfWorkDangerSource;
    this.measures$ = this._store.pipe(select(measureSelector));
    this.refMeasures$ = this._store.pipe(select(DangerSourceSelectors.getRefMeasuresBySelected));
    this.highlightedMeasureId$ = this._store.pipe(select(MeasureSelectors.getHighlightedId));

    this.form = this._fb.group(
      {
        name: ['', Validators.required],
      },
      { updateOn: 'blur' }
    );

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.form.valid),
        tap(({ name }) => {
          this._store.dispatch(MeasureActions.create({ name, measureType: this.type }));

          this.form.setValue({ name: '' });
          this.form.markAsPristine();
        })
      )
      .subscribe();
  }

  notificationDelete(measure: Measure) {
    const ref = this._dialog.open(ConfirmModalComponent, {
      data: {
        title: `Êtes vous sûr de vouloir supprimer l'alerte de cette mesure`,
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(confirm => confirm),
        tap(() => this._store.dispatch(MeasureNotificationActions.remove({ measure })))
      )
      .subscribe();
  }

  notificationRequest(measure: Measure, data: Partial<MeasureNotification>) {
    if (measure.notification) {
      this._store.dispatch(MeasureNotificationActions.update({ measure, data }));
    } else {
      this._store.dispatch(MeasureNotificationActions.create({ measure, data }));
    }
  }

  onDrop(data) {
    this._store.dispatch(MeasureActions.move({ id: data.id, measureType: this.type, position: data.position }));
  }

  openCreate(e: MouseEvent, measures: Measure[], refMeasures: RefMeasure[]) {
    e.stopPropagation();
    e.preventDefault();

    let ref: MatDialogRef<MeasureCreateComponent, any>;

    const closeFn = (response: CreateResponse) => {
      const { activate, createFromRef, create } = response;
      if (activate.length + createFromRef.length + create.length === 0) {
        ref.close();
      } else {
        this._dialog
          .open(ConfirmModalComponent, {
            data: {
              title: 'Êtes vous sûr de vouloir annuler?',
              message: 'Vos modifications en attente seront perdues',
            },
            disableClose: true,
          })
          .afterClosed()
          .pipe(
            first(),
            filter(confirm => confirm),
            tap(() => ref.close())
          )
          .subscribe();
      }
    };

    ref = this._dialog.open(MeasureCreateComponent, {
      data: {
        measures,
        refMeasures,
        preventive: this.preventive,
        trackingMode: true,
        closeFn,
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(response => response),
        tap(({ activate, create, createFromRef }) => {
          activate.forEach(id => this._store.dispatch(MeasureActions.update({ id, values: { enabled: true } })));
          create.forEach(name => this._store.dispatch(MeasureActions.create({ name, measureType: this.type })));
          createFromRef.forEach(({ id, preventiveName, effectiveName }) => {
            const name = this.preventive ? preventiveName : effectiveName;
            this._store.dispatch(MeasureActions.create({ name, measureType: this.type, refMeasureId: id }));
          });
        })
      )
      .subscribe();
  }

  update(measure: Measure, values: Partial<Measure>) {
    this._store.dispatch(MeasureActions.update({ id: measure.id, values }));
  }
}
