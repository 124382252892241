import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { UnitOfProductionActions, UnitOfProductionApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { UnitOfProduction } from '../models';

export interface State extends EntityState<UnitOfProduction>, LoadingState {
  selected: number;
}

export const adapter: EntityAdapter<UnitOfProduction> = createEntityAdapter<UnitOfProduction>();

export const initialState: State = adapter.getInitialState({
  ...LoadingStateHelper.initial(),
  selected: null,
});

const UnitOfProductionReducer = createReducer(
  initialState,
  // Load
  on(UnitOfProductionActions.load, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfProductionApiActions.loadSuccess, (state, { unitOfProductions }) => {
    return adapter.upsertMany(unitOfProductions, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfProductionApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Select
  on(UnitOfProductionActions.select, (state, { id }) => ({
    ...state,
    ...LoadingStateHelper.start(),
    selected: id,
  })),
  on(UnitOfProductionApiActions.selectSuccess, (state, { unitOfProduction }) => {
    return adapter.upsertOne(unitOfProduction, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfProductionApiActions.selectFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Validate
  on(UnitOfProductionActions.validate, (state, { id }) => ({
    ...state,
    ...LoadingStateHelper.start(),
    selected: id,
  })),
  on(UnitOfProductionApiActions.validateSuccess, (state, { unitOfProduction }) => {
    return adapter.upsertOne(unitOfProduction, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfProductionApiActions.validateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Duplicate
  on(UnitOfProductionActions.duplicate, (state, { id }) => ({
    ...state,
    ...LoadingStateHelper.start(),
    selected: id,
  })),
  on(UnitOfProductionApiActions.duplicateSuccess, (state, { unitOfProduction }) => {
    return adapter.upsertOne(unitOfProduction, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfProductionApiActions.duplicateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Update
  on(UnitOfProductionActions.update, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfProductionApiActions.updateSuccess, (state, { unitOfProduction }) => {
    return adapter.upsertOne(unitOfProduction, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfProductionApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Create
  on(UnitOfProductionActions.create, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfProductionApiActions.createSuccess, (state, { unitOfProduction }) => {
    return adapter.addOne(unitOfProduction, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfProductionApiActions.createFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Remove
  on(UnitOfProductionActions.remove, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(UnitOfProductionApiActions.removeSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(UnitOfProductionApiActions.removeFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Clean
  on(UnitOfProductionActions.clean, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
    });
  }),
  // ChangeReference
  on(UnitOfProductionActions.changeReference, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return UnitOfProductionReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
