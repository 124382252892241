import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DangerSourceActions, DangerSourceApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { DangerSource } from '../models';

export interface State extends EntityState<DangerSource>, LoadingState {
  selected: number;
}

export const adapter: EntityAdapter<DangerSource> = createEntityAdapter<DangerSource>();

export const initialState: State = {
  ...adapter.getInitialState(LoadingStateHelper.initial()),
  selected: null,
};

const DangerSourceReducer = createReducer(
  initialState,
  // Load
  on(DangerSourceActions.load, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(DangerSourceApiActions.loadSuccess, (state, { dangerSources }) => {
    return adapter.upsertMany(dangerSources, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(DangerSourceApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Select
  on(DangerSourceActions.select, (state, { id }) => ({
    ...state,
    ...LoadingStateHelper.start(),
    selected: id,
  })),
  on(DangerSourceApiActions.selectSuccess, (state, { dangerSource }) => {
    return adapter.upsertOne(dangerSource, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(DangerSourceApiActions.selectFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Update
  on(DangerSourceActions.update, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(DangerSourceApiActions.updateSuccess, (state, { dangerSource }) => {
    return adapter.upsertOne(dangerSource, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(DangerSourceApiActions.updateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Create
  on(DangerSourceActions.create, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(DangerSourceApiActions.createSuccess, (state, { dangerSource }) => {
    return adapter.addOne(dangerSource, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(DangerSourceApiActions.createFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Remove
  on(DangerSourceActions.remove, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(DangerSourceApiActions.removeSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(DangerSourceApiActions.removeFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Move
  on(DangerSourceActions.move, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(DangerSourceApiActions.moveSuccess, (state, { dangerSource }) => {
    const { entities } = state;
    // reorder other dangerSources in same riskType
    const working = Object.values(entities)
      .filter(current => current.riskTypeId === dangerSource.riskTypeId)
      .filter(current => current.id !== dangerSource.id)
      .sort((currentA, currentB) => (currentA.position < currentB.position ? -1 : 1))
      .map((current, idx) => ({
        ...current,
        position: idx >= dangerSource.position ? idx + 1 : idx,
      }));
    working.push(dangerSource);

    return adapter.upsertMany(working, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(DangerSourceApiActions.moveFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Duplicate
  on(DangerSourceActions.duplicate, (state, { id }) => ({
    ...state,
    ...LoadingStateHelper.start(),
    selected: id,
  })),
  on(DangerSourceApiActions.duplicateSuccess, (state, { dangerSource }) => {
    return adapter.upsertOne(dangerSource, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(DangerSourceApiActions.duplicateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Images
  on(DangerSourceActions.imageAdded, (state, { dangerSourceId }) => {
    const { entities } = state;
    const selectedDS = Object.values(entities).find(dangerSource => dangerSource.id === dangerSourceId);

    if (!selectedDS) {
      return state;
    }

    const updatedDS = {
      ...selectedDS,
      nbImages: selectedDS.nbImages + 1,
    };

    return adapter.upsertOne(updatedDS, {
      ...state,
    });
  }),
  on(DangerSourceActions.imageDeleted, (state, { dangerSourceId }) => {
    const { entities } = state;
    const selectedDS = Object.values(entities).find(dangerSource => dangerSource.id === dangerSourceId);

    if (!selectedDS) {
      return state;
    }

    const updatedDS = {
      ...selectedDS,
      nbImages: Math.max(selectedDS.nbImages - 1, 0),
    };

    return adapter.upsertOne(updatedDS, {
      ...state,
    });
  }),
  // Clean
  on(DangerSourceActions.clean, state => adapter.removeAll(state))
);

export function reducer(state: State | undefined, action: Action) {
  return DangerSourceReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
