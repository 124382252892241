import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[libDropdownItem]',
})
export class DropdownItemDirective {
  elt: HTMLElement;
  initiate = false;

  constructor(el: ElementRef<HTMLElement>) {
    el.nativeElement.classList.add('dropdown-item');
    this.elt = el.nativeElement;
  }
}
