import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ContractActions, ContractApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { Contract } from '../models';

export interface State extends EntityState<Contract>, LoadingState {
  selected: number;
}

export const adapter: EntityAdapter<Contract> = createEntityAdapter<Contract>();

export const initialState: State = {
  ...adapter.getInitialState(LoadingStateHelper.initial()),
  selected: null,
};

const ContractReducer = createReducer(
  initialState,
  on(ContractActions.loadAndSelect, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(ContractApiActions.loadSuccess, (state, { contracts }) => {
    return adapter.upsertMany(contracts, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(ContractApiActions.refreshSelectedSuccess, (state, { contract }) => {
    return adapter.upsertOne(contract, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(ContractApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Select
  on(ContractActions.select, (state, { contractId }) => {
    return {
      ...state,
      selected: contractId,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return ContractReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
