import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';

import { ConfirmModalComponent, ConfirmModalData } from '../../../components/actions/confirm/confirm.component';
import { RefUnitOfProductionActions, UnitOfProductionActions } from '../../../store/actions';
import { RefUnitOfProduction, UnitOfProduction } from '../../../store/models';
import { UserTypeEnum } from '../../../store/models/user.model';
import { AppState } from '../../../store/reducers';
import { AuthenticatedSelectors, RefUnitOfProductionSelectors, UnitOfProductionSelectors } from '../../../store/selectors';
import { ContractListComponent } from '../../contract/list/list.component';

@Component({
  selector: 'lib-unit-of-production-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class UnitOfProductionListComponent implements OnInit, OnDestroy {
  @Input() documentUrlMask: string[];
  form: UntypedFormGroup;
  @Input() historyUrlMask: string[];
  @Input() planningUrlMask: string[];
  refUnitOfProductions$: Observable<RefUnitOfProduction[]>;
  @Input() riskUrlMask: string[];
  @Input() topUrlMask: string[];
  @Input() trackingMode = false;
  unitOfProductions$: Observable<UnitOfProduction[]>;
  userType$: Observable<UserTypeEnum>;
  private _destroy$: Subject<boolean> = new Subject();

  constructor(private _store: Store<AppState>, private _fb: UntypedFormBuilder, private _dialog: MatDialog) {}

  changeReference(unitOfProduction: UnitOfProduction, refUnitOfProductionId: number) {
    const ref = this._dialog.open(ConfirmModalComponent, {
      data: {
        title: `Êtes vous sur de vouloir changer le profil d'activité de cette unité de production ?`,
        message: `Cela entrainera la suppression de toutes les données associées à cette unité`,
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(confirm => confirm),
        tap(() => this._store.dispatch(UnitOfProductionActions.changeReference({ id: unitOfProduction.id, refUnitOfProductionId })))
      )
      .subscribe();
  }

  delete(unitOfProduction: UnitOfProduction) {
    const ref = this._dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Etes vous sur de vouloir supprimer cette unité de production ?',
        message: `La suppression d'une unité de production implique la suppression des objets liés à celle-ci.`,
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(confirm => confirm),
        tap(() => this._store.dispatch(UnitOfProductionActions.remove({ id: unitOfProduction.id })))
      )
      .subscribe();
  }

  duplicate(unitOfProduction: UnitOfProduction, ask: boolean) {
    if (ask) {
      const ref = this._dialog.open(ContractListComponent);

      ref
        .afterClosed()
        .pipe(
          first(),
          filter(contractId => contractId),
          tap(contractId => this._store.dispatch(UnitOfProductionActions.duplicate({ id: unitOfProduction.id, contractId })))
        )
        .subscribe();
    } else {
      this._store.dispatch(UnitOfProductionActions.duplicate({ id: unitOfProduction.id, contractId: null }));
    }
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this.unitOfProductions$ = this._store.pipe(select(UnitOfProductionSelectors.getAllByCurrentContract));
    this.refUnitOfProductions$ = this._store.pipe(select(RefUnitOfProductionSelectors.getAll));
    this.userType$ = this._store.pipe(select(AuthenticatedSelectors.getUserType));

    this._store.dispatch(UnitOfProductionActions.load());
    this._store.dispatch(RefUnitOfProductionActions.load());

    this.form = this._fb.group(
      {
        name: ['', Validators.required],
      },
      { updateOn: 'blur' }
    );

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.form.valid),
        tap(({ name }) => {
          this._store.dispatch(UnitOfProductionActions.create({ name }));

          this.form.setValue({ name: '' });
          this.form.markAsPristine();
        })
      )
      .subscribe();
  }

  update(unitOfProduction: UnitOfProduction, values: Partial<UnitOfProduction>) {
    this._store.dispatch(UnitOfProductionActions.update({ id: unitOfProduction.id, values }));
  }

  validate(unitOfProduction: UnitOfProduction, userType: UserTypeEnum) {
    const data: ConfirmModalData = {
      title: 'Etes vous sur de vouloir valider cette unité de production ?',
    };

    if (userType !== UserTypeEnum.MANAGER) {
      data.message = 'cette validation sera soumise à un gestionnaire';
    }

    const ref = this._dialog.open(ConfirmModalComponent, { data });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(confirm => confirm),
        tap(() => this._store.dispatch(UnitOfProductionActions.validate({ id: unitOfProduction.id })))
      )
      .subscribe();
  }
}
