import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[libDateAutoFormatter]',
})
export class DateAutoFormatterDirective {
  constructor(protected _el: ElementRef) {}

  protected get _domEl() {
    return this._el.nativeElement as HTMLInputElement;
  }

  @HostListener('input')
  private _format() {
    const val = this._domEl.value;
    const pos = this._domEl.selectionStart;

    // cursor is at the end of the value
    if (val.length === pos) {
      if ([3, 6].includes(val.length) && val.charAt(pos - 1) !== '/') {
        const newVal = `${val.slice(0, pos - 1)}/${val.charAt(pos - 1)}`;
        this._domEl.value = newVal;
        this._domEl.setSelectionRange(pos + 1, pos + 1);
      }
    }
  }

  @HostListener('focus')
  private _selectAllText() {
    const val = this._domEl.value;
    this._domEl.setSelectionRange(0, val.length);
  }
}
