import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { MeasureService } from '../../services/models/measure.service';
import {
  DangerSourceActions,
  DangerSourceApiActions,
  HydratedMeasureActions,
  HydratedMeasureApiActions,
  MeasureActions,
  RiskCategoryActions,
  RiskCategoryApiActions,
  RiskTypeActions,
  RiskTypeApiActions,
  UnitOfWorkActions,
  UnitOfWorkApiActions,
  UnitOfWorkDangerSourceActions,
  UnitOfWorkDangerSourceApiActions,
} from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { HydratedMeasureResponse } from '../models';
import { AppState } from '../reducers';
import { MeasureSelectors, UnitOfProductionSelectors } from '../selectors';

@Injectable()
export class HydratedMeasureEffects {
  clean$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HydratedMeasureActions.clean),
      mergeMap(() => [
        RiskTypeActions.clean(),
        UnitOfWorkActions.clean(),
        DangerSourceActions.clean(),
        UnitOfWorkDangerSourceActions.clean(),
        RiskCategoryActions.clean(),
        MeasureActions.clean(),
      ])
    )
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HydratedMeasureActions.loadAll),
      withLatestFrom(this._store.pipe(select(UnitOfProductionSelectors.getSelected))),
      switchMap(([, unitOfProduction]) =>
        this._srv.loadAllByUnitOfProduction(unitOfProduction.id).pipe(
          switchMap(hydratedMeasureResponses => this._loadResponseToMap(hydratedMeasureResponses, unitOfProduction.id)),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(HydratedMeasureApiActions.loadAllFailure(), 'Erreur lors de la récupération des mesures'))
          )
        )
      )
    )
  );

  loadPreventive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HydratedMeasureActions.loadPreventive),
      withLatestFrom(this._store.pipe(select(UnitOfProductionSelectors.getSelected))),
      switchMap(([, unitOfProduction]) =>
        this._srv.loadPreventiveByUnitOfProduction(unitOfProduction.id).pipe(
          switchMap(hydratedMeasureResponses => this._loadResponseToMap(hydratedMeasureResponses, unitOfProduction.id)),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(
                HydratedMeasureApiActions.loadPreventiveFailure(),
                'Erreur lors de la récupération des mesures préconisées'
              )
            )
          )
        )
      )
    )
  );

  loadTop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HydratedMeasureActions.loadTop),
      withLatestFrom(this._store.pipe(select(UnitOfProductionSelectors.getSelected))),
      switchMap(([, unitOfProduction]) =>
        this._srv.loadTopByUnitOfProduction(unitOfProduction.id).pipe(
          switchMap(hydratedMeasureResponses => this._loadResponseToMap(hydratedMeasureResponses, unitOfProduction.id)),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(HydratedMeasureApiActions.loadTopFailure(), 'Erreur lors de la récupération des mesures du TOP')
            )
          )
        )
      )
    )
  );

  move$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HydratedMeasureActions.moveTop),
      mergeMap(({ id, topPosition }) =>
        of({ id, topPosition }).pipe(withLatestFrom(this._store.pipe(select(MeasureSelectors.getById(id)))))
      ),
      mergeMap(([{ id, topPosition }, { unitOfWorkDangerSourceId }]) =>
        this._srv.update(id, { topPosition }, unitOfWorkDangerSourceId).pipe(
          map(measure => HydratedMeasureApiActions.moveTopSuccess({ measure })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(HydratedMeasureApiActions.moveTopFailure(), 'Erreur lors du déplacement de la mesure du TOP')
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: MeasureService, private _store: Store<AppState>) {}

  private _loadResponseToMap(hydratedMeasureResponses: HydratedMeasureResponse[], unitOfProductionId: number) {
    const measures = hydratedMeasureResponses.map(item => item.measure);
    const unitOfWorkDangerSources = hydratedMeasureResponses.map(item => item.unitOfWorkDangerSource);
    const riskTypes = hydratedMeasureResponses.map(item => item.riskType);
    const riskCategories = hydratedMeasureResponses.map(item => item.riskCategory);
    const dangerSources = hydratedMeasureResponses.map(item => item.dangerSource);
    const unitOfWorks = hydratedMeasureResponses.map(item => ({ ...item.unitOfWork, unitOfProductionId }));

    return [
      MeasureActions.load({ measures }),
      UnitOfWorkApiActions.loadSuccess({ unitOfWorks }),
      UnitOfWorkDangerSourceApiActions.loadSuccess({ unitOfWorkDangerSources }),
      RiskTypeApiActions.loadSuccess({ riskTypes }),
      RiskCategoryApiActions.loadSuccess({ riskCategories }),
      DangerSourceApiActions.loadSuccess({ dangerSources }),
    ];
  }
}
