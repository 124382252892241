import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

import { RiskTypeActions } from '../store/actions';
import { AppState } from '../store/reducers';
import { RiskTypeSelectors } from '../store/selectors';

export const riskTypeResolver = ({ params }: ActivatedRouteSnapshot): Observable<boolean> => {
  const store = inject(Store<AppState>);
  const { riskTypeId } = params;

  const loaded$ = store.pipe(
    select(RiskTypeSelectors.getSelected),
    filter(riskType => riskType !== undefined),
    first(),
    switchMap(() => of(true))
  );

  store.dispatch(RiskTypeActions.select({ id: +riskTypeId }));

  return loaded$;
};
