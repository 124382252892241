import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { RefUnitOfProductionActions, RefUnitOfProductionApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { RefUnitOfProduction } from '../models';

export interface State extends EntityState<RefUnitOfProduction>, LoadingState {}

export const adapter: EntityAdapter<RefUnitOfProduction> = createEntityAdapter<RefUnitOfProduction>();

export const initialState: State = adapter.getInitialState(LoadingStateHelper.initial());

const RefUnitOfProductionReducer = createReducer(
  initialState,
  // Load
  on(RefUnitOfProductionActions.load, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(RefUnitOfProductionApiActions.loadSuccess, (state, { refUnitOfProductions }) => {
    return adapter.upsertMany(refUnitOfProductions, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(RefUnitOfProductionApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return RefUnitOfProductionReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
