<ng-container *ngIf="measures$ | async as measures">
  <div class="header">
    <div class="title">
      {{ title }}
      <span class="count">{{ getEnabledCount(measures) }}</span>
    </div>

    <ng-container *ngIf="preventive">
      <span class="label amount">Coût</span>
      <span class="label responsible">Resp.</span>
      <span class="label date">Date prévue</span>
    </ng-container>
  </div>
  <lib-drop-zone (dropItem)="onDrop($event)">
    <ng-container *ngFor="let measure of measures">
      <ng-container *ngIf="preventive">
        <app-measure-item-preventive
          *ngIf="isVisible(measure); else ghost"
          [measure]="measure"
          (update)="update(measure, $event)"
          (deleteRequest)="delete(measure)"
          (notificationRequest)="notificationRequest(measure, $event)"
          (notificationDeleteRequest)="notificationDelete(measure)"
          libDragItem
          [dragData]="{ id: measure.id }"
          [highlight]="(highlightedMeasureId$ | async) === measure.id"
        >
          <lib-text-format [text]="measure.name"></lib-text-format>
        </app-measure-item-preventive>
      </ng-container>

      <ng-container *ngIf="!preventive">
        <app-measure-item-effective
          *ngIf="isVisible(measure); else ghost"
          [measure]="measure"
          (update)="update(measure, $event)"
          (deleteRequest)="delete(measure)"
          libDragItem
          [dragData]="{ id: measure.id }"
          [highlight]="(highlightedMeasureId$ | async) === measure.id"
        >
          <lib-text-format [text]="measure.name"></lib-text-format>
        </app-measure-item-effective>
      </ng-container>

      <ng-template #ghost>
        <div libDragItem></div>
      </ng-template>
    </ng-container>
    <div class="measure-create" *ngIf="refMeasures$ | async as refMeasures" end>
      <a href="#" class="create-link" (click)="openCreate($event, measures, refMeasures)">Ajouter des mesures</a>
    </div>
  </lib-drop-zone>
</ng-container>
