import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RefRiskTypeService } from '../../services/models/ref-risk-type.service';
import { RefRiskTypeActions, RefRiskTypeApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';

@Injectable()
export class RefRiskTypeEffects {
  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefRiskTypeActions.load),
      switchMap(() =>
        this._srv.getAll().pipe(
          map(refRiskTypes => RefRiskTypeApiActions.loadSuccess({ refRiskTypes })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(RefRiskTypeApiActions.loadFailure(), 'Erreur lors de la récupération des types de risque de référence')
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: RefRiskTypeService) {}
}
