<div class="presentation">
  <h2 class="title">
    Entreprise
    <span>{{ company.socialReason }}</span>
  </h2>

  <p *ngIf="company.address">{{ company.address }}</p>
  <p *ngIf="company.addressComplement">{{ company.addressComplement }}</p>
  <p *ngIf="company.zipcode || company.city">{{ company.zipcode }} {{ company.city }}</p>

  <ng-container *ngIf="company.contactDecisionMaker as contact">
    <p *ngIf="contact.mobileNumber" class="mobile">
      {{ contact.mobileNumber }}
    </p>
    <p *ngIf="contact.email" class="email">
      {{ contact.email }}
    </p>
  </ng-container>
</div>

<ng-container [formGroup]="companyform">
  <div class="part general">
    <div [formGroup]="decisionMakerform">
      <label>Dirigeant</label>
      <lib-select [options]="civilityOptions" formControlName="civility" class="contrast civility"></lib-select>
      <lib-editable-data class="lastname">
        <span libEditableDataLabel></span>
        <input libEditableDataInput formControlName="lastname" />
      </lib-editable-data>
    </div>
    <div>
      <label>Activité</label>
      <lib-editable-data class="activity">
        <span libEditableDataLabel></span>
        <input libEditableDataInput formControlName="activityComplement" [placeholder]="activityLabel" />
      </lib-editable-data>
    </div>
  </div>

  <div class="part workforce">
    <label class="title">Nombre de salariés</label>
    <lib-editable-data>
      <span libEditableDataLabel></span>
      <input libEditableDataInput formControlName="nbMember" />
    </lib-editable-data>
  </div>

  <div class="part member-part">
    <div class="sub-part">
      <label class="title">Membre CSE</label>
      <lib-radio-group formControlName="memberCSE">
        <lib-radio-input [value]="true" class="contrast"><label>Oui</label></lib-radio-input>
        <lib-radio-input [value]="false" class="contrast"><label>Non</label></lib-radio-input>
      </lib-radio-group>
    </div>
    <div class="sub-part">
      <label class="title">Membre CSSCT</label>
      <lib-radio-group formControlName="memberCSSCT">
        <lib-radio-input [value]="true" class="contrast"><label>Oui</label></lib-radio-input>
        <lib-radio-input [value]="false" class="contrast"><label>Non</label></lib-radio-input>
      </lib-radio-group>
    </div>
  </div>

  <div class="part referent" [formGroup]="referentForm">
    <h3 class="title">Référent prévention</h3>
    <div class="identity">
      <lib-select [options]="civilityOptions" formControlName="civility" class="contrast civility"></lib-select>
      <lib-editable-data class="lastname">
        <span libEditableDataLabel></span>
        <input libEditableDataInput formControlName="lastname" placeholder="Nom" />
      </lib-editable-data>
    </div>
    <div class="mobile">
      <lib-editable-data>
        <span libEditableDataLabel></span>
        <input libEditableDataInput formControlName="mobileNumber" placeholder="Téléphone mobile" />
      </lib-editable-data>
    </div>
    <div class="email">
      <lib-editable-data>
        <span libEditableDataLabel></span>
        <input libEditableDataInput formControlName="email" placeholder="email" />
      </lib-editable-data>
    </div>
  </div>

  <div class="part indicators">
    <h3 class="title">Indicateur de prévention</h3>
    <div class="risk-number">
      <label>N° de risque</label>
      <lib-editable-data>
        <span libEditableDataLabel></span>
        <input libEditableDataInput formControlName="riskNumber" />
      </lib-editable-data>
    </div>

    <ng-container formGroupName="indicators">
      <div class="indicator">
        <h4 class="title">Taux cotisation AT/MP</h4>
        <div class="region">
          <label>Entreprise</label>
          <lib-editable-data>
            <span libEditableDataLabel></span>
            <input libEditableDataInput formControlName="companyContributionRateAtMP" />
          </lib-editable-data>
        </div>
        <div class="region">
          <label>National</label>
          <lib-editable-data>
            <span libEditableDataLabel></span>
            <input libEditableDataInput formControlName="nationalContributionRateAtMP" />
          </lib-editable-data>
        </div>
      </div>
      <div class="indicator">
        <h4 class="title">Indice fréquence</h4>
        <div class="region">
          <label>Entreprise</label>
          <lib-editable-data>
            <span libEditableDataLabel></span>
            <input libEditableDataInput formControlName="companyFrequencyIndex" />
          </lib-editable-data>
        </div>
        <div class="region">
          <label>National</label>
          <lib-editable-data>
            <span libEditableDataLabel></span>
            <input libEditableDataInput formControlName="nationalFrequencyIndex" />
          </lib-editable-data>
        </div>
      </div>
      <div class="indicator">
        <h4 class="title">Taux de gravité</h4>
        <div class="region">
          <label>Entreprise</label>
          <lib-editable-data>
            <span libEditableDataLabel></span>
            <input libEditableDataInput formControlName="companyGravityRate" />
          </lib-editable-data>
        </div>
        <div class="region">
          <label>National</label>
          <lib-editable-data>
            <span libEditableDataLabel></span>
            <input libEditableDataInput formControlName="nationalGravityRate" />
          </lib-editable-data>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
