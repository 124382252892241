import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { HydratedMeasure, Measure, MEASURE_TYPE } from '../models';
import { getMeasureState } from '../reducers';
import { State } from '../reducers/measure.reducer';
import * as DangerSourceSelector from './danger-source.selectors';
import * as RiskCategorySelectors from './risk-category.selectors';
import * as RiskTypeSelectors from './risk-type.selectors';
import * as UnitOfProductionSelectors from './unit-of-production.selectors';
import * as UnitOfWorkDangerSourceSelectors from './unit-of-work-danger-source.selectors';
import * as UnitOfWorkSelectors from './unit-of-work.selectors';

const getEntitiesFromState = createSelector(getMeasureState, (state: State): Dictionary<Measure> => state.entities);
export const getAll = createSelector(getEntitiesFromState, entities =>
  Object.keys(entities)
    .map(id => entities[id])
    .sort((a, b) => (a.position < b.position ? -1 : 1))
);

export const getById = (measureId: number) => createSelector(getAll, measures => measures.find(({ id }) => id === measureId));

export const getHighlightedId = createSelector(getMeasureState, (state: State): number | null => state.highlighted);

// Preventive Measures
export const getAllPreventive = createSelector(getAll, entities => entities.filter(entity => entity.type === MEASURE_TYPE.PREVENTIVE));

export const getPreventiveBySelectedUnitOfWorkDangerSource = createSelector(
  getAllPreventive,
  UnitOfWorkDangerSourceSelectors.getSelected,
  (entities, unitOfWorkDangerSource) =>
    unitOfWorkDangerSource ? entities.filter(entity => entity.unitOfWorkDangerSourceId === unitOfWorkDangerSource.id) : []
);

export const getEnabledPreventiveByUnitOfWorkDangerSource = (unitOfWorkDangerSourceId: number) =>
  createSelector(getAllPreventive, entities =>
    entities.filter(entity => entity.unitOfWorkDangerSourceId === unitOfWorkDangerSourceId).filter(entity => entity.enabled)
  );

// Effective Measures
export const getAllEffective = createSelector(getAll, entities => entities.filter(entity => entity.type === MEASURE_TYPE.EFFECTIVE));

export const getEffectiveBySelectedUnitOfWorkDangerSource = createSelector(
  getAllEffective,
  UnitOfWorkDangerSourceSelectors.getSelected,
  (entities, unitOfWorkDangerSource) =>
    unitOfWorkDangerSource ? entities.filter(entity => entity.unitOfWorkDangerSourceId === unitOfWorkDangerSource.id) : []
);

export const getEnabledEffectiveByUnitOfWorkDangerSource = (unitOfWorkDangerSourceId: number) =>
  createSelector(getAllEffective, entities =>
    entities.filter(entity => entity.unitOfWorkDangerSourceId === unitOfWorkDangerSourceId).filter(entity => entity.enabled)
  );

const getDataForHydratation = createSelector(
  RiskCategorySelectors.getAll,
  RiskTypeSelectors.getAll,
  DangerSourceSelector.getAll,
  UnitOfWorkSelectors.getAllAvailableBySelectedUnitOfProduction,
  UnitOfWorkDangerSourceSelectors.getAll,
  (riskCategories, riskTypes, dangerSources, unitOfWorks, unitOfWorkDangerSources) => ({
    riskCategories,
    riskTypes,
    dangerSources,
    unitOfWorks,
    unitOfWorkDangerSources,
  })
);

const hydrate = (measures, { riskCategories, riskTypes, dangerSources, unitOfWorks, unitOfWorkDangerSources }): HydratedMeasure[] => {
  return measures
    .map(({ ...measure }) => ({
      measure,
      riskCategory: riskCategories.find(({ id }) => id === measure.riskCategoryId),
      riskType: riskTypes.find(({ id }) => id === measure.riskTypeId),
      unitOfWork: unitOfWorks.find(({ id }) => id === measure.unitOfWorkId),
      unitOfWorkDangerSource: unitOfWorkDangerSources.find(({ id }) => id === measure.unitOfWorkDangerSourceId),
      dangerSource: dangerSources.find(({ id }) => id === measure.dangerSourceId),
    }))
    .filter(({ riskCategory, riskType, unitOfWork, unitOfWorkDangerSource, dangerSource }) => {
      return riskCategory && riskType && unitOfWork && unitOfWorkDangerSource && dangerSource;
    });
};

// Top measures
export const getAllTop = createSelector(getAll, measures => measures.filter(({ top }) => top));
export const getEnabledTop = createSelector(getAllTop, measures => measures.filter(({ enabled }) => enabled));

export const getTopBySelectedUnitOfProduction = createSelector(
  getEnabledTop,
  UnitOfProductionSelectors.getSelected,
  (measures, unitOfProduction) =>
    measures
      .filter(({ unitOfProductionId }) => unitOfProductionId === unitOfProduction.id)
      .sort((a, b) => (a.topPosition < b.topPosition ? -1 : 1))
);

export const getHydratedTopBySelectedUnitOfProduction = createSelector(getTopBySelectedUnitOfProduction, getDataForHydratation, hydrate);

export const getAllBySelectedUnitOfProduction = createSelector(
  getAll,
  UnitOfProductionSelectors.getSelected,
  (measures, unitOfProduction) =>
    measures
      .filter(({ unitOfProductionId }) => unitOfProductionId === unitOfProduction.id)
      .filter(({ enabled }) => enabled)
      .sort((a, b) => (a.predictedDate < b.predictedDate ? -1 : 1))
      .sort(a => (a.predictedDate ? -1 : 1))
);

export const getHydratedBySelectedUnitOfProduction = createSelector(getAllBySelectedUnitOfProduction, getDataForHydratation, hydrate);

const sortMeasures = (a, b) => {
  // sort by top measure
  if (a.measure.top !== b.measure.top) {
    return Number(b.measure.top) - Number(a.measure.top);
  }

  // if the two measure are top, sort by top position
  if (a.measure.top) {
    return a.measure.topPosition - b.measure.topPosition;
  }

  // then sort by predicted date
  if (a.measure.predictedDate !== null && b.measure.predictedDate !== null) {
    return a.measure.predictedDate.getTime() - b.measure.predictedDate.getTime();
  }

  // put those with a date first
  if (a.measure.predictedDate !== null || b.measure.predictedDate !== null) {
    return Number(b.measure.predictedDate) - Number(a.measure.predictedDate);
  }

  // then sort by riskCategory position
  if (!a.riskCategory || !b.riskCategory) {
    return 0;
  }

  if (a.riskCategory.position !== b.riskCategory.position) {
    return a.riskCategory.position - b.riskCategory.position;
  }

  // then sort by riskType position
  if (!a.riskType || !b.riskType) {
    return 0;
  }

  if (a.riskType.position !== b.riskType.position) {
    return a.riskType.position - b.riskType.position;
  }

  // then sort by dangerSource position
  if (!a.dangerSource || !b.dangerSource) {
    return 0;
  }

  if (a.dangerSource.position !== b.dangerSource.position) {
    return a.dangerSource.position - b.dangerSource.position;
  }

  // then sort by unitOfWork position
  if (!a.unitOfWork || !b.unitOfWork) {
    return 0;
  }

  if (a.unitOfWork.position !== b.unitOfWork.position) {
    return a.unitOfWork.position - b.unitOfWork.position;
  }

  // finally sort by measure position
  return a.measure.position - b.measure.position;
};

export const getHydratedAndSortedBySelectedUnitOfProduction = createSelector(getHydratedBySelectedUnitOfProduction, measures =>
  measures.sort(sortMeasures)
);
