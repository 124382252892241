import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { DangerSourceImageActions } from '../../../store/actions';
import { DangerSource, DangerSourceImage } from '../../../store/models';
import { AppState } from '../../../store/reducers';
import { DangerSourceImageSelectors } from '../../../store/selectors';

@Component({
  selector: 'lib-picture-list-modal',
  templateUrl: './picture-list-modal.component.html',
  styleUrls: ['./picture-list-modal.component.scss'],
})
export class DangerSourcePictureListModalComponent implements OnInit, OnDestroy {
  images$: Observable<DangerSourceImage[]>;
  loading$: Observable<boolean>;

  private _destroy$: Subject<boolean> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dangerSource: DangerSource },
    private _dialogRef: MatDialogRef<DangerSourcePictureListModalComponent>,
    private _store: Store<AppState>
  ) {}

  close() {
    this._dialogRef.close();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this._dialogRef.addPanelClass('alternative');
    const dangerSourceId = this.data.dangerSource.id;
    this.images$ = this._store.pipe(select(DangerSourceImageSelectors.getAllByDangerSource(dangerSourceId)));
    this.loading$ = this._store.pipe(select(DangerSourceImageSelectors.isLoading));
    this._store.dispatch(DangerSourceImageActions.load({ dangerSourceId }));
  }
}
