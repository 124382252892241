import { Directive, HostBinding, HostListener } from '@angular/core';

import { textFormatter } from '../../../helpers/text.helper';
import { EditableDataBaseDirective } from './_editable-data-base.directive';

@Directive({
  selector: '[libEditableDataLabel]',
})
export class EditableDataLabelDirective extends EditableDataBaseDirective<HTMLElement> {
  @HostBinding('class') className = 'editable-data-label';
  @HostListener('click') onClick = () => this.event$.emit('click');

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this._domEl.innerHTML = textFormatter(this._value);
  }
}
