import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { RiskCategoryService } from '../../services/models/risk-category.service';
import { RiskCategoryActions, RiskCategoryApiActions, RiskTypeActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { AppState } from '../reducers';
import { UnitOfProductionSelectors } from '../selectors';

@Injectable()
export class RiskCategoryEffects {
  loadByUnitOfProduction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoryActions.load),
      withLatestFrom(this._store.pipe(select(UnitOfProductionSelectors.getSelected))),
      switchMap(([{ loadTree }, unitOfProduction]) =>
        unitOfProduction
          ? this._srv.getAllByUnitOfProduction(unitOfProduction.id).pipe(
              mergeMap(riskCategories => [
                RiskCategoryApiActions.loadSuccess({ riskCategories }),
                ...riskCategories.map(({ id }) => RiskTypeActions.loadByRiskCategory({ riskCategoryId: id, loadTree })),
              ]),
              catchError(() =>
                of(true).pipe(
                  EffectHelper.failure(RiskCategoryApiActions.loadFailure(), 'Erreur lors de la récupération des catégories de risque')
                )
              )
            )
          : of(RiskCategoryApiActions.loadFailure())
      )
    )
  );

  constructor(private actions$: Actions, private _srv: RiskCategoryService, private _store: Store<AppState>) {}
}
