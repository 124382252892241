import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { DangerSourcePictureListModalComponent } from '../../../containers/danger-source/picture-list-modal/picture-list-modal.component';
import { DangerSource } from '../../../store/models';
import { DangerSourceCommentComponent } from '../comment/comment.component';

@Component({
  selector: 'lib-danger-source-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class DangerSourceItemComponent implements OnInit, OnDestroy {
  @Input() dangerSource: DangerSource;
  @Input() dangerSourceItemUrlMask: string[];
  @Output() deleteRequest: EventEmitter<null> = new EventEmitter();
  @Output() duplicateRequest: EventEmitter<null> = new EventEmitter();
  form: UntypedFormGroup;
  @Input() trackingMode: boolean;
  @Output() update: EventEmitter<Partial<DangerSource>> = new EventEmitter();
  private _destroy$: Subject<boolean> = new Subject();

  get hasComment(): boolean {
    return this.dangerSource.comment && this.dangerSource.comment !== '';
  }

  constructor(private _fb: UntypedFormBuilder, private _dialog: MatDialog) {}

  delete(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.deleteRequest.emit();
  }

  duplicate(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.duplicateRequest.emit();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this.form = this._fb.group(
      {
        enabled: [this.dangerSource.enabled],
        name: [this.dangerSource.name, Validators.required],
      },
      { updateOn: 'blur' }
    );

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.form.valid),
        tap(values => this.update.emit(values))
      )
      .subscribe();
  }

  showComment(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    const ref = this._dialog.open(DangerSourceCommentComponent, {
      data: {
        dangerSource: this.dangerSource,
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(response => response !== undefined),
        tap(values => this.update.emit(values))
      )
      .subscribe();
  }

  showPictures(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    this._dialog.open(DangerSourcePictureListModalComponent, {
      data: {
        dangerSource: this.dangerSource,
      },
    });
  }

  switchPainfullness(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.update.emit({ painfullness: !this.dangerSource.painfullness });
  }
}
