<span class="close" (click)="close()"></span>

<div class="title">Informations</div>

<ng-container [formGroup]="form">
  <div class="field unit-of-work">
    <div class="label">Unité de travail concernée</div>
    <div class="value" *ngIf="selectedUnitOfWork" [textFormat]="selectedUnitOfWork.name"></div>
  </div>

  <div class="field name">
    <div class="label">Mesure</div>
    <div class="value">
      <lib-editable-data class="measure-name">
        <span libEditableDataLabel></span>
        <textarea libEditableDataInput placeholder="--" formControlName="name" tabindex="-1"></textarea>
      </lib-editable-data>
    </div>
  </div>

  <div class="other-fields">
    <div class="field">
      <div class="label">Coût</div>
      <lib-editable-data class="amount" suffix="€">
        <span libEditableDataLabel></span>
        <input libEditableDataInput placeholder="--" formControlName="amount" />
      </lib-editable-data>
    </div>
    <div class="field">
      <div class="label">Resp.</div>
      <lib-editable-data class="responsible">
        <span libEditableDataLabel></span>
        <input libEditableDataInput placeholder="--" formControlName="inCharge" maxlength="5" />
      </lib-editable-data>
    </div>
    <div class="field">
      <div class="label">Date de réalisation</div>
      <lib-editable-data class="date">
        <span libEditableDataLabel></span>
        <input
          libEditableDataInput
          libDateAutoFormatter
          placeholder="jj/mm/aaaa"
          formControlName="completionDate"
          [matDatepicker]="picker"
          (focus)="picker.open()"
        />
        <mat-datepicker #picker></mat-datepicker>
      </lib-editable-data>
    </div>
  </div>
</ng-container>

<div class="action-wrapper">
  <button class="button strong" (click)="submit()" [disabled]="!form.valid">Valider</button>
</div>
