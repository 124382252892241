import {
  authenticationGuard,
  companyResolver,
  dangerSourceResolver,
  hydratedMeasureDeactivationGuard,
  riskTypeResolver,
  unitOfProductionResolver,
} from '@aequalib';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { LegalComponent } from './components/legal/legal.component';
import { TopMeasureComponent } from './components/top-measure/top-measure.component';
import { UnitOfProductionPictureGalleryPageComponent } from './components/unit-of-production/picture-gallery/picture-gallery.component';
import { ForgotPasswordComponent } from './containers/anonymous/forgot-password/forgot-password.component';
import { AnonymousLayoutComponent } from './containers/anonymous/layout/layout.component';
import { LoginComponent } from './containers/anonymous/login/login.component';
import { ResetPasswordComponent } from './containers/anonymous/reset-password/reset-password.component';
import { ContactComponent } from './containers/contact/contact.component';
import { DangerSourceEditComponent } from './containers/danger-source/edit/edit.component';
import { DangerSourceListComponent } from './containers/danger-source/list/list.component';
import { DefaultComponent } from './containers/default/default.component';
import { DuerChangeContractComponent } from './containers/duer/change-contract/change-contract.component';
import { DuerLayoutComponent } from './containers/duer/layout/layout.component';
import { HistoryComponent } from './containers/history/history.component';
import { HomepageComponent } from './containers/homepage/homepage.component';
import { PlanningComponent } from './containers/planning/planning.component';
import { ProductComponent } from './containers/product/product.component';
import { RefRiskTypeListComponent } from './containers/ref-risk-type/list/list.component';
import { RiskListComponent } from './containers/risk/list/list.component';
import { UnitOfProductionDocumentComponent } from './containers/unit-of-production/document/document.component';
import { UnitOfProductionItemComponent } from './containers/unit-of-production/item/item.component';
import { UnitOfProductionListComponent } from './containers/unit-of-production/list/list.component';
import { contractResolver } from './resolvers/contract.resolver';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        canActivate: [authenticationGuard('/login')],
        resolve: [contractResolver],
        children: [
          {
            path: '',
            component: HomepageComponent,
          },
          {
            path: 'mentions-legales',
            component: LegalComponent,
          },
          {
            path: 'detail-des-risques',
            component: RefRiskTypeListComponent,
          },
          {
            path: 'contact',
            component: ContactComponent,
          },
          {
            path: 'produit/:productId',
            component: ProductComponent,
          },
          {
            path: 'mon-duer',
            component: DuerLayoutComponent,
            resolve: {
              isCompanyLoaded: companyResolver,
            },
            children: [
              {
                path: '',
                component: UnitOfProductionListComponent,
              },
              {
                path: 'unite-de-production/:unitOfProductionId',
                component: UnitOfProductionItemComponent,
                resolve: {
                  isUnitOfProductionSelected: unitOfProductionResolver,
                },
                children: [
                  {
                    path: 'risques',
                    children: [
                      {
                        path: '',
                        component: RiskListComponent,
                      },
                      {
                        path: ':riskTypeId',
                        resolve: {
                          isRiskTypeSelected: riskTypeResolver,
                        },
                        children: [
                          {
                            path: 'sources-de-danger',
                            children: [
                              {
                                path: '',
                                component: DangerSourceListComponent,
                              },
                              {
                                path: ':dangerSourceId',
                                component: DangerSourceEditComponent,
                                resolve: {
                                  isDangerSourceSelected: dangerSourceResolver,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'top',
                    component: TopMeasureComponent,
                    canDeactivate: [hydratedMeasureDeactivationGuard],
                  },
                  {
                    path: 'planification',
                    component: PlanningComponent,
                    canDeactivate: [hydratedMeasureDeactivationGuard],
                  },
                  {
                    path: 'galerie-photo',
                    component: UnitOfProductionPictureGalleryPageComponent,
                  },
                  {
                    path: 'documents',
                    component: UnitOfProductionDocumentComponent,
                  },
                  {
                    path: 'historique',
                    component: HistoryComponent,
                  },
                ],
              },
            ],
          },
          {
            path: 'change-contract',
            component: DuerChangeContractComponent,
          },
          {
            path: 'static/:code',
            component: DefaultComponent,
          },
        ],
      },
      {
        path: '',
        component: AnonymousLayoutComponent,
        children: [
          {
            path: 'login',
            component: LoginComponent,
          },
          {
            path: 'mot-de-passe-oublie',
            component: ForgotPasswordComponent,
          },
          {
            path: 'reinit-mot-de-passe/:token',
            component: ResetPasswordComponent,
          },
        ],
      },
    ],
  },
  {
    // redirection from shared library (error with jwt authentification)
    path: 'unauthorized',
    redirectTo: 'login',
  },
  {
    // redirection from shared library (error with jwt authentification)
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
