<div class="title">Selectionner le contrat sur lequel dupliquer cette UP</div>

<div *ngIf="contracts$ | async as contracts" [formGroup]="form" class="content">
  <lib-select [options]="options" formControlName="contract"></lib-select>
</div>

<div class="action-wrapper">
  <span (click)="cancel()" class="button shadow">Annuler</span>
  <span (click)="confirm()" class="button" [ngClass]="{ disabled: !form.valid }">Valider</span>
</div>
