import { AfterViewInit, Component, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { LayoutService } from '../../../services/layout.service';

@Component({
  selector: 'app-duer-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class DuerHeaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild('duerAction', { read: ViewContainerRef }) duerActionWrapperRef: ViewContainerRef;
  @ViewChild('duerTitle', { read: ViewContainerRef }) duerTitleWrapperRef: ViewContainerRef;
  private _destroy$: Subject<boolean> = new Subject();

  constructor(private _srv: LayoutService) {}

  back(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    window.history.back();
  }

  ngAfterViewInit(): void {
    this._srv.duerTitle$
      .pipe(
        takeUntil(this._destroy$),
        tap(tplRef => {
          if (tplRef === null) {
            this.duerTitleWrapperRef.clear();
          } else {
            this.duerTitleWrapperRef.createEmbeddedView(tplRef);
          }
        })
      )
      .subscribe();

    this._srv.duerAction$
      .pipe(
        takeUntil(this._destroy$),
        tap(tplRef => {
          if (tplRef === null) {
            this.duerActionWrapperRef.clear();
          } else {
            this.duerActionWrapperRef.createEmbeddedView(tplRef);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }
}
