<h1>Mentions Légales Aequalis prévention</h1>

<div class="title">Ce site est édité par :</div>
<p>
  Aequalis-Prévention - 3 Place Meissonnier - 69001 LYON
  <br />
  Société enregistrée sous le numéro RCS LYON B 530 719 632 - SIRET : 530 719 632 00016
  <br />
  SARL au capital de 10.000 EURO
  <br />
  Conception : PrestaConcept -
  <a href="http://www.prestaconcept.net" target="_blank" rel="noopener noreferrer">www.prestaconcept.net</a>
</p>

<p>
  Conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, vous disposez d'un droit d'accès,
  de modifications, de rectifications et de suppression des données qui vous concernent.
  <br />
  Pour l'exercer, adressez-vous par courrier à :
  <br />
  Aequalis Prévention, 3 Place Meissonnier 69001 LYON, ou par mail à l'adresse
  <a href="mailto:contact@aequalis-prevention.com">contact@aequalis-prevention.com</a>
</p>

<div class="title">Marques et propriété intellectuelle :</div>
<p>
  En application de la loi du 11 mars 1957 (art. 41) et du code de la propriété intellectuelle du 1er juillet 1992, toute reproduction
  partielle ou totale à usage collectif est strictement interdite sans autorisation.
  <br />
  Les logos, visuels et marques présents sur ce site sont la propriété de leurs détenteurs respectifs.
</p>
