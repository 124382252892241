import { Directive, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { LayoutService } from '../services/layout.service';

@Directive({
  selector: '[appDuerAction]',
})
export class DuerActionDirective implements OnInit, OnDestroy {
  constructor(private templateRef: TemplateRef<any>, private _srv: LayoutService) {}

  ngOnDestroy() {
    this._srv.duerAction = null;
  }

  ngOnInit() {
    this._srv.duerAction = this.templateRef;
  }
}
