import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RiskCategory, RiskCategoryApi, RiskCategoryListApi } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class RiskCategoryService {
  constructor(private _http: HttpClient) {}

  getAllByUnitOfProduction(unitOfProductionId: number): Observable<RiskCategory[]> {
    return this._http
      .get<RiskCategoryListApi>('risk-categories', {
        params: {
          unitOfProductionId: unitOfProductionId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(result => result.items.map(item => this._transformer(item, unitOfProductionId))));
  }

  private _transformer(itemApi: RiskCategoryApi, unitOfProductionId: number): RiskCategory {
    return {
      ...itemApi,
      unitOfProductionId,
    };
  }
}
