import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-radio-input',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class FormRadioComponent implements AfterViewInit {
  @Input() formControlName: string;
  @ViewChild('formField') formField: ElementRef;
  @Input() value: any;
  private _focus = false;
  private _formControlValue: any;

  get checked() {
    return this._formControlValue === this.value;
  }

  @HostBinding('class')
  get className() {
    const classList = ['lib-radio-input', 'form-field'];

    if (this.checked) {
      classList.push('checked');
    }

    if (this._focus) {
      classList.push('focus');
    }

    return classList.join(' ');
  }

  constructor(@Optional() private _group: FormRadioGroupComponent) {}

  ngAfterViewInit() {
    const formField = this.formField.nativeElement as HTMLInputElement;
    formField.addEventListener('keypress', (e: KeyboardEvent) => {
      if (e.shiftKey || e.ctrlKey) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      this._keyboardAction(e.code);
    });

    formField.addEventListener('focus', () => (this._focus = true));
    formField.addEventListener('blur', () => (this._focus = false));
  }

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this._toggle();
  }

  propagateChange = (_: any) => {};
  propagateTouched = () => {};

  writeValue(value: any) {
    if (value !== undefined) {
      this._formControlValue = value;
    }
  }

  private _keyboardAction(key: string) {
    if (key === 'Space') {
      this._toggle();
    }
  }

  private _toggle() {
    if (this._formControlValue !== this.value) {
      this._group.update(this.value);
    }
  }
}

let uniqId = 0;
@Component({
  selector: 'lib-radio-group',
  template: '<ng-content></ng-content>',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRadioGroupComponent),
      multi: true,
    },
  ],
})
export class FormRadioGroupComponent implements ControlValueAccessor, AfterContentInit {
  @HostBinding('class') className = 'lib-radio-group form-field';
  @Input() formControlName: string;
  @ContentChildren(FormRadioComponent) private _controls: QueryList<FormRadioComponent>;
  private _formControlValue: any;
  private _name = `radio-group-${uniqId++}`;

  @Input()
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }

  @Input()
  get value() {
    return this._formControlValue;
  }

  ngAfterContentInit() {
    this._propagateToControls();
  }

  propagateChange = (_: any) => {};
  propagateTouched = () => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouched = fn;
  }

  update(value) {
    this._formControlValue = value;

    this.propagateChange(this._formControlValue);
    this.propagateTouched();
    this._propagateToControls();
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this._formControlValue = value;
      this._propagateToControls();
    }
  }

  private _propagateToControls() {
    if (this._controls) {
      this._controls.forEach(control => control.writeValue(this._formControlValue));
    }
  }
}
