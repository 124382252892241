import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthService, PFW_SECURITY_API_PROVIDERS, PFW_SECURITY_LOCAL_TOKEN_STORAGE } from '@prestaconcept/security';
import * as Sentry from '@sentry/angular-ivy';
import { ConfirmModalComponent } from './components/actions/confirm/confirm.component';
import { DangerSourceCommentComponent } from './components/danger-source/comment/comment.component';
import { DangerSourceCreateComponent } from './components/danger-source/create/create.component';
import { DangerSourceItemComponent } from './components/danger-source/item/item.component';
import { DangerSourcePictureComponent } from './components/danger-source/picture/picture.component';
import { UnitOfWorkQuotationComponent } from './components/danger-source/unit-of-work-quotation/unit-of-work-quotation.component';
import { DangerSourceUnitOfWorkComponent } from './components/danger-source/unit-of-work/unit-of-work.component';
import { FormCheckboxComponent, FormCheckboxGroupComponent } from './components/form/checkbox/checkbox.component';
import { FormImageComponent } from './components/form/image/image.component';
import { FormRadioComponent, FormRadioGroupComponent } from './components/form/radio/radio.component';
import { FormSelectComponent } from './components/form/select/select.component';
import { SignatureComponent } from './components/form/signature/signature.component';
import { LineItemComponent } from './components/line-item/line-item.component';
import { MeasureCreateComponent } from './components/measure/create/create.component';
import { MessageComponent } from './components/message/message.component';
import { RiskTypeItemComponent } from './components/risk-type/item/item.component';
import { TextFormatComponent } from './components/text-format/text-format.component';
import { HydratedMeasureItemComponent } from './components/top-measure/item/item.component';
import { ExportModalComponent } from './components/unit-of-production/export-modal/export-modal.component';
import { UnitOfWorkItemComponent } from './components/unit-of-work/item/item.component';
import { ContractListComponent } from './containers/contract/list/list.component';
import { DangerSourceListComponent } from './containers/danger-source/list/list.component';
import { DangerSourcePictureListModalComponent } from './containers/danger-source/picture-list-modal/picture-list-modal.component';
import { DangerSourcePictureListComponent } from './containers/danger-source/picture-list/picture-list.component';
import { DangerSourceSelectorComponent } from './containers/danger-source/selector/selector.component';
import { RiskCategoryItemComponent } from './containers/risk-category/item/item.component';
import { RiskCategoryListComponent } from './containers/risk-category/list/list.component';
import { HydratedMeasureListComponent } from './containers/top-measure/list/list.component';
import { UnitOfProductionItemComponent } from './containers/unit-of-production/item/item.component';
import { UnitOfProductionListComponent } from './containers/unit-of-production/list/list.component';
import { UnitOfProductionPictureGalleryComponent } from './containers/unit-of-production/picture-gallery/picture-gallery.component';
import { UnitOfProductionProfileComponent } from './containers/unit-of-production/profile/profile.component';
import { UnitOfWorkListComponent } from './containers/unit-of-work/list/list.component';
import { DateAutoFormatterDirective } from './directives/date-auto-formatter.directive';
import { RiskAssessmentExportDirective } from './directives/risk-assessment-export.directive';
import { UrlMaskPipe } from './pipes/url-mask.pipe';
import { AuthenticationService } from './services/authentication.service';
import { SharedLibRoutingModule } from './shared-routing.module';
import { EFFECT_SERVICES } from './store/effects';
import { appReducer } from './store/reducers';
import { DragndropModule } from './submodules/dragndrop/dragndrop.module';
import { DropdownModule } from './submodules/dropdown/dropdown.module';
import { EditableDataModule } from './submodules/editable-data/editable-data.module';
import { MessengerModule } from './submodules/messenger/messenger.module';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    UnitOfProductionListComponent,
    UnitOfProductionItemComponent,
    UnitOfWorkListComponent,
    UnitOfWorkItemComponent,
    RiskCategoryListComponent,
    RiskCategoryItemComponent,
    ConfirmModalComponent,
    FormSelectComponent,
    FormRadioGroupComponent,
    FormRadioComponent,
    FormCheckboxGroupComponent,
    FormCheckboxComponent,
    FormImageComponent,
    RiskTypeItemComponent,
    LineItemComponent,
    MessageComponent,
    DangerSourceListComponent,
    DangerSourceItemComponent,
    DangerSourceCommentComponent,
    DangerSourcePictureListModalComponent,
    DangerSourcePictureListComponent,
    DangerSourcePictureComponent,
    UnitOfWorkQuotationComponent,
    DangerSourceUnitOfWorkComponent,
    ContractListComponent,
    UnitOfProductionProfileComponent,
    UnitOfProductionPictureGalleryComponent,
    DangerSourceCreateComponent,
    DangerSourceSelectorComponent,
    MeasureCreateComponent,
    HydratedMeasureListComponent,
    HydratedMeasureItemComponent,
    TextFormatComponent,
    UrlMaskPipe,
    DateAutoFormatterDirective,
    ExportModalComponent,
    RiskAssessmentExportDirective,
    SignatureComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatLuxonDateModule,
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot(EFFECT_SERVICES),
    SharedLibRoutingModule,
    DragndropModule,
    EditableDataModule,
    DropdownModule,
    MessengerModule,
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatLuxonDateModule,
    DragndropModule,
    EditableDataModule,
    DropdownModule,
    MessengerModule,
    LineItemComponent,
    MessageComponent,
    UnitOfProductionListComponent,
    UnitOfProductionPictureGalleryComponent,
    RiskCategoryListComponent,
    DangerSourceListComponent,
    ConfirmModalComponent,
    FormSelectComponent,
    FormRadioGroupComponent,
    FormRadioComponent,
    FormCheckboxGroupComponent,
    FormCheckboxComponent,
    UnitOfWorkQuotationComponent,
    DangerSourceUnitOfWorkComponent,
    MeasureCreateComponent,
    HydratedMeasureListComponent,
    DateAutoFormatterDirective,
    TextFormatComponent,
    RiskAssessmentExportDirective,
  ],
  providers: [
    {
      provide: AuthService,
      useExisting: AuthenticationService,
    },
    PFW_SECURITY_LOCAL_TOKEN_STORAGE,
    ...PFW_SECURITY_API_PROVIDERS,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    { provide: LOCALE_ID, useValue: 'fr' },
  ],
})
export class SharedModule {
  constructor(@Optional() @SkipSelf() parentModule: StoreModule) {
    if (parentModule) {
      throw new Error('SharedModule is already loaded. Import it in the AppModule only');
    }
  }
}
