export interface LoadingState {
  loading: boolean;
  loaded: boolean;
}

export class LoadingStateHelper {
  static fail(): LoadingState {
    return {
      loading: false,
      loaded: false,
    };
  }

  static initial(): LoadingState {
    return {
      loading: false,
      loaded: false,
    };
  }

  static start(): Partial<LoadingState> {
    return {
      loading: true,
    };
  }

  static success(): LoadingState {
    return {
      loading: false,
      loaded: true,
    };
  }
}
