import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DropZoneComponent } from './components/drop-zone/drop-zone.component';
import { DragItemDirective } from './directives/drag-item.directive';
import { ShadowItemDirective } from './directives/shadow-item.directive';

@NgModule({
  declarations: [DropZoneComponent, DragItemDirective, ShadowItemDirective],
  imports: [CommonModule],
  exports: [DropZoneComponent, DragItemDirective, ShadowItemDirective],
})
export class DragndropModule {}
