import { Page, PageService } from '@aequalib';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit, OnDestroy {
  data: Page;
  private _destroy$ = new Subject<void>();

  constructor(private _route: ActivatedRoute, private _router: Router, private _srv: PageService) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit(): void {
    this._route.params
      .pipe(
        takeUntil(this._destroy$),
        map(({ code }) => code),
        switchMap(code => this._srv.getByCode(code)),
        map(data => (this.data = data)),
        catchError(() => this._router.navigateByUrl('/'))
      )
      .subscribe();
  }
}
