import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { UnitOfWorkDangerSourceService } from '../../services/models/unit-of-work-danger-source.service';
import { MeasureActions, UnitOfWorkDangerSourceActions, UnitOfWorkDangerSourceApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { AppState } from '../reducers';
import { DangerSourceSelectors, UnitOfWorkDangerSourceSelectors } from '../selectors';

@Injectable()
export class UnitOfWorkDangerSourceEffects {
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkDangerSourceActions.create),
      withLatestFrom(this._store.pipe(select(DangerSourceSelectors.getSelected))),
      mergeMap(([{ unitOfWorkId }, dangerSource]) =>
        this._srv.create(unitOfWorkId, dangerSource.id).pipe(
          mergeMap(({ unitOfWorkDangerSource, measures }) => [
            UnitOfWorkDangerSourceApiActions.createSuccess({ unitOfWorkDangerSource }),
            MeasureActions.load({ measures }),
          ]),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(UnitOfWorkDangerSourceApiActions.createFailure(), `Erreur lors de la création de l'unité de travail`)
            )
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkDangerSourceActions.remove),
      mergeMap(({ id }) =>
        this._srv.delete(id).pipe(
          mergeMap(() => [UnitOfWorkDangerSourceApiActions.removeSuccess({ id }), MeasureActions.removeByUnitOfWorkDangerSource({ id })]),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(UnitOfWorkDangerSourceApiActions.removeFailure(), `Erreur lors de la suppression de l'unité de travail`)
            )
          )
        )
      )
    )
  );

  duplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkDangerSourceActions.duplicate),
      withLatestFrom(this._store.pipe(select(UnitOfWorkDangerSourceSelectors.getSelected))),
      mergeMap(([{ unitOfWorkDangerSource }, { id }]) =>
        this._srv.duplicate(id, unitOfWorkDangerSource.unitOfWorkId).pipe(
          mergeMap(result => [
            UnitOfWorkDangerSourceApiActions.removeSuccess({ id: unitOfWorkDangerSource.id }),
            UnitOfWorkDangerSourceApiActions.createSuccess({
              unitOfWorkDangerSource: result.unitOfWorkDangerSource,
            }),
            MeasureActions.load({ measures: result.measures }),
          ]),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(
                UnitOfWorkDangerSourceApiActions.duplicateFailure(),
                `Erreur lors de la duplication de l'unité de travail`
              )
            )
          )
        )
      )
    )
  );

  duplicateToAnotherDangerSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkDangerSourceActions.duplicateToAnotherDangerSource),
      mergeMap(({ unitOfWorkDangerSource, dangerSourceId, unitOfWorkId }) => {
        const { id } = unitOfWorkDangerSource;
        return of({ id, unitOfWorkId, dangerSourceId }).pipe(
          withLatestFrom(this._store.pipe(select(UnitOfWorkDangerSourceSelectors.getAllByDangerSource(dangerSourceId))))
        );
      }),
      mergeMap(([{ id, unitOfWorkId, dangerSourceId }, unitOfWorkDangerSources]) =>
        this._srv.duplicate(id, unitOfWorkId, dangerSourceId).pipe(
          mergeMap(({ unitOfWorkDangerSource, measures }) => {
            const exists = unitOfWorkDangerSources.find(current => current.unitOfWorkId === unitOfWorkId);
            return [
              ...(exists ? [UnitOfWorkDangerSourceApiActions.removeSuccess({ id: exists.id })] : []),
              UnitOfWorkDangerSourceApiActions.createSuccess({ unitOfWorkDangerSource }),
              MeasureActions.load({ measures }),
            ];
          }),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(
                UnitOfWorkDangerSourceApiActions.duplicateFailure(),
                `Erreur lors de la duplication de l'unité de travail`
              )
            )
          )
        )
      )
    )
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkDangerSourceActions.load),
      withLatestFrom(this._store.pipe(select(DangerSourceSelectors.getSelected))),
      switchMap(([, { id }]) => this._load(id))
    )
  );

  loadByDangerSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkDangerSourceActions.loadByDangerSource),
      mergeMap(({ dangerSourceId }) => this._load(dangerSourceId))
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfWorkDangerSourceActions.update),
      mergeMap(({ id, values }) =>
        this._srv.update(id, values).pipe(
          mergeMap(({ unitOfWorkDangerSource, measures }) => [
            UnitOfWorkDangerSourceApiActions.updateSuccess({ unitOfWorkDangerSource }),
            MeasureActions.load({ measures }),
          ]),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(UnitOfWorkDangerSourceApiActions.updateFailure(), `Erreur lors de la sauvegarde de l'unité de travail`)
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: UnitOfWorkDangerSourceService, private _store: Store<AppState>) {}

  private _load(dangerSourceId) {
    return this._srv.getAllByDangerSource(dangerSourceId).pipe(
      mergeMap(data => {
        const unitOfWorkDangerSources = data.map(entry => entry.unitOfWorkDangerSource);
        const measures = data.map(entry => entry.measures).reduce((acc, val) => acc.concat(val), []);
        return [UnitOfWorkDangerSourceApiActions.loadSuccess({ unitOfWorkDangerSources }), MeasureActions.load({ measures })];
      }),
      catchError(() =>
        of(true).pipe(
          EffectHelper.failure(UnitOfWorkDangerSourceApiActions.loadFailure(), 'Erreur lors de la récupération des unités de travail')
        )
      )
    );
  }
}
