import { createAction, props } from '@ngrx/store';
import { UnitOfWork } from '../models';

export const load = createAction('[UnitOfWork] Load Units of work');
export const loadByUnitOfProduction = createAction(
  '[UnitOfWork] Load Units of work by Unit of Production',
  props<{ unitOfProductionId: number }>()
);
export const update = createAction('[UnitOfWork] Update Unit of work', props<{ id: number; values: Partial<UnitOfWork> }>());
export const create = createAction('[UnitOfWork] Create Unit of work', props<{ unitOfProductionId: number; name: string }>());
export const remove = createAction('[UnitOfWork] Delete Unit of work', props<{ id: number }>());
export const clean = createAction('[UnitOfWork] Clean store of Unit of work');
export const cleanOne = createAction('[UnitOfWork] Remove one Unit of work from store', props<{ id: number }>());
export const move = createAction('[UnitOfWork] Move Unit of work', props<{ id: number; unitOfProductionId: number; position: number }>());
