import { AfterViewInit, Component, ElementRef, forwardRef, HostBinding, HostListener, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LibSelectOption } from '../../../interfaces/select';

@Component({
  selector: 'lib-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectComponent),
      multi: true,
    },
  ],
})
export class FormSelectComponent implements ControlValueAccessor, AfterViewInit {
  @Input() allowEmpty = false;
  @ViewChild('formField') formField: ElementRef;
  @Input() options: LibSelectOption[];
  @Input() placeholder = '...';
  private _focus = false;
  private _open = false;

  private _value: any;

  @HostBinding('class')
  get className() {
    const classList = ['lib-select', 'form-field'];

    if (this._open) {
      classList.push('open');
    }

    if (this._focus) {
      classList.push('focus');
    }

    if (this.selectedLabel === this.placeholder) {
      classList.push('valueless');
    }

    return classList.join(' ');
  }

  get value() {
    return this._value;
  }

  get selectedLabel(): string {
    const selectedOption = this.options.find(option => option.value === this.value);

    return selectedOption ? selectedOption.label : this.placeholder;
  }

  getOptionClassList(option: LibSelectOption) {
    const value = option ? option.value : null;

    return this.value === value ? 'selected' : '';
  }

  ngAfterViewInit() {
    const formField = this.formField.nativeElement as HTMLInputElement;
    formField.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.shiftKey || e.ctrlKey) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      this._keyboardAction(e.code);
    });

    formField.addEventListener('focus', () => (this._focus = true));
    formField.addEventListener('blur', () => (this._focus = false));
  }

  onSelect(option: LibSelectOption, e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    const value = option ? option.value : null;
    if (this._value !== value) {
      this._value = value;
      this.propagateChange(this.value);
      this.propagateTouched();
    }

    this._open = false;
  }

  @HostListener('click', ['$event'])
  onToggle(e) {
    e.preventDefault();
    e.stopPropagation();

    this._open = !this._open;
  }

  propagateChange = (_: any) => {};
  propagateTouched = () => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouched = fn;
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this._value = value;
    }
  }

  private _keyboardAction(key: string) {
    if (key === 'Space') {
      this._open = true;
    }
    if (key === 'Escape') {
      this._open = false;
    }
  }
}
