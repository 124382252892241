import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { Message, MessageTypeEnum } from '../../store/model';

@Component({
  selector: 'lib-messenger-message-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class MessageItemComponent {
  @Input() message: Message;
  @Output() read: EventEmitter<string> = new EventEmitter();

  @HostBinding('class')
  get classname() {
    const classList = [];

    switch (this.message.type) {
      case MessageTypeEnum.INFO:
        classList.push('info');
        break;
      case MessageTypeEnum.SUCCESS:
        classList.push('success');
        break;
      case MessageTypeEnum.WARNING:
        classList.push('warning');
        break;
      case MessageTypeEnum.ERROR:
        classList.push('error');
        break;
    }

    return classList.join(' ');
  }

  constructor() {
    setTimeout(() => this.close(), 5000);
  }

  close() {
    this.read.emit(this.message.id);
  }
}
