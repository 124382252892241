import { createAction, props } from '@ngrx/store';
import { DangerSource } from '../models';

export const load = createAction('[DangerSource] Load Danger sources for selected risk type');
export const loadForRiskType = createAction('[DangerSource] Load Danger sources for specified risk type', props<{ riskTypeId: number }>());
export const update = createAction('[DangerSource] Update Danger source', props<{ id: number; values: Partial<DangerSource> }>());
export const create = createAction('[DangerSource] Create Danger source', props<{ name: string; refDangerSourceId?: number }>());
export const remove = createAction('[DangerSource] Delete Danger source', props<{ id: number }>());
export const clean = createAction('[DangerSource] Clean store of Danger source');
export const move = createAction('[DangerSource] Move Danger source', props<{ id: number; position: number }>());
export const duplicate = createAction('[DangerSource] Duplicate Danger source', props<{ id: number }>());
export const imageAdded = createAction('[DangerSource] Image added on danger source', props<{ dangerSourceId: number }>());
export const imageDeleted = createAction('[DangerSource] Image deleted on danger source', props<{ dangerSourceId: number }>());
export const select = createAction('[DangerSource] Select Danger source', props<{ id: number }>());
