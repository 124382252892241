<div class="title">Filtre</div>

<ng-container [formGroup]="form">
  <div class="filter-part">
    <div class="filter-field">
      <label>Nature du risque</label>
      <lib-select class="input-render" formControlName="risk" [options]="riskOptions" placeholder="Tous" [allowEmpty]="true"></lib-select>
    </div>
    <div class="filter-field">
      <label>Unité de travail</label>
      <lib-select
        class="input-render"
        formControlName="unitOfWork"
        [options]="unitOfWorkOptions"
        placeholder="Toutes"
        [allowEmpty]="true"
      ></lib-select>
    </div>
    <div class="filter-field">
      <label>Responsable</label>
      <lib-select
        class="input-render"
        formControlName="responsible"
        [options]="responsibleOptions"
        placeholder="Tous"
        [allowEmpty]="true"
      ></lib-select>
    </div>
    <div class="filter-field">
      <label>Cotation</label>
      <lib-checkbox-group formControlName="quotation">
        <lib-checkbox-input *ngFor="let option of quotationOptions" [value]="option.value" [ngClass]="'quotation-' + option.value">
          <label>{{ option.label }}</label>
        </lib-checkbox-input>
      </lib-checkbox-group>
    </div>
  </div>

  <div class="filter-part">
    <div class="filter-field">
      <label>Avec alerte</label>
      <lib-select
        class="input-render"
        formControlName="notification"
        [options]="yesNoOptions"
        placeholder="Indifférent"
        [allowEmpty]="true"
      ></lib-select>
    </div>
    <div class="filter-field">
      <label>Périodique</label>
      <lib-select
        class="input-render"
        formControlName="periodic"
        [options]="yesNoOptions"
        placeholder="Indifférent"
        [allowEmpty]="true"
      ></lib-select>
    </div>
    <div class="filter-field">
      <label>Date prévue</label>
      <div class="date-field">
        <label for="date-from">De</label>
        <input
          id="date-from"
          libDateAutoFormatter
          type="text"
          formControlName="dateFrom"
          placeholder="JJ/MM/AAAA"
          [matDatepicker]="pickerFrom"
          (focus)="pickerFrom.open()"
        />
        <mat-datepicker #pickerFrom></mat-datepicker>
      </div>
      <div class="date-field">
        <label for="date-to">à</label>
        <input
          id="date-to"
          libDateAutoFormatter
          type="text"
          formControlName="dateTo"
          placeholder="JJ/MM/AAAA"
          [matDatepicker]="pickerTo"
          (focus)="pickerTo.open()"
        />
        <mat-datepicker #pickerTo></mat-datepicker>
      </div>
    </div>

    <div class="actions">
      <button class="button" (click)="submit()" [disabled]="!form.valid">Filtrer</button>
      <a (click)="reset($event)" *ngIf="hasActiveFilters">Réinitialiser les filtres</a>
    </div>
  </div>
</ng-container>
