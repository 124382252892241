<ng-container *ngIf="unitOfProduction$ | async as unitOfProduction">
  <div class="actions">
    <lib-checkbox-input [ngModel]="unitOfProduction.papripact" (change)="setPapripact($event, unitOfProduction.id)">
      <label>PAPRIPACT si > 50 salariés</label>
    </lib-checkbox-input>

    <a href="#" class="button" (click)="print($event)">Imprimer</a>
  </div>
  <div class="print-header">
    <ng-container *ngIf="!unitOfProduction.papripact; else papripactHeader">
      TOP Actions :
      <lib-text-format [text]="unitOfProduction.name"></lib-text-format>
      ({{ currentDate }})
      <p>
        Les entreprises de moins de 50 salariés doivent
        <strong>établir une liste d'actions de prévention des risques et de protection des salariés</strong>
        -
        <i>article L4121-3-1 du code du travail</i>
        <br />
        Obligatoire depuis le 31 mars 2022, cette liste découle du Document Unique et de ses mises à jour. Elle est consignée dans le DU et
        présentée au CSE si l'entreprise en dispose.
        <br />
        <strong>Ce TOP Actions constitue votre liste d'actions de prévention.</strong>
      </p>
    </ng-container>

    <ng-template #papripactHeader>
      Papripact :
      <lib-text-format [text]="unitOfProduction.name"></lib-text-format>
      ({{ currentDate }})
      <p>
        Les entreprises de plus de 50 salariés doivent
        <strong>
          établir chaque année un programme de prévention des risques professionnels et d'amélioration des conditions de travail, dit aussi
          « Papripact »
        </strong>
        -
        <i>article L4121-3-1 du code du travail</i>
        <br />
        Le Papripact doit être établi sur la base des résultats de l'évaluation des risques professionnels (Document Unique) et faire
        l'objet d'une consultation auprès du CSE -
        <i>article L2312-27 du code du travail</i>
      </p>
    </ng-template>
  </div>

  <lib-drop-zone class="measure-list" (dropItem)="onDrop($event)">
    <div class="header">
      <div class="counter"></div>
      <div class="risk">
        <strong>
          Risques
          <small>et</small>
        </strong>
        <small>Situations dangereuses</small>
      </div>
      <div class="unit-of-work">Unité de travail</div>
      <div class="quotation">Cotation</div>
      <div class="remove"></div>
      <div class="measure">
        <strong>Mesures préconisées</strong>
        <small>et conditions d'exécution</small>
      </div>
      <div class="amount" *ngIf="unitOfProduction.papripact">Coût</div>
      <div class="in-charge">Resp.</div>
      <div class="date">Date</div>
      <div class="result-indicator" *ngIf="unitOfProduction.papripact">Indicateur de résultat</div>
    </div>
    <div *ngFor="let topMeasure of topMeasures$ | async; let i = index" class="row" libDragItem [dragData]="{ id: topMeasure.measure.id }">
      <lib-top-measure-item
        [topMeasure]="topMeasure"
        [dangerSourceItemUrlMask]="dangerSourceItemUrlMask"
        [index]="i"
        [isPapripact]="unitOfProduction.papripact"
        (updateRequest)="updateMeasure(topMeasure, $event)"
        (deleteRequest)="removeTop(topMeasure)"
        (redirectRequest)="redirectToDangerSource(topMeasure)"
      ></lib-top-measure-item>
    </div>
  </lib-drop-zone>
</ng-container>
