import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { History, HistoryApi, HistoryListApi } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private _http: HttpClient) {}

  cancel(id: number, unitOfProductionId: number): Observable<null> {
    return this._http.patch<null>(`histories/${id}`, { unitOfProductionId });
  }

  getByUnitOfProduction(unitOfProductionId: number): Observable<History[]> {
    return this._http
      .get<HistoryListApi>('histories', {
        params: {
          paginate: 'false',
          unitOfProductionId: unitOfProductionId.toString(),
        },
      })
      .pipe(map(({ items }) => items.map(item => this._transformer(item, unitOfProductionId))));
  }

  private _transformer(itemApi: HistoryApi, unitOfProductionId: number): History {
    return {
      ...itemApi,
      unitOfProductionId,
      createdAt: itemApi.createdAt ? new Date(itemApi.createdAt) : null,
    };
  }
}
