import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { MessengerActions } from './actions';
import { Message } from './model';

export interface State extends EntityState<Message> {}

export const adapter: EntityAdapter<Message> = createEntityAdapter<Message>();

export const initialState: State = adapter.getInitialState();

const MessageReducer = createReducer(
  initialState,
  // Append
  on(MessengerActions.append, (state, { message }) => {
    return adapter.upsertOne(message, {
      ...state,
    });
  }),
  // Remove
  on(MessengerActions.remove, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
    });
  })
);

export function reducer(state: State | undefined, action: Action) {
  return MessageReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
