import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { ConfirmModalComponent } from '../../../components/actions/confirm/confirm.component';
import { CreateResponse } from '../../../components/actions/create/create.component';
import { DangerSourceCreateComponent } from '../../../components/danger-source/create/create.component';
import { DangerSourceActions } from '../../../store/actions';
import { DangerSource, RefDangerSource } from '../../../store/models';
import { AppState } from '../../../store/reducers';
import { DangerSourceSelectors, RiskTypeSelectors } from '../../../store/selectors';

@Component({
  selector: 'lib-danger-source-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class DangerSourceListComponent implements OnInit, OnDestroy {
  @Input() dangerSourceItemUrlMask: string[];
  dangerSources$: Observable<DangerSource[]>;
  refDangerSources$: Observable<RefDangerSource[]>;
  @Input() trackingMode = false;
  private _destroy$: Subject<boolean> = new Subject();

  @HostBinding('class')
  get className(): string {
    return 'danger-source-list';
  }

  constructor(private _store: Store<AppState>, private _fb: UntypedFormBuilder, private _dialog: MatDialog) {}

  delete(dangerSource: DangerSource) {
    const ref = this._dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Etes vous sur de vouloir supprimer cette situation dangereuse ?',
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(confirm => confirm),
        tap(() => this._store.dispatch(DangerSourceActions.remove({ id: dangerSource.id })))
      )
      .subscribe();
  }

  duplicate(dangerSource: DangerSource) {
    this._store.dispatch(DangerSourceActions.duplicate({ id: dangerSource.id }));
  }

  getEnabledDangerSourcesCount(dangerSources: DangerSource[]): number {
    return dangerSources.filter(({ enabled }) => enabled).length;
  }

  isVisible(dangerSource: DangerSource): boolean {
    return !this.trackingMode || dangerSource.enabled;
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this.dangerSources$ = this._store.pipe(select(DangerSourceSelectors.getAllBySelectedRiskType));
    this.refDangerSources$ = this._store.pipe(select(RiskTypeSelectors.getRefDangerSourcesBySelected));
    this._store.dispatch(DangerSourceActions.load());
  }

  onDrop(data) {
    this._store.dispatch(DangerSourceActions.move({ id: +data.id, position: data.position }));
  }

  openCreate(e: MouseEvent, dangerSources: DangerSource[], refDangerSources: RefDangerSource[]) {
    e.stopPropagation();
    e.preventDefault();

    let ref: MatDialogRef<DangerSourceCreateComponent, any>;

    const closeFn = (response: CreateResponse) => {
      const { activate, createFromRef, create } = response;
      if (activate.length + createFromRef.length + create.length === 0) {
        ref.close();
      } else {
        this._dialog
          .open(ConfirmModalComponent, {
            data: {
              title: 'Êtes vous sûr de vouloir annuler?',
              message: 'Vos modifications en attente seront perdues',
            },
            disableClose: true,
          })
          .afterClosed()
          .pipe(
            first(),
            filter(confirm => confirm),
            tap(() => ref.close())
          )
          .subscribe();
      }
    };

    ref = this._dialog.open(DangerSourceCreateComponent, {
      data: {
        dangerSources,
        refDangerSources,
        trackingMode: this.trackingMode,
        closeFn,
      },
    });

    ref
      .afterClosed()
      .pipe(
        first(),
        filter(response => response),
        tap(({ activate, create, createFromRef }) => {
          activate.forEach(id => this._store.dispatch(DangerSourceActions.update({ id, values: { enabled: true } })));
          create.forEach(name => this._store.dispatch(DangerSourceActions.create({ name })));
          createFromRef.forEach(({ id, name: refName }) => {
            const count = dangerSources.filter(({ refDangerSourceId }) => id === refDangerSourceId).length;
            const name = refName + (count > 0 ? ` - ${count + 1}` : '');
            this._store.dispatch(DangerSourceActions.create({ name, refDangerSourceId: id }));
          });
        })
      )
      .subscribe();
  }

  update(dangerSource: DangerSource, values: Partial<DangerSource>) {
    this._store.dispatch(DangerSourceActions.update({ id: dangerSource.id, values }));
  }
}
