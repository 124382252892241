import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { MeasureNotificationService } from '../../services/models/measure-notification.service';
import { MeasureNotificationActions, MeasureNotificationApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';

@Injectable()
export class MeasureNotificationEffects {
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasureNotificationActions.create),
      mergeMap(({ measure, data }) =>
        this._srv.create(measure, data).pipe(
          map(notification => MeasureNotificationApiActions.createSuccess({ measureId: measure.id, notification })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(MeasureNotificationApiActions.createFailure(), `Erreur lors de la création de l'alerte de mesure`)
            )
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasureNotificationActions.remove),
      mergeMap(({ measure }) =>
        this._srv.delete(measure.notification.id).pipe(
          map(() => MeasureNotificationApiActions.removeSuccess({ measureId: measure.id })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(MeasureNotificationApiActions.removeFailure(), `Erreur lors de la suppression de l'alerte de mesure`)
            )
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasureNotificationActions.update),
      mergeMap(({ measure, data }) =>
        this._srv.update(measure.notification.id, data).pipe(
          map(notification => MeasureNotificationApiActions.updateSuccess({ measureId: measure.id, notification })),
          catchError(() =>
            of(true).pipe(
              EffectHelper.failure(MeasureNotificationApiActions.updateFailure(), `Erreur lors de la modification de l'alerte de mesure`)
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: MeasureNotificationService) {}
}
