import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-insert',
  templateUrl: './insert.component.html',
  styleUrls: ['./insert.component.scss'],
})
export class InsertComponent {
  @HostBinding('class') className = 'insert-block';
  @Input() contact = false;
}
