import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { MeasureService } from '../../services/models/measure.service';
import { MeasureActions, MeasureApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { AppState } from '../reducers';
import { MeasureSelectors, UnitOfWorkDangerSourceSelectors } from '../selectors';

@Injectable()
export class MeasureEffects {
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasureActions.create),
      withLatestFrom(this._store.pipe(select(UnitOfWorkDangerSourceSelectors.getSelected))),
      mergeMap(([{ name, measureType, refMeasureId }, unitOfWorkDangerSource]) =>
        this._srv.create(unitOfWorkDangerSource.id, measureType, name, refMeasureId).pipe(
          map(measure => MeasureApiActions.createSuccess({ measure })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(MeasureApiActions.createFailure(), 'Erreur lors de la création de la mesure'))
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasureActions.remove),
      mergeMap(({ id }) =>
        this._srv.delete(id).pipe(
          map(() => MeasureApiActions.removeSuccess({ id })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(MeasureApiActions.removeFailure(), 'Erreur lors de la suppression de la mesure'))
          )
        )
      )
    )
  );

  move$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasureActions.move),
      withLatestFrom(this._store.pipe(select(UnitOfWorkDangerSourceSelectors.getSelected))),
      map(([{ id, measureType, position }, unitOfWorkDangerSource]) => ({
        id,
        values: {
          type: measureType,
          position,
        },
        unitOfWorkDangerSource,
      })),
      mergeMap(({ id, values, unitOfWorkDangerSource }) =>
        this._srv.update(id, values, unitOfWorkDangerSource.id).pipe(
          map(measure => MeasureApiActions.moveSuccess({ measure })),
          catchError(() => of(true).pipe(EffectHelper.failure(MeasureApiActions.moveFailure(), 'Erreur lors du déplacement de la mesure')))
        )
      )
    )
  );

  removeByUnitOfWorkDangerSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasureActions.removeByUnitOfWorkDangerSource),
      withLatestFrom(this._store.pipe(select(MeasureSelectors.getAll))),
      map(([{ id }, measures]) => measures.filter(({ unitOfWorkDangerSourceId }) => id === unitOfWorkDangerSourceId)),
      mergeMap(measures => measures.map(({ id }) => MeasureApiActions.removeSuccess({ id })))
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasureActions.update),
      mergeMap(({ id, values }) => of({ id, values }).pipe(withLatestFrom(this._store.pipe(select(MeasureSelectors.getById(id)))))),
      mergeMap(([{ id, values }, { unitOfWorkDangerSourceId }]) => {
        return this._srv.update(id, values, unitOfWorkDangerSourceId).pipe(
          map(measure => MeasureApiActions.updateSuccess({ measure })),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(MeasureApiActions.updateFailure(), 'Erreur lors de la sauvegarde de la mesure'))
          )
        );
      })
    )
  );

  constructor(private actions$: Actions, private _srv: MeasureService, private _store: Store<AppState>) {}
}
