import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractApi, ContractListApi, ContractServiceResponse } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private _http: HttpClient) {}

  getByFilters(filters = {}): Observable<ContractServiceResponse[]> {
    return this._http
      .get<ContractListApi>('contracts', {
        params: {
          paginate: 'false',
          ...filters,
        },
      })
      .pipe(map(({ items }) => items.map(itemApi => this._transformer(itemApi))));
  }

  getById(id: number): Observable<ContractServiceResponse> {
    return this._http.get<ContractApi>(`contracts/${id}`).pipe(map(contractApi => this._transformer(contractApi)));
  }

  private _transformer(itemApi: ContractApi): ContractServiceResponse {
    const { company, product, ...data } = itemApi;

    return {
      contract: {
        ...data,
        companyId: company.id,
        productId: product.id,
        startAt: data.startAt ? new Date(data.startAt) : null,
      },
      product,
      company,
    };
  }
}
