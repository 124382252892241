import { AppState, ContractSelectors } from '@aequalib';
import { AfterViewInit, Component, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { skip, takeUntil, tap } from 'rxjs/operators';
import { LayoutService } from '../../../services/layout.service';

@Component({
  selector: 'app-duer-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class DuerLayoutComponent implements AfterViewInit, OnDestroy {
  hasSidebar = false;
  @ViewChild('sidebar', { read: ViewContainerRef }) sidebarWrapperRef: ViewContainerRef;
  private _destroy$: Subject<boolean> = new Subject();
  private _sidebarAdditionalClass = null;

  get sidebarClass(): string {
    const classClist = [];

    if (!this.hasSidebar) {
      classClist.push('inactive');
    }

    if (this._sidebarAdditionalClass) {
      classClist.push(this._sidebarAdditionalClass);
    }

    return classClist.join(' ');
  }

  constructor(private _layoutSrv: LayoutService, private _store: Store<AppState>, private _router: Router) {}

  ngAfterViewInit(): void {
    this._layoutSrv.pageClass = 'wide';

    this._layoutSrv.sidebar$
      .pipe(
        takeUntil(this._destroy$),
        tap(tplRef => {
          if (tplRef === null) {
            this.sidebarWrapperRef.clear();
            setTimeout(() => (this.hasSidebar = false));
          } else {
            this.sidebarWrapperRef.createEmbeddedView(tplRef);
            setTimeout(() => (this.hasSidebar = true));
          }
        })
      )
      .subscribe();

    this._layoutSrv.sidebarClass$
      .pipe(
        takeUntil(this._destroy$),
        tap(className => {
          setTimeout(() => (this._sidebarAdditionalClass = className));
        })
      )
      .subscribe();

    this._store
      .pipe(
        takeUntil(this._destroy$),
        select(ContractSelectors.getSelected),
        skip(1),
        tap(() => this._router.navigateByUrl('/change-contract'))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._layoutSrv.pageClass = null;
  }
}
