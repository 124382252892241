import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmModalData {
  title: string;
  message?: string;
}

@Component({
  selector: 'lib-confirm-modal',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmModalData, private _dialogRef: MatDialogRef<ConfirmModalComponent>) {}

  cancel() {
    this._dialogRef.close(false);
  }

  confirm() {
    this._dialogRef.close(true);
  }
}
