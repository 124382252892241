import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LibSelectOption } from '../../../interfaces/select';
import { DangerSourceActions } from '../../../store/actions';
import { DangerSource, UnitOfWork } from '../../../store/models';
import { AppState } from '../../../store/reducers';
import { DangerSourceSelectors, UnitOfWorkSelectors } from '../../../store/selectors';

export interface ConfirmModalData {
  title: string;
  message?: string;
}

@Component({
  selector: 'lib-danger-source-selector-modal',
  templateUrl: './selector.component.html',
})
export class DangerSourceSelectorComponent implements OnInit {
  dangerSourceOptions: LibSelectOption[];
  dangerSources$: Observable<DangerSource[]>;
  form: UntypedFormGroup;
  unitOfWorkOptions: LibSelectOption[];
  unitOfWorks$: Observable<UnitOfWork[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dangerSourceId: number },
    private _dialogRef: MatDialogRef<DangerSourceSelectorComponent>,
    private _store: Store<AppState>,
    private _fb: UntypedFormBuilder
  ) {}

  cancel() {
    this._dialogRef.close(null);
  }

  confirm() {
    if (this.form.valid) {
      this._dialogRef.close(this.form.value);
    }
  }

  getOptions(dangerSources: DangerSource[]) {
    return dangerSources
      .filter(({ id }) => id !== this.data.dangerSourceId)
      .map(({ name, id }) => ({
        label: name,
        value: id,
      }));
  }

  ngOnInit(): void {
    this._dialogRef.addPanelClass(['overflowable', 'narrow', 'alternative']);

    this.dangerSources$ = this._store.pipe(
      select(DangerSourceSelectors.getAllBySelectedRiskType),
      tap(dangerSources => (this.dangerSourceOptions = this.getOptions(dangerSources)))
    );

    this.unitOfWorks$ = this._store.pipe(
      select(UnitOfWorkSelectors.getAllAvailableBySelectedUnitOfProduction),
      tap(unitOfWorks => (this.unitOfWorkOptions = unitOfWorks.map(({ name, id }) => ({ label: name, value: id }))))
    );

    this._store.dispatch(DangerSourceActions.load());

    this.form = this._fb.group({
      dangerSource: [null, Validators.required],
      unitOfWork: [null, Validators.required],
    });
  }
}
