import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[libShadowItem]',
})
export class ShadowItemDirective {
  constructor(private _el: ElementRef) {
    this.domEl.classList.add('shadow-item');
  }

  get domEl(): HTMLElement {
    return this._el.nativeElement as HTMLElement;
  }

  set position(value: number) {
    this.domEl.style.order = value.toString();
  }

  get position(): number {
    return +(this.domEl.style.order || 0);
  }
}
