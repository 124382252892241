import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CompanyActions, CompanyApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { Company, CompanyShort } from '../models';

export interface State extends EntityState<Company>, LoadingState {
  selected: number;
  shorts: Dictionary<CompanyShort>;
}

export const adapter: EntityAdapter<Company> = createEntityAdapter<Company>();

export const initialState: State = adapter.getInitialState({
  ...LoadingStateHelper.initial(),
  selected: null,
  shorts: {},
});

const CompanyReducer = createReducer(
  initialState,
  // Load
  on(CompanyActions.loadForCurrentContract, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(CompanyApiActions.loadForCurrentContractSuccess, (state, { company }) => {
    return adapter.upsertOne(company, {
      ...state,
      ...LoadingStateHelper.success(),
      selected: company.id,
    });
  }),
  on(CompanyApiActions.loadForCurrentContractFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  on(CompanyApiActions.loadShorts, (state, { companies }) => ({
    ...state,
    shorts: {
      ...state.shorts,
      ...companies.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),
    },
  })),
  // Update
  on(CompanyActions.update, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(CompanyApiActions.updateSuccess, (state, { company }) => {
    return adapter.upsertOne(company, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(CompanyApiActions.updateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Update Referent
  on(CompanyActions.updateOrCreateReferent, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(CompanyApiActions.updateOrCreateReferentSuccess, (state, { companyId, contact }) => {
    const company = Object.values(state.entities).find(curr => curr.id === companyId);

    return adapter.upsertOne(
      {
        ...company,
        contactReferent: contact,
      },
      {
        ...state,
        ...LoadingStateHelper.success(),
      }
    );
  }),
  on(CompanyApiActions.updateOrCreateReferentFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Update Decision Maker
  on(CompanyActions.updateOrCreateDecisionMaker, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(CompanyApiActions.updateOrCreateDecisionMakerSuccess, (state, { companyId, contact }) => {
    const company = Object.values(state.entities).find(curr => curr.id === companyId);

    return adapter.upsertOne(
      {
        ...company,
        contactDecisionMaker: contact,
      },
      {
        ...state,
        ...LoadingStateHelper.success(),
      }
    );
  }),
  on(CompanyApiActions.updateOrCreateDecisionMakerFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  on(CompanyActions.unselect, state => ({
    ...state,
    selected: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return CompanyReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
