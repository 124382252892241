import {
  AppState,
  HydratedMeasure,
  HydratedMeasureActions,
  LibSelectOption,
  MeasureActions,
  UnitOfProduction,
  UnitOfProductionSelectors,
  UnitOfWorkDangerSourceActions,
  UrlMaskPipe,
} from '@aequalib';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PlanningFilters } from 'src/app/components/planning/filter/filter.component';
import { UiActions } from 'src/app/store/actions';
import { MeasureSelectors, UiSelectors } from 'src/app/store/selectors';

interface FilterOptions {
  risk: LibSelectOption[];
  unitOfWork: LibSelectOption[];
  responsible: LibSelectOption[];
}

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss'],
  providers: [UrlMaskPipe],
})
export class PlanningComponent implements OnInit, OnDestroy {
  filters$: Observable<PlanningFilters>;
  filtersOptions$: Observable<FilterOptions>;
  hasPeriodicFilter: boolean = false;
  hydratedMeasures$: Observable<HydratedMeasure[]>;
  unitOfProduction$: Observable<UnitOfProduction>;
  private _destroy$: Subject<boolean> = new Subject();
  private _hydratedMeasures: HydratedMeasure[] = [];

  get currentDate() {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    return `${day}/${month}/${date.getFullYear()}`;
  }

  get hydratedMeasures(): HydratedMeasure[] {
    return this._hydratedMeasures;
  }

  constructor(private _store: Store<AppState>, private _urlMaskPipe: UrlMaskPipe, private _router: Router) {}

  changeFilter(filters) {
    this._store.dispatch(UiActions.savePlanningFilters({ data: filters }));
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this.hydratedMeasures$ = this._store.pipe(select(MeasureSelectors.getForPlanning));

    this.unitOfProduction$ = this._store.pipe(select(UnitOfProductionSelectors.getSelected));

    this.filters$ = this._store.pipe(
      select(UiSelectors.getPlanningFilters),
      tap(({ periodic }) => (this.hasPeriodicFilter = ![null, undefined].includes(periodic)))
    );

    this.filtersOptions$ = this._store.pipe(select(UiSelectors.getPlanningFilterOptions));

    this._store.dispatch(HydratedMeasureActions.loadAll());
  }

  print(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    window.print();
  }

  redirectToDangerSource({ measure, dangerSource, riskType, unitOfWorkDangerSource }: HydratedMeasure) {
    const mask = ['/mon-duer', 'unite-de-production', '%unitOfProductionId', 'risques', '%riskTypeId', 'sources-de-danger', '%id'];
    const url = this._urlMaskPipe.transform(
      mask,
      [dangerSource.id, measure.unitOfProductionId, riskType.id],
      ['id', 'unitOfProductionId', 'riskTypeId']
    );

    this._store.dispatch(UnitOfWorkDangerSourceActions.select({ id: unitOfWorkDangerSource.id }));
    this._store.dispatch(MeasureActions.highlight({ id: measure.id }));

    this._router.navigate(url);
  }
}
