<ng-container *ngIf="product$ | async as product">
  <div class="content" [innerHTML]="product.description"></div>

  <aside>
    <ng-container *ngIf="contractsWithDocument$ | async as contracts">
      <ng-container *ngIf="hasDocument">
        <app-insert>
          <a *ngIf="product.document" [href]="product.document" target="_blank" class="button">Télécharger</a>
          <a *ngFor="let contract of contracts" [href]="contract.document" target="_blank" class="button">Télécharger mon document</a>
        </app-insert>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="product.image">
      <img class="picture" [src]="product.image" />
    </ng-container>
  </aside>
</ng-container>
