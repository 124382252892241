import { createAction, props } from '@ngrx/store';
import { Company, Contact } from '../models';

export const loadForCurrentContract = createAction('[Company] Load company of a contract');
export const update = createAction('[Company] Update company', props<{ id: number; values: Partial<Company> }>());
export const updateOrCreateReferent = createAction(
  '[Company] Update Referent for company',
  props<{ id: number; values: Partial<Contact> }>()
);
export const updateOrCreateDecisionMaker = createAction(
  '[Company] Update Decision maker for company',
  props<{ id: number; values: Partial<Contact> }>()
);
export const unselect = createAction('[Company] Unselect current selected company');
