<ng-container *ngIf="data">
  <div class="content" [innerHTML]="data.content"></div>

  <app-insert>
    <ng-container title>{{ data.title }}</ng-container>
    <ng-container *ngIf="data.document">
      <p>{{ data.subTitle }}</p>
      <a [href]="data.document" target="_blank" class="button">Télécharger</a>
    </ng-container>
  </app-insert>
</ng-container>
