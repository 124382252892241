import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { UnitOfWork } from '../../../store/models';

@Component({
  selector: 'lib-unit-of-work-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class UnitOfWorkItemComponent implements OnInit, OnDestroy {
  @Output() archiveRequest: EventEmitter<null> = new EventEmitter();
  @Output() deleteRequest: EventEmitter<null> = new EventEmitter();
  form: UntypedFormGroup;
  @Input() trackingMode: boolean;
  @Input() unitOfWork: UnitOfWork;
  @Output() update: EventEmitter<Partial<UnitOfWork>> = new EventEmitter();
  private _destroy$: Subject<boolean> = new Subject();

  get archiveLabel(): string {
    return this.unitOfWork.archived ? 'Désarchiver' : 'Archiver';
  }

  constructor(private _fb: UntypedFormBuilder) {}

  archive(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.archiveRequest.emit();
  }

  delete(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.deleteRequest.emit();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this.form = this._fb.group(
      {
        name: [this.unitOfWork.name, Validators.required],
        enabled: [this.unitOfWork.enabled],
        workforce: [this.unitOfWork.workforce, [Validators.required, Validators.pattern('[0-9]*')]],
      },
      { updateOn: 'blur' }
    );

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.form.valid),
        tap(values => {
          values = {
            ...values,
            workforce: +values.workforce,
          };
          this.update.emit(values);
        })
      )
      .subscribe();
  }
}
