<lib-line-item [disabled]="!dangerSource.enabled" [formGroup]="form">
  <lib-checkbox-input formControlName="enabled" *ngIf="!trackingMode"></lib-checkbox-input>
  <lib-editable-data>
    <span libEditableDataLabel></span>
    <textarea libEditableDataInput formControlName="name"></textarea>
  </lib-editable-data>
  <ng-container *ngIf="trackingMode">
    <div class="count-wrapper">
      <span class="count">{{ dangerSource.nbMeasures }}</span>
    </div>

    <div class="count-wrapper">
      <span class="count">{{ dangerSource.nbMeasuresImplemented }}</span>
    </div>

    <div class="count-wrapper multi">
      <span class="count quotation-critical">{{ dangerSource.nbMeasuresCritical }}</span>
      <span class="count quotation-important">{{ dangerSource.nbMeasuresImportant }}</span>
      <span class="count quotation-normal">{{ dangerSource.nbMeasuresNormal }}</span>
      <span class="count quotation-low">{{ dangerSource.nbMeasuresLow }}</span>
    </div>

    <div class="count-wrapper">
      <span class="count">{{ dangerSource.nbMeasuresPredicted }}</span>
    </div>
  </ng-container>
  <span class="icon painfullness" [ngClass]="{ active: dangerSource.painfullness }" title="Pénibilité du travail"></span>
  <span class="icon comment" [ngClass]="{ active: hasComment }" title="Possède un commentaire"></span>
  <span class="icon picture" [ngClass]="{ active: dangerSource.nbImages > 0 }">
    <i>{{ dangerSource.nbImages }}</i>
  </span>
  <ng-container actions>
    <a [routerLink]="dangerSourceItemUrlMask | urlMask: dangerSource.id" class="button">Sélectionner</a>
    <a href="#" class="picture" (click)="showPictures($event)"></a>
    <lib-dropdown-menu>
      <a href="#" libDropdownItem (click)="showPictures($event)">Ajouter des photos</a>
      <a href="#" libDropdownItem (click)="showComment($event)">
        {{ hasComment ? 'Modifier le commentaire' : 'Ajouter un commentaire' }}
      </a>
      <a href="#" libDropdownItem (click)="switchPainfullness($event)">Pénibilité du travail</a>
      <a href="#" libDropdownItem (click)="duplicate($event)">Dupliquer</a>
      <a href="#" libDropdownItem (click)="delete($event)" class="warning">Supprimer</a>
    </lib-dropdown-menu>
  </ng-container>
</lib-line-item>
