import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditableDataComponent } from './components/editable-data.component';
import { EditableDataInputDirective } from './directives/editable-data-input.directive';
import { EditableDataLabelDirective } from './directives/editable-data-label.directive';

@NgModule({
  declarations: [EditableDataComponent, EditableDataInputDirective, EditableDataLabelDirective],
  imports: [CommonModule],
  exports: [EditableDataComponent, EditableDataInputDirective, EditableDataLabelDirective],
})
export class EditableDataModule {}
