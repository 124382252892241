import { Directive, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

interface Item {
  id: number;
  name: string;
  enabled: boolean;
}

interface RefItem {
  id: number;
}

export interface CreateResponse {
  activate: number[];
  createFromRef: RefItem[];
  create: any[];
}

@Directive()
export abstract class ItemCreateDirective<T extends Item, RefT extends RefItem> implements OnInit, OnDestroy {
  addForm: UntypedFormGroup;
  form: UntypedFormGroup;
  items: T[] = [];
  refItems: RefT[] = [];
  private _destroy$: Subject<boolean> = new Subject();

  get customFormArray(): UntypedFormArray {
    return this.form.get('custom') as UntypedFormArray;
  }

  get customGroups(): UntypedFormGroup[] {
    return this.customFormArray.controls as UntypedFormGroup[];
  }

  constructor(
    private _dialogRef: MatDialogRef<ItemCreateDirective<T, RefT>>,
    private _fb: UntypedFormBuilder,
    public trackingMode: boolean,
    items: T[],
    private _closeFn?: (response: CreateResponse) => void
  ) {
    this.items = trackingMode ? items.filter(item => !item.enabled) : [];
  }

  cancel() {
    if (this._closeFn && this.form.valid) {
      this._closeFn(this._createResponse());
    } else {
      this._dialogRef.close();
    }
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this._dialogRef.addPanelClass(['wide']);

    if (this._closeFn) {
      this._dialogRef.disableClose = true;
      this._dialogRef
        .backdropClick()
        .pipe(
          takeUntil(this._destroy$),
          tap(() => this.cancel())
        )
        .subscribe();
    }

    this._createSelectorForm();
    this._createSelectorRefForm();
    this._createAddForm();
  }

  submit() {
    if (this.form.valid) {
      this._dialogRef.close(this._createResponse());
    }
  }

  private _addCustomGroup(name: string) {
    const group = this._fb.group({
      enabled: true,
      name: [name],
    });

    this.customFormArray.push(group);
  }

  private _createAddForm() {
    this.addForm = this._fb.group(
      {
        name: ['', Validators.required],
      },
      { updateOn: 'blur' }
    );

    this.addForm.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.addForm.valid),
        tap(({ name }) => {
          this._addCustomGroup(name);

          this.addForm.setValue({ name: '' });
          this.addForm.markAsPristine();
        })
      )
      .subscribe();
  }

  private _createResponse(): CreateResponse {
    const { custom, existing, reference } = this.form.value;
    return {
      activate: Object.keys(existing)
        .filter(id => existing[id])
        .map(id => +id),
      createFromRef: Object.keys(reference)
        .filter(id => reference[id])
        .map(refId => this.refItems.find(({ id }) => +refId === id)),
      create: custom.filter(item => item.enabled).map(item => item.name),
    };
  }

  private _createSelectorForm() {
    this.form = this._fb.group(
      { existing: this._fb.group([]), reference: this._fb.group([]), custom: this._fb.array([]) },
      {
        updateOn: 'blur',
      }
    );

    const existingForm = this.form.get('existing') as UntypedFormGroup;

    this.items.forEach(item => {
      existingForm.addControl(`${item.id}`, this._fb.control(false));
    });
  }

  private _createSelectorRefForm() {
    const refForm = this.form.get('reference') as UntypedFormGroup;

    this.refItems.forEach(item => {
      refForm.addControl(`${item.id}`, this._fb.control(false));
    });
  }
}
