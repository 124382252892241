import { Action, createReducer, on } from '@ngrx/store';

import { ContactFormActions, ContactFormApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';

export interface State extends LoadingState {
  sended: boolean;
}

export const initialState: State = {
  ...LoadingStateHelper.initial(),
  sended: false,
};

const ContactFormReducer = createReducer(
  initialState,
  // reinit
  on(ContactFormActions.reinit, () => initialState),
  // Send
  on(ContactFormActions.send, state => ({
    ...state,
    ...LoadingStateHelper.start(),
    sended: false,
  })),
  on(ContactFormApiActions.success, state => ({
    ...state,
    ...LoadingStateHelper.success(),
    sended: true,
  })),
  on(ContactFormApiActions.failure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return ContactFormReducer(state, action);
}
