import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UnitOfWork, UnitOfWorkApi, UnitOfWorkListApi } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class UnitOfWorkService {
  constructor(private _http: HttpClient) {}

  create(unitOfProductionId: number, name: string): Observable<UnitOfWork> {
    return this._http
      .post<UnitOfWorkApi>(`unit-of-works`, {
        unitOfProductionId,
        name,
      })
      .pipe(map(unitOfWorkApi => this._transformer(unitOfWorkApi)));
  }

  delete(id: number): Observable<null> {
    return this._http.delete<null>(`unit-of-works/${id}`);
  }

  getAll(contractId: number): Observable<UnitOfWork[]> {
    return this._http
      .get<UnitOfWorkListApi>('unit-of-works', {
        params: {
          contractId: contractId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(result => result.items.map(item => this._transformer(item))));
  }

  getAllByUnitOfProduction(unitOfProductionId: number): Observable<UnitOfWork[]> {
    return this._http
      .get<UnitOfWorkListApi>('unit-of-works', {
        params: {
          unitOfProductionId: unitOfProductionId.toString(),
          paginate: 'false',
        },
      })
      .pipe(map(result => result.items.map(item => this._transformer(item))));
  }

  update(id: number, values: Partial<UnitOfWork>): Observable<UnitOfWork> {
    return this._http.patch<UnitOfWorkApi>(`unit-of-works/${id}`, values).pipe(map(unitOfWorkApi => this._transformer(unitOfWorkApi)));
  }

  private _transformer(itemApi: UnitOfWorkApi): UnitOfWork {
    const { unitOfProduction, ...data } = itemApi;

    return {
      ...data,
      unitOfProductionId: unitOfProduction.id,
    };
  }
}
