import { createAction, props } from '@ngrx/store';
import { RiskType } from '../models';

export const loadByRiskCategory = createAction(
  '[RiskType] Load risk types by category of risk',
  props<{ riskCategoryId: number; loadTree: boolean }>()
);
export const loadStatuses = createAction('[RiskType/Status] Load possibles status for risk type');
export const update = createAction('[RiskType] Update risk type', props<{ id: number; values: Partial<RiskType> }>());
export const move = createAction('[RiskType] Move risk type', props<{ id: number; riskCategoryId: number; position: number }>());
export const select = createAction('[RiskType] Select risk type', props<{ id: number }>());
// Clean
export const clean = createAction('[RiskType] Clean store of risk type');
