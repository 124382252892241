import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ContactFormService } from '../../services/contact.service';
import { ContactFormActions, ContactFormApiActions } from '../actions';
import { EffectHelper } from '../helpers/effect.helper';
import { AppState } from '../reducers';
import { ContractSelectors } from '../selectors';

@Injectable()
export class ContactFormEffects {
  send$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactFormActions.send),
      withLatestFrom(this._store.pipe(select(ContractSelectors.getSelected))),
      mergeMap(([{ formData }, { id }]) =>
        this._srv.send(formData, id).pipe(
          map(() => ContactFormApiActions.success()),
          catchError(() =>
            of(true).pipe(EffectHelper.failure(ContactFormApiActions.failure(), `Erreur lors de l'envoi de votre demande de contact`))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private _srv: ContactFormService, private _store: Store<AppState>) {}
}
