import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Measure, RefMeasure } from '../../../store/models';
import { ItemCreateDirective } from '../../actions/create/create.component';

@Component({
  selector: 'lib-measure-create',
  templateUrl: './create.component.html',
  styleUrls: ['../../actions/create/create.component.scss'],
})
export class MeasureCreateComponent extends ItemCreateDirective<Measure, RefMeasure> implements OnInit, OnDestroy {
  private _preventive: boolean;

  get title() {
    return this.trackingMode
      ? 'Vous pouvez choisir des nouvelles mesures pré-rédigées ou créer de nouvelles mesure'
      : 'Vous pouvez choisir des mesures issues du référentiel ou créer de nouvelles mesures';
  }

  get selectorTitle() {
    return this.trackingMode ? 'Nouvelles mesures pré-rédigées par nos experts' : 'Nouvelles mesures issues du référentiel';
  }

  constructor(
    dialogRef: MatDialogRef<MeasureCreateComponent>,
    fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    data: {
      measures: Measure[];
      refMeasures: RefMeasure[];
      trackingMode: boolean;
      preventive: boolean;
      closeFn: (CreateResponse) => void;
    }
  ) {
    super(dialogRef, fb, data.trackingMode, data.measures, data.closeFn);

    this._preventive = data.preventive || false;

    const refIds = data.measures.filter(({ refMeasureId }) => refMeasureId !== null).map(({ refMeasureId }) => refMeasureId);
    this.refItems = data.refMeasures.filter(({ id }) => !refIds.includes(id));
  }

  getRefMeasureName(refMeasure: RefMeasure) {
    return this._preventive ? refMeasure.preventiveName : refMeasure.effectiveName;
  }
}
