<div class="content">
  <p>Bonjour {{ owner }}</p>
  <p>
    <ng-container *ngIf="contract$ | async as contract">
      <ng-container *ngIf="contract.startAt">
        Depuis le
        {{ contract.startAt | date: 'dd/MM/yyyy' }},
      </ng-container>
    </ng-container>
    vous adhérez aux services Aequalis Prévention pour être accompagné dans vos démarches de santé et de sécurité au travail.
  </p>

  <div class="title">Vous avez réalisé votre Document Unique avec Aequalis Prévention ?</div>
  <p>
    Notre outil en ligne vous permet ici de le mettre à jour dès que nécessaire et à minima une fois par an, à l'aide des fonctionnalités
    mises à votre disposition : planification et suivi des actions de prévention, ajout de nouvelles situations de travail, de nouvelles
    mesures de prévention, traçabilité de vos actions, réédition de votre Document Unique mis à jour, ...
  </p>

  <div class="title">Vous bénéficiez d'autres Services Aequalis Prévention ?</div>
  <p>
    Vous pouvez ici les consulter : diagnostic pénibilité, diagnostic TMS, focus Risques Psycho-Sociaux, protocole de sécurité, livret
    d'accueil sécurité, ...
  </p>

  <hr />
  <p>
    Nous vous remercions de votre engagement pour la prévention des risques professionnels au sein de votre entreprise, et nous nous tenons
    à votre disposition pour vous accompagner dans sa mise en œuvre.
  </p>
</div>

<app-insert [contact]="true">
  <ng-container title>Une question ? Une demande particulière ?</ng-container>
  <p>
    Vous bénéficiez d'un accès illimité à notre service d'assistance
    <strong>Experts en Ligne</strong>
    , pour répondre à toutes vos questions concernant la prévention des risques professionnels :
  </p>
</app-insert>
