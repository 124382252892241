<lib-line-item class="contrast" [disabled]="!hasDangerSourceLink">
  <lib-checkbox-input [(ngModel)]="hasDangerSourceLink"></lib-checkbox-input>
  <lib-text-format [text]="unitOfWork.name"></lib-text-format>
  <ng-container *ngIf="hasDangerSourceLink" actions>
    <a href="#" class="button" (click)="select($event)">Sélectionner</a>
    <lib-dropdown-menu>
      <a href="#" libDropdownItem (click)="duplicate($event)" [ngClass]="{ disabled: !canDuplicate }">
        Dupliquer la sélection pour cette autre UT
      </a>
      <a href="#" libDropdownItem (click)="duplicateForDangerSource($event)">Dupliquer dans une autre situation dangereuse</a>
      <a href="#" libDropdownItem (click)="delete($event)">Unité non concernée</a>
    </lib-dropdown-menu>
  </ng-container>
  <lib-unit-of-work-quotation
    [unitOfWorkDangerSource]="unitOfWorkDangerSource"
    *ngIf="isSelected"
    (update)="updateQuotation($event)"
    compact
    additional
  ></lib-unit-of-work-quotation>
</lib-line-item>
