import { Measure } from '@aequalib';
import { Directive, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Directive()
export class MeasureItemDirective implements OnInit, OnDestroy {
  @Output() deleteRequest: EventEmitter<null> = new EventEmitter();
  form: UntypedFormGroup;
  @Input() highlight: boolean;
  @Input() measure: Measure;
  @Output() update: EventEmitter<Partial<Measure>> = new EventEmitter();
  protected _destroy$: Subject<boolean> = new Subject();

  @HostBinding('class')
  get className(): string {
    const classList = ['measure-item'];

    if (this.highlight) {
      classList.push('highlight');
    }

    return classList.join(' ');
  }

  constructor(protected _fb: UntypedFormBuilder) {}

  changePeriodic(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.update.emit({
      periodic: !this.measure.periodic,
    });
  }

  delete(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.deleteRequest.emit();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit(): void {
    this._createForm();

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        filter(() => this.form.valid),
        tap(values => this._submitForm(values))
      )
      .subscribe();
  }

  protected _createForm() {
    this.form = this._fb.group(
      {
        name: [this.measure.name, Validators.required],
      },
      { updateOn: 'blur' }
    );
  }

  protected _submitForm(values) {
    this.update.emit(values);
  }
}
