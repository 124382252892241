import { AppState, ContractActions, contractResolver as sharedContractResolver } from '@aequalib';
import { inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

export const contractResolver = (): Observable<boolean> => {
  inject(Store<AppState>).dispatch(ContractActions.loadAndSelect());

  return sharedContractResolver();
};
