import { createAction, props } from '@ngrx/store';

import { UnitOfProduction } from '../models';

export const load = createAction('[UnitOfProduction] Load Units of Production');
export const update = createAction(
  '[UnitOfProduction] Update Unit of Production',
  props<{ id: number; values: Partial<UnitOfProduction> }>()
);
export const create = createAction('[UnitOfProduction] Create Unit of Production', props<{ name: string }>());
export const remove = createAction('[UnitOfProduction] Delete Unit of Production', props<{ id: number }>());
export const clean = createAction('[UnitOfProduction] Clean store of Unit of Production', props<{ id: number }>());
export const cleanDependencies = createAction('[UnitOfProduction] Clean store dependencies of Unit of Production', props<{ id: number }>());
export const select = createAction('[UnitOfProduction] Select Unit of Production', props<{ id: number }>());
export const validate = createAction('[UnitOfProduction] Validate Unit of Production', props<{ id: number }>());
export const duplicate = createAction('[UnitOfProduction] Duplicate Unit of Production', props<{ id: number; contractId: number }>());
export const changeReference = createAction(
  '[UnitOfProduction] Change Reference of the Unit of Production',
  props<{ id: number; refUnitOfProductionId: number }>()
);
