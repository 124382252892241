import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { RefActivityActions, RefActivityApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { RefActivity } from '../models';

export interface State extends EntityState<RefActivity>, LoadingState {}

export const adapter: EntityAdapter<RefActivity> = createEntityAdapter<RefActivity>();

export const initialState: State = adapter.getInitialState({
  ...LoadingStateHelper.initial(),
  selected: null,
});

const RefActivityReducer = createReducer(
  initialState,
  // Load
  on(RefActivityActions.load, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(RefActivityApiActions.loadSuccess, (state, { refActivities }) => {
    return adapter.upsertMany(refActivities, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(RefActivityApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return RefActivityReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
