import {
  AppState,
  Company,
  CompanyActions,
  CompanySelectors,
  Contact,
  Contract,
  ContractSelectors,
  RefActivity,
  RefActivityActions,
  RefActivitySelectors,
} from '@aequalib';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-unit-of-production-list',
  templateUrl: './list.component.html',
})
export class UnitOfProductionListComponent implements OnInit {
  activities$: Observable<RefActivity[]>;
  company$: Observable<Company>;
  contract$: Observable<Contract>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.contract$ = this._store.pipe(select(ContractSelectors.getSelected));
    this.company$ = this._store.pipe(select(CompanySelectors.getSelected));
    this.activities$ = this._store.pipe(select(RefActivitySelectors.getAll));

    this._store.dispatch(RefActivityActions.load());
  }

  updateCompany(id: number, values: Partial<Company>) {
    this._store.dispatch(CompanyActions.update({ id, values }));
  }

  updateDecisionMaker(id: number, values: Partial<Contact>) {
    this._store.dispatch(CompanyActions.updateOrCreateDecisionMaker({ id, values }));
  }

  updateReferent(id: number, values: Partial<Contact>) {
    this._store.dispatch(CompanyActions.updateOrCreateReferent({ id, values }));
  }
}
