import { DateHelper, LibSelectOption, QuotationEnum, QuotationHelper } from '@aequalib';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

export interface PlanningFilters {
  risk?: string;
  unitOfWork?: string;
  responsible?: string;
  quotation?: string;
  notification?: boolean;
  periodic?: boolean;
  dateFrom?: string;
  dateTo?: string;
}

@Component({
  selector: 'app-planning-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class PlanningFilterComponent implements OnInit {
  @Output() filterRequested: EventEmitter<{ [key: string]: any }> = new EventEmitter();
  @Input() filters: PlanningFilters = {};
  form: UntypedFormGroup;
  quotationOptions: LibSelectOption[] = [
    { value: QuotationEnum.CRITICAL, label: QuotationHelper.getLabel(QuotationEnum.CRITICAL) },
    { value: QuotationEnum.IMPORTANT, label: QuotationHelper.getLabel(QuotationEnum.IMPORTANT) },
    { value: QuotationEnum.NORMAL, label: QuotationHelper.getLabel(QuotationEnum.NORMAL) },
    { value: QuotationEnum.LOW, label: QuotationHelper.getLabel(QuotationEnum.LOW) },
  ];
  @Input() responsibleOptions: LibSelectOption[];
  @Input() riskOptions: LibSelectOption[];
  @Input() unitOfWorkOptions: LibSelectOption[];
  yesNoOptions: LibSelectOption[] = [
    { value: true, label: 'Oui' },
    { value: false, label: 'Non' },
  ];

  get activeFilters(): PlanningFilters {
    return Object.entries(this.form.value)
      .filter(([, val]) => val !== null)
      .filter(([, val]) => val !== '')
      .filter(([, val]) => (Array.isArray(val) ? val.length > 0 : true))
      .reduce((prev, [key, val]) => ({ ...prev, [key]: Array.isArray(val) ? [...val] : val }), {});
  }

  get hasActiveFilters(): boolean {
    return Object.keys(this.activeFilters).length > 0;
  }

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this._fb.group(
      {
        risk: null,
        unitOfWork: null,
        responsible: null,
        quotation: null,
        notification: null,
        periodic: null,
        dateFrom: [null, DateHelper.validator],
        dateTo: [null, DateHelper.validator],
      },
      {
        updateOn: 'change',
      }
    );

    this.form.patchValue(this.filters, { emitEvent: false });
  }

  reset(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.form.reset();
    this.submit();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }

    this.filterRequested.emit(this.activeFilters);
  }
}
