import { Directive, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { LayoutService } from '../services/layout.service';

@Directive({
  selector: '[appSidebar]',
})
export class SidebarDirective implements OnInit, OnDestroy {
  @Input()
  set appSidebar(value: string) {
    this._srv.sidebarClass = value;
  }

  constructor(private templateRef: TemplateRef<any>, private _srv: LayoutService) {}

  ngOnDestroy() {
    this._srv.sidebar = null;
    this._srv.sidebarClass = null;
  }

  ngOnInit() {
    this._srv.sidebar = this.templateRef;
  }
}
