import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  HydratedMeasureActions,
  HydratedMeasureApiActions,
  MeasureActions,
  MeasureApiActions,
  MeasureNotificationActions,
  MeasureNotificationApiActions,
} from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { Measure } from '../models';

export interface State extends EntityState<Measure>, LoadingState {
  highlighted: number | null;
}

export const adapter: EntityAdapter<Measure> = createEntityAdapter<Measure>();

export const initialState: State = adapter.getInitialState({
  ...LoadingStateHelper.initial(),
  highlighted: null,
});

const MeasureReducer = createReducer(
  initialState,
  // Load
  on(MeasureActions.load, (state, { measures }) => {
    return adapter.upsertMany(measures, {
      ...state,
    });
  }),
  // Update
  on(MeasureActions.update, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(MeasureApiActions.updateSuccess, (state, { measure }) => {
    return adapter.upsertOne(measure, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(MeasureApiActions.updateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Create
  on(MeasureActions.create, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(MeasureApiActions.createSuccess, (state, { measure }) => {
    return adapter.addOne(measure, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(MeasureApiActions.createFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Remove
  on(MeasureActions.remove, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(MeasureApiActions.removeSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(MeasureApiActions.removeFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Move
  on(MeasureActions.move, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(MeasureApiActions.moveSuccess, (state, { measure }) => {
    const { entities } = state;
    // reorder other measures of same type in same unitOfWorkDangerSource
    const working = Object.values(entities)
      .filter(current => current.unitOfWorkDangerSourceId === measure.unitOfWorkDangerSourceId)
      .filter(current => current.type === measure.type)
      .filter(current => current.id !== measure.id)
      .sort((measureA, measureB) => (measureA.position < measureB.position ? -1 : 1))
      .map((current, idx) => ({
        ...current,
        position: idx >= measure.position ? idx + 1 : idx,
      }));
    working.push(measure);

    return adapter.upsertMany(working, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(MeasureApiActions.moveFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Load Top
  on(HydratedMeasureActions.loadTop, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(HydratedMeasureApiActions.loadTopFailure, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  // Move Top
  on(HydratedMeasureActions.moveTop, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(HydratedMeasureApiActions.moveTopSuccess, (state, { measure }) => {
    const { entities } = state;
    const { unitOfProductionId } = Object.values(entities).find(current => current.id === measure.id);
    // reorder other measures in same unitOfProduction
    const working = Object.values(entities)
      .filter(current => current.unitOfProductionId === unitOfProductionId)
      .filter(current => current.id !== measure.id)
      .sort((measureA, measureB) => (measureA.topPosition < measureB.topPosition ? -1 : 1))
      .map((current, idx) => ({
        ...current,
        topPosition: idx >= measure.topPosition ? idx + 1 : idx,
      }));
    working.push(measure);

    return adapter.upsertMany(working, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(HydratedMeasureApiActions.moveTopFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Load Preventive
  on(HydratedMeasureActions.loadPreventive, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(HydratedMeasureApiActions.loadPreventiveFailure, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  // Clean
  on(MeasureActions.clean, state => adapter.removeAll(state)),
  // Highlight
  on(MeasureActions.highlight, (state, { id }) => ({
    ...state,
    highlighted: id,
  })),
  // Notification Create
  on(MeasureNotificationActions.create, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(MeasureNotificationApiActions.createSuccess, (state, { measureId, notification }) => {
    const measure = Object.values(state.entities).find(curr => curr.id === measureId);

    return adapter.upsertOne(
      { ...measure, notification },
      {
        ...state,
        ...LoadingStateHelper.success(),
      }
    );
  }),
  on(MeasureNotificationApiActions.createFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Notification Update
  on(MeasureNotificationActions.update, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(MeasureNotificationApiActions.updateSuccess, (state, { measureId, notification }) => {
    const measure = Object.values(state.entities).find(curr => curr.id === measureId);

    return adapter.upsertOne(
      { ...measure, notification },
      {
        ...state,
        ...LoadingStateHelper.success(),
      }
    );
  }),
  on(MeasureNotificationApiActions.updateFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Notification Delete
  on(MeasureNotificationActions.remove, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(MeasureNotificationApiActions.removeSuccess, (state, { measureId }) => {
    const measure = Object.values(state.entities).find(curr => curr.id === measureId);
    return adapter.upsertOne(
      { ...measure, notification: null },
      {
        ...state,
        ...LoadingStateHelper.success(),
      }
    );
  }),
  on(MeasureNotificationApiActions.removeFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return MeasureReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
