import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { RiskCategoryActions, RiskCategoryApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { RiskCategory } from '../models';

export interface State extends EntityState<RiskCategory>, LoadingState {}

export const adapter: EntityAdapter<RiskCategory> = createEntityAdapter<RiskCategory>();

export const initialState: State = adapter.getInitialState(LoadingStateHelper.initial());

const RiskCategoryReducer = createReducer(
  initialState,
  // Load
  on(RiskCategoryActions.load, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(RiskCategoryApiActions.loadSuccess, (state, { riskCategories }) => {
    return adapter.upsertMany(riskCategories, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(RiskCategoryApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  })),
  // Clean
  on(RiskCategoryActions.clean, state => adapter.removeAll(state)),
  on(RiskCategoryActions.cleanOne, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
    });
  })
);

export function reducer(state: State | undefined, action: Action) {
  return RiskCategoryReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
