import { ActionReducerMap } from '@ngrx/store';

import * as fromAuthenticated from './authenticated.reducer';
import * as fromCompany from './company.reducer';
import * as fromContactForm from './contact-form.reducer';
import * as fromContract from './contract.reducer';
import * as fromDangerSourceImage from './danger-source-image.reducer';
import * as fromDangerSource from './danger-source.reducer';
import * as fromHistory from './history.reducer';
import * as fromMeasure from './measure.reducer';
import * as fromPage from './page.reducer';
import * as fromProduct from './product.reducer';
import * as fromRefActivity from './ref-activity.reducer';
import * as fromRefProfile from './ref-profile.reducer';
import * as fromRefRiskType from './ref-risk-type.reducer';
import * as fromRefUnitOfProduction from './ref-unit-of-production.reducer';
import * as fromRiskCategory from './risk-category.reducer';
import * as fromRiskType from './risk-type.reducer';
import * as fromUnitOfProduction from './unit-of-production.reducer';
import * as fromUnitOfWorkDangerSource from './unit-of-work-danger-source.reducer';
import * as fromUnitOfWork from './unit-of-work.reducer';

export interface AppState {
  authenticated: fromAuthenticated.State;
  contactForm: fromContactForm.State;
  unitOfProduction: fromUnitOfProduction.State;
  unitOfWork: fromUnitOfWork.State;
  refActivity: fromRefActivity.State;
  refProfile: fromRefProfile.State;
  refRiskType: fromRefRiskType.State;
  refUnitOfProduction: fromRefUnitOfProduction.State;
  company: fromCompany.State;
  riskCategory: fromRiskCategory.State;
  riskType: fromRiskType.State;
  dangerSource: fromDangerSource.State;
  dangerSourceImage: fromDangerSourceImage.State;
  unitOfWorkDangerSource: fromUnitOfWorkDangerSource.State;
  measure: fromMeasure.State;
  product: fromProduct.State;
  contract: fromContract.State;
  history: fromHistory.State;
  page: fromPage.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  authenticated: fromAuthenticated.reducer,
  contactForm: fromContactForm.reducer,
  unitOfProduction: fromUnitOfProduction.reducer,
  unitOfWork: fromUnitOfWork.reducer,
  refActivity: fromRefActivity.reducer,
  refProfile: fromRefProfile.reducer,
  refRiskType: fromRefRiskType.reducer,
  refUnitOfProduction: fromRefUnitOfProduction.reducer,
  company: fromCompany.reducer,
  riskCategory: fromRiskCategory.reducer,
  riskType: fromRiskType.reducer,
  dangerSource: fromDangerSource.reducer,
  dangerSourceImage: fromDangerSourceImage.reducer,
  unitOfWorkDangerSource: fromUnitOfWorkDangerSource.reducer,
  measure: fromMeasure.reducer,
  product: fromProduct.reducer,
  contract: fromContract.reducer,
  history: fromHistory.reducer,
  page: fromPage.reducer,
};

export const getAuthenticatedState = (state: AppState) => state.authenticated;
export const getContactFormState = (state: AppState) => state.contactForm;
export const getUnitOfProductionState = (state: AppState) => state.unitOfProduction;
export const getUnitOfWorkState = (state: AppState) => state.unitOfWork;
export const getRefActivityState = (state: AppState) => state.refActivity;
export const getRefProfileState = (state: AppState) => state.refProfile;
export const getRefRiskTypeState = (state: AppState) => state.refRiskType;
export const getRefUnitOfProductionState = (state: AppState) => state.refUnitOfProduction;
export const getCompanyState = (state: AppState) => state.company;
export const getRiskCategoryState = (state: AppState) => state.riskCategory;
export const getRiskTypeState = (state: AppState) => state.riskType;
export const getDangerSourceState = (state: AppState) => state.dangerSource;
export const getDangerSourceImageState = (state: AppState) => state.dangerSourceImage;
export const getUnitOfWorkDangerSourceState = (state: AppState) => state.unitOfWorkDangerSource;
export const getMeasureState = (state: AppState) => state.measure;
export const getProductState = (state: AppState) => state.product;
export const getContractState = (state: AppState) => state.contract;
export const getHistoryState = (state: AppState) => state.history;
export const getPageState = (state: AppState) => state.page;
