import { ModelApi } from '../helpers/model.helper';
import { RefUnitOfProduction } from './ref-unit-of-production.model';

export enum UnitOfProductionStatusEnum {
  IN_WRITING = 'in_writing',
  VALIDATE = 'validate',
}

export interface UnitOfProduction {
  id: number;
  name: string;
  description: string;
  status: UnitOfProductionStatusEnum;
  riskAssessmentExportDate: Date | null;
  riskAssessmentExportUrl: string;
  contractId: number;
  refUnitOfProductionId: number;
  papripact: boolean;
}

export interface UnitOfProductionApi extends Omit<UnitOfProduction, 'contractId' | 'refUnitOfProductionId'> {
  unitOfWorks?: any[];
  refUnitOfProduction: Pick<RefUnitOfProduction, 'id'>;
}

export interface UnitOfProductionListApi extends ModelApi<UnitOfProductionApi> {}
