import { createAction, props } from '@ngrx/store';

import { Measure, MeasureNotification } from '../models';

export const create = createAction(
  '[Measure] Create Notification for a measure',
  props<{ measure: Measure; data: Partial<MeasureNotification> }>()
);
export const update = createAction(
  '[Measure] Update Notification for a measure',
  props<{ measure: Measure; data: Partial<MeasureNotification> }>()
);
export const remove = createAction('[Measure] Delete Notification for a measure', props<{ measure: Measure }>());
