import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { RefRiskTypeActions, RefRiskTypeApiActions } from '../actions';
import { LoadingState, LoadingStateHelper } from '../helpers/loading-state.helper';
import { RefRiskType } from '../models';

export interface State extends EntityState<RefRiskType>, LoadingState {}

export const adapter: EntityAdapter<RefRiskType> = createEntityAdapter<RefRiskType>();

export const initialState: State = adapter.getInitialState({
  ...LoadingStateHelper.initial(),
  selected: null,
});

const RefRiskTypeReducer = createReducer(
  initialState,
  // Load
  on(RefRiskTypeActions.load, state => ({
    ...state,
    ...LoadingStateHelper.start(),
  })),
  on(RefRiskTypeApiActions.loadSuccess, (state, { refRiskTypes }) => {
    return adapter.upsertMany(refRiskTypes, {
      ...state,
      ...LoadingStateHelper.success(),
    });
  }),
  on(RefRiskTypeApiActions.loadFailure, state => ({
    ...state,
    ...LoadingStateHelper.fail(),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return RefRiskTypeReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
