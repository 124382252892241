import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page, PageList, PageListApi } from '../../store/models';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(private _http: HttpClient) {}

  getByCode(code: string): Observable<Page> {
    return this._http.get<Page>(`pages/${code}`);
  }

  getList(): Observable<PageList[]> {
    return this._http.get<PageListApi>(`pages`).pipe(map(({ items }) => items));
  }
}
